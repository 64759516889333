import React, { useState, useEffect, useRef } from 'react'
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'

const Locate = (props) => {

  const googleRef = useRef(null)

  const latLng = useRef(null)

  const elevation = useRef(null)

  useEffect(() => {
    initMap()
  }, [])

  const add = () => props.add(latLng.current, elevation.current)

  const getElev = (location, elev) => {

    // Initiate the location request
    elev.getElevationForLocations(
      {
        locations: [location],
      },
      (results, status) => {

        if (status === "OK") {
          // Retrieve the first result
          if (results[0]) {
            elevation.current = Math.round((results[0].elevation * 3.28084) * 10) / 10  // 3.28084 convert from meters to feet
          } else {
            console.log('no elevation results found')
          }
        } else {
          console.log("Elevation service failed due to: " + status)
        }
      }
    );

  }

  function initMap() {



    let lat, lng

    if (props.latlng.latitude2 !== null && props.latlng.latitude2 !== '' && props.latlng.longitude2 !== null && props.latlng.longitude2 !== '') {

      lat = Number(props.latlng.latitude2)
      lng = Number(props.latlng.longitude2)

      //console.log('here1')

    } else if (props.latlng.latitude !== null && props.latlng.latitude !== '' && props.latlng.longitude !== null && props.latlng.longitude !== '') {

      //console.log(`derp: ${JSON.stringify(props.latlng)}`)

      //console.log(`latlng: ${props.latlng.latitude}, ${props.latlng.longitude}`)

      lat = Number(props.latlng.latitude)
      lng = Number(props.latlng.longitude)

      //console.log('here2')

    } else {

      lat = Number(props.filter.latitude)
      lng = Number(props.filter.longitude)

      //console.log('here3')

    }

    //console.log(`latlng: ${lat}, ${lng}`)

    let google = googleRef.current
    const myLatLng = { lat: lat, lng: lng }

    let map = new window.google.maps.Map(google, {
      mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      zoom: 19,
      center: myLatLng,
      tilt: 0
    })

    //console.log(`data: ${JSON.stringify(props)}`)
    //console.log(`data: ${JSON.stringify(props.fetchedData)}`)

    let data = props.fetchedData

    for (let i = 0; i < data.length; i++) {

      // let jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      let location = data[i].location === null ? '' : data[i].location
      // let depth = data[i].depth === null ? '' : data[i].depth
      // let gw = data[i].gw === null ? '' : data[i].gw

      let marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].latitude, data[i].longitude),
        // title:
        // `
        // ${jn}
        // ${location} @ ${depth}ft
        // GW @ ${gw}ft
        // `,
        //animation: window.google.maps.Animation.DROP,
        map,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue.png'
        },
        //label: { color: 'white', fontWeight: 'bold', fontSize: '12px', text: location },
        //id: data[i].id,
        //visible: filterData(data[i], searchValue)
      });

      //marker.setMap(mapRef.current)

      // markerRef.current.push(marker)
      //
      // latLng = new window.google.maps.LatLng(data[i].latitude, data[i].longitude);
      //
      // bounds.extend(latLng);
      //
      // window.google.maps.event.addListener(marker, 'click', function () {
      //   infoWindow.setContent('<h3>' + this.title + '</h3>');
      //   infoWindow.open(mapRef.current, this);
      // });

    }

    let marker = new window.google.maps.Marker({
      position: myLatLng,
      map,
      draggable: true,
      animation: window.google.maps.Animation.DROP,
      title: "Drag and drop to position",
    })

    // Create the initial InfoWindow.
    let infoWindow = new window.google.maps.InfoWindow({
      content: "<p>Drag and drop</p><p>or </p><p>Click the map to position</p>"
    });

    infoWindow.open(map, marker);

    let elev = new window.google.maps.ElevationService();

    const updateMarker = (e) => {

      latLng.current = marker.getPosition().toJSON()

      getElev(e.latLng, elev);

      // Close the current InfoWindow.
      infoWindow.close();
      // Create a new InfoWindow.
      infoWindow = new window.google.maps.InfoWindow({
        content: "<p>Now click <p style='font-size: 20px; margin-left: 10px; background-color: dodgerblue; color: white; height: 30px; width: 30px; padding: 3px; border-radius: 50%;'>✓</p> above</p>"
      });

      infoWindow.open(map, marker);

    }

    // event for map

    window.google.maps.event.addListener(map, 'click', function(e) {

      marker.setPosition(e.latLng)

      updateMarker(e)

    });

    // event for marker

    marker.addListener("dragend", (e) => {
      //map.setZoom(8);

      updateMarker(e)

      //map.setCenter(marker.getPosition());
    });

  }

  let content = <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>

  return <Modal close={props.close} add={add} content={content} maxWidth='none' />
}

export default Locate
