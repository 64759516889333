import React, { useState, useEffect, useRef } from 'react'
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'

const Locate = (props) => {

  const googleRef = useRef(null)

  const [searchValue, setSearchValue] = useState('')

  const siteInfo = useRef({
    latitude: '',
    longitude: '',
    elevation: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zip: '',
    siteLocation: '',
    siteArea: ''
  })

  const [displayAddress, setDisplayAddress] = useState({
    format: true,
    formatted: ''
  })

  const polygons = useRef([])

  useEffect(() => {

    console.log(`poly: ${props.polygon}`)

    let info = props.latlng

    siteInfo.current = {
      latitude: info.latitude,
      longitude: info.longitude,
      elevation: info.elevation,
      address: info.address,
      city: info.city,
      county: info.county,
      state: info.state,
      zip: info.zip,
      siteLocation: props.polygon === false ? '' : info.siteLocation,
      siteArea: props.polygon === false ? '' : info.siteArea
    }

    initMap()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const toggleFormat = () => setDisplayAddress(prevState => ({...prevState, format: displayAddress.format ? false : true}))

  const geoLocate = (lat, lng) => {

    let formattedAddress = searchValue.replace(/ /g, '+')

    //let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=AIzaSyAvwEh7RclQl_Uc8iVL6bvTzqCNWcqE27s`

    let url = lat !== undefined && lng !== undefined ?
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB4ttD4F5wit89cXVQa-uMyCDacyuUpkyY` :
    searchValue !== '' ?
    `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=AIzaSyB4ttD4F5wit89cXVQa-uMyCDacyuUpkyY` : ''

    if (url !== '') {

      fetch(url)
      .then(res => res.json())
      .then(res => {
        console.log('res ', res)

        res.results[0].address_components.forEach(o => {

          switch (o.types[0]) {
            case 'street_number':

              siteInfo.current = {...siteInfo.current, address: o.long_name}

              break
            case 'route':

              siteInfo.current = {...siteInfo.current, address: `${siteInfo.current.address} ${o.long_name}`}

              break
            case 'locality':

              siteInfo.current = {...siteInfo.current, city: o.short_name}

              break
            case 'administrative_area_level_2':

              siteInfo.current = {...siteInfo.current, county: o.short_name}

              break
            case 'administrative_area_level_1':

              siteInfo.current = {...siteInfo.current, state: o.short_name}

              break
            case 'postal_code':

              siteInfo.current = {...siteInfo.current, zip: o.short_name}

              break
            default:

          }

        })


        siteInfo.current = {...siteInfo.current,
          latitude: res.results[0].geometry.location.lat,
          longitude: res.results[0].geometry.location.lng
        }

        // siteInfo.current = {...siteInfo.current,
        //   latitude: res.results[0].geometry.location.lat,
        //   longitude: res.results[0].geometry.location.lng,
        //   elevation: '',
        //   address: `${res.results[0].address_components[0].long_name} ${res.results[0].address_components[1].long_name}`,
        //   city: res.results[0].address_components[2].short_name,
        //   county: res.results[0].address_components[3].short_name,
        //   state: res.results[0].address_components[4].short_name,
        //   zip: res.results[0].address_components[6].long_name
        // }

        if (lat === undefined && lng === undefined) initMap()

      })
      .catch(err => console.log(`Err: ${err}`))

    } else {

      alert('Error: missing address and/or Lat/Lng')

    }

  }

  const onEnter = (e) => {
    if(e.key === 'Enter') {
      //console.log('enter')
      geoLocate()
    }
  }

  const add = () => {
    //console.log(`siteInfo before: ${JSON.stringify(siteInfo.current)}`)

    props.add(siteInfo.current)
  }

  const getElev = (location, elev) => {

    // Initiate the location request
    elev.getElevationForLocations(
      {
        locations: [location],
      },
      (results, status) => {

        if (status === "OK") {
          // Retrieve the first result
          if (results[0]) {
            siteInfo.current.elevation = Math.round((results[0].elevation * 3.28084) * 10) / 10  // 3.28084 convert from meters to feet
            console.log(`elevation: ${siteInfo.current.elevation}`)
          } else {
            console.log('no elevation results found')
          }
        } else {
          console.log("Elevation service failed due to: " + status)
        }
      }
    );

  }

  let siteLocation

  function initMap() {

    //console.log(`site? ${siteInfo.current.siteLocation}`)

    let lat, lng

    if (siteInfo.current.latitude === null || siteInfo.current.latitude === '' || siteInfo.current.longitude === null || siteInfo.current.longitude === '') {

      lat = 33.847827
      lng = -117.862286

      //lat = Number(props.filter.latitude)
      //lng = Number(props.filter.longitude)
    } else {
      lat = Number(siteInfo.current.latitude)
      lng = Number(siteInfo.current.longitude)
    }

    let google = googleRef.current
    const myLatLng = { lat: lat, lng: lng };

    let map = new window.google.maps.Map(google, {
      mapTypeId: window.google.maps.MapTypeId.SATELLITE,
      zoom: 15,
      center: myLatLng,
      tilt: 0
    });

    let drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: null,
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          window.google.maps.drawing.OverlayType.POLYGON
        ],
      },
      polygonOptions: {
        fillColor: "#59FF33",
        fillOpacity: 0.3,
        strokeColor: "#59FF33",
        strokeWeight: 1,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1,
      },
    });

    if (props.polygon === false) { // client address piggy backs this

      drawingManager.setMap(null)

    } else if (siteInfo.current.siteLocation !== '' && siteInfo.current.siteLocation !== null) {

      if (siteInfo.current.siteLocation.length > 0) {
        drawingManager.setMap(null)
      } else {
        drawingManager.setMap(map)
      }

    } else {

      drawingManager.setMap(map)

    }

    // window.google.maps.event.addListener(map, 'click', function(e) {
    //
    //   marker.setPosition(e.latLng)
    //
    //   updateMarker(e)
    //
    // });

    window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {

      window.google.maps.event.addListener(polygon, 'click', function (event) {

        if (window.confirm("Delete this polygon?")) {
          let index = Number(polygon.get('id'))
          polygons.current.splice(index, 1)

          siteInfo.current.siteLocation = ''
          siteInfo.current.siteArea = ''

          this.setMap(null)
          drawingManager.setMap(map)
        }

      })

      window.google.maps.event.addListener(polygon.getPath(), 'insert_at', function(eventj) {
        updateSiteAndArea(polygon)
      })

      window.google.maps.event.addListener(polygon.getPath(), 'set_at', function(event) {
        updateSiteAndArea(polygon)
      })

      window.google.maps.event.addListener(polygon.getPath(), 'remove_at', function(event) {
        updateSiteAndArea(polygon)
      })

      updateSiteAndArea(polygon)

      drawingManager.setMap(null)

    })

    const updateSiteAndArea = (polygon) => {

      let coordinates = polygon.getPath().getArray()

      //console.log(coordinates)

      // DOESNT WORK IF A POLYGON IS DELETED
      let id = polygons.current.length
      polygon.set("id", id)

      let lat = ''
      let lng = ''
      let latlng = ''

      for (let i = 0; i < coordinates.length; i++) {

        lat = coordinates[i].lat();
        lng = coordinates[i].lng();

        latlng += `${lat},${lng} `

        //console.log(lat);
        //console.log(lng);

      }

      latlng = latlng.trim()

      let area = window.google.maps.geometry.spherical.computeArea(polygon.getPath())

      area = Math.round((area * 0.00024715) * 10) / 10  // 3.28084 convert from meters to acres

      //console.log(`area? ${area}`)

      //console.log(`area: ${area}`)

      // this will allow multiple polygons in the future
      polygons.current.push({id: id, latlng: latlng, area: area})

      siteInfo.current.siteLocation = latlng
      siteInfo.current.siteArea = area

    }

    // place marker

    let marker = new window.google.maps.Marker({
      position: myLatLng,
      map,
      draggable: true,
      animation: window.google.maps.Animation.DROP,
      title: "Drag and drop to position",
    })

    // now handle the siteLocation if exists

    console.log(`siteInfo: ${JSON.stringify(siteInfo.current)}`)

    if (siteInfo.current.siteLocation !== '' && siteInfo.current.siteLocation !== null) {

      if (siteInfo.current.siteLocation.length > 0) {

        let id = polygons.current.length

        polygons.current.push({id: id, latlng: siteInfo.current.siteLocation, area: siteInfo.current.siteArea})

        let coords = []

        let siteCoords = siteInfo.current.siteLocation.split(" ")

        for (let i=0; i < siteCoords.length; i++) {
          let latlng = siteCoords[i].split(",")
          coords.push(new window.google.maps.LatLng(latlng[0], latlng[1]))
        }

        // Construct the polygon
        siteLocation = new window.google.maps.Polygon({
          id: id,
          paths: coords,
          fillColor: "#59FF33",
          fillOpacity: 0.3,
          strokeColor: "#59FF33",
          strokeWeight: 1,
          clickable: true,
          editable: true,
          draggable: true,
          zIndex: 1
        })

        //siteLocation.set("id", id)

        siteLocation.setMap(map)

        window.google.maps.event.addListener(siteLocation, 'click', function (event) {

          if (window.confirm("Delete this polygon?")) {
            let index = Number(this.get('id'))
            polygons.current.splice(index, 1)

            siteInfo.current.siteLocation = ''
            siteInfo.current.siteArea = ''

            this.setMap(null)
            drawingManager.setMap(map)
          }

        })

        window.google.maps.event.addListener(siteLocation.getPath(), 'insert_at', function(event) {
          updateSiteAndArea(siteLocation)
        })

        window.google.maps.event.addListener(siteLocation.getPath(), 'set_at', function(event) {
          updateSiteAndArea(siteLocation)
        })

        window.google.maps.event.addListener(siteLocation.getPath(), 'remove_at', function(event) {
          updateSiteAndArea(siteLocation)
        })

      }

    }

    // Create the initial InfoWindow.
    let infoWindow = new window.google.maps.InfoWindow({
      content: "<p>Drag and drop</p><p>or </p><p>Click the map to position</p>"
    });

    infoWindow.open(map, marker);

    let elev = new window.google.maps.ElevationService();

    const updateMarker = (e) => {

      let latlng = marker.getPosition().toJSON()

      siteInfo.current.lat = latlng.lat
      siteInfo.current.lng = latlng.lng

      getElev(e.latLng, elev)

      geoLocate(latlng.lat, latlng.lng)

      // Close the current InfoWindow.
      infoWindow.close();
      // Create a new InfoWindow.
      infoWindow = new window.google.maps.InfoWindow({
        content: "<p>Now click <p style='font-size: 20px; margin-left: 10px; background-color: dodgerblue; color: white; height: 30px; width: 30px; padding: 3px; border-radius: 50%;'>✓</p> above</p>"
      });

      infoWindow.open(map, marker);

    }

    // event for map

    window.google.maps.event.addListener(map, 'click', function(e) {

      marker.setPosition(e.latLng)

      updateMarker(e)

    });

    // event for marker

    marker.addListener("dragend", (e) => {
      //map.setZoom(8);

      updateMarker(e)

      //map.setCenter(marker.getPosition());
    });

  }

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    marginTop: 100
  }

  // <div style={styleBtn} onClick={null}>
  //   <Icon name='arrow_circle_right' />
  //   <span style={{marginRight: 10}}>Find</span>
  // </div>

  // <div>
  //   <Icon name='spellcheck' onClick={toggleFormat} />
  //   <span>{displayAddress.format ? `Format on` : 'Format off'}{displayAddress.formatted !== '' ? `: ${displayAddress.formatted}` : ''}</span>
  // </div>

  let content = (

    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>


      <SearchBar search={search} clearSearch={clearSearch} onClick={geoLocate} onKeyPress={onEnter} />

      <div style={{flex: '1', width: '100%'}} ref={googleRef}></div>

    </div>
  )

  return <Modal close={props.close} add={add} content={content} maxWidth='none' />
}

export default Locate
