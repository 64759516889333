import React from 'react'
import Modal from 'components/utils/Modal'

const Contact = (props) => {

  let content = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

        <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

          {props.modal ? null :
            <div>
              <div><label className='label'>Client</label></div>
              <textarea style={{height: 50}} className='textArea' value={props.data.client} onClick={props.readOnly ? null : props.openClient} readOnly></textarea>
            </div>
          }

          <div>
            <div><label className='label'>First Name</label></div>
            <textarea style={{height: 50}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='firstName' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.firstName} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

          <div>
            <div><label className='label'>Last Name</label></div>
            <textarea  id='myText' style={{height: 50}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='lastName' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.lastName} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

          <div>
            <div><label className='label'>Title</label></div>
            <textarea style={{height: 50}} className='textArea' placeholder='optional' pattern="[a-zA-Z0-9]{1,}" name='title' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.title} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

          <div>
            <label className='label'>Email</label>
            <input style={{width: 200}} className='input' type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder='john@example.com' name='email' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.email} required readOnly={props.readOnly ? true : false} />
          </div>

          <div>
            <label className='label'>Phone</label>
            <input style={{width: 200}} className='input' type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder='###-###-####' name='phone' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.phone} required readOnly={props.readOnly ? true : false} />
          </div>

          <div>
            <div><label className='label'>Notes</label></div>
            <textarea style={{height: 100}} className='textArea' placeholder='optional' pattern="[a-zA-Z0-9]{1,}" name='notes' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.notes} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

        </div>

    </div>
  )

  return <Modal content={content} add={props.add} delete={props.delete} close={props.close} />

}

export default Contact
