import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, filterData } from 'scripts/common'

const User = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectMenuUser', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectUserMenu --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let name = data.fullname === null ? '' : data.fullname
    let level = data.userlevel === null ? '' : data.userlevel

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={props.selectUser}>
          <td style={{display: 'none'}}>{data.username}</td>
          <td>{level}</td>
          <td>{name}</td>
        </tr>
      )
    }

  })

  let content = (
    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{textAlign: 'center'}}>
        <div style={{
          border: '2px solid tomato',
          borderRadius: 5,
          color: 'tomato',
          cursor: 'pointer',
          padding: 5,
          margin: 10
        }} onClick={props.clearUser}>Clear User
        </div>
      </div>

      <>

        <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

          <div style={{textAlign: 'center'}}>

            <div style={{display: 'inline-block'}}>

              <table>

                <thead>
                  <tr>
                    <th>Level</th>
                    <th>Name</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>

            </div>

          </div>

        </div>

      </>

    </div>
  )

  return <Modal content={content} close={props.closeModal} />
}

export default User
