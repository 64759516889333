import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Icon from 'components/utils/Icon'
import Client from 'components/menu/Client'
import Input from 'components/input/Contact'

import { formatDateTime, formatDateYMD, getDeviceType, getLocation, replaceStr, filterData } from 'scripts/common'

const Contact = (props) => {

  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    client: false
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    firstName: '',
    lastName: '',
    client: '',
    companyId: null,
    title: '',
    email: '',
    phone: '',
    notes: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    firstName: '',
    lastName: '',
    client: '',
    companyId: null,
    title: '',
    email: '',
    phone: '',
    notes: ''
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') {

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          firstName: fetchedData[i].firstname,
          lastName: fetchedData[i].lastname,
          companyId: fetchedData[i].companyid,
          client: fetchedData[i].client,
          title: fetchedData[i].title,
          email: fetchedData[i].email,
          phone: fetchedData[i].phone,
          notes: fetchedData[i].notes
        }))
        openEdit()

      }

    }

  }

  const fetchData = () => {

    fetch('/api/selectMenuContact', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        companyId: props.modal ? props.companyId : false
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuContact --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const addData = () => {

    if (isValidated.firstName === null || isValidated.firstName === '') {
      alert("Please provide a first name.")
    } else if (isValidated.lastName === null || isValidated.lastName === '') {
      alert("Please provide a last name.")
    } else {

      console.log(`isValidated: ${JSON.stringify(isValidated)}`)

      getLocation(function(latlng){

        fetch('/api/addContact', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            firstName: replaceStr(isValidated.firstName),
            lastName: replaceStr(isValidated.lastName),
            companyId: props.modal ? props.companyId : isValidated.companyId,
            title: replaceStr(isValidated.title),
            email: isValidated.email,
            phone: isValidated.phone,
            notes: replaceStr(isValidated.notes)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add contact. Contact and admin.')
            console.log('Error: addContact --> ' + error)
          }
        )

      })

    }

  }

  const editData = () => {

    if (isValidated.firstName === null || isValidated.firstName === '') {
      alert("Please provide a first name.")
    } else if (isValidated.lastName === null || isValidated.lastName === '') {
      alert("Please provide a last name.")
    } else if (isValidated.email === null || isValidated.email === '') {
      alert("Please provide an email.")
    } else if (isValidated.phone === null || isValidated.phone === '') {
      alert("Please provide a phone number.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editContact', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            id: isValidated.id,
            firstName: replaceStr(isValidated.firstName),
            lastName: replaceStr(isValidated.lastName),
            companyId: props.modal ? props.companyId : isValidated.companyId,
            title: replaceStr(isValidated.title),
            email: isValidated.email,
            phone: isValidated.phone,
            notes: replaceStr(isValidated.notes)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: getDeviceType(),
                firstname: replaceStr(isValidated.firstName),
                lastname: replaceStr(isValidated.lastName),
                client: isValidated.client,
                companyId: props.modal ? props.companyId : isValidated.companyId,
                title: replaceStr(isValidated.title),
                email: isValidated.email,
                phone: isValidated.phone,
                notes: replaceStr(isValidated.notes)
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit contact. Contact and admin.')
            console.log('Error: editContact --> ' + error)
          }
        )

      })

    }

  }

  const deleteData = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteContact', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          //fetchData()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete Contact. Contact and admin.')
          console.log('Error: deleteContact --> ' + error)
        }
      )

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectClient = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,
      companyId: td[1].textContent,
      client: td[2].textContent
    }))
    changedData()
    closeClient()

  }

  const clearClient = () => {

    setIsValidated(prevState => ({...prevState,
      companyId: null,
      client: ''
    }))
    changedData()
    closeClient()
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const toggleEdit = () => setIsModal(prevState => ({...prevState, editMode: isModal.editMode ? false : true}))

  const openClient = () => setIsModal(prevState => ({...prevState, client: true}))

  const closeClient = () => setIsModal(prevState => ({...prevState, client: false}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let firstName = data.firstname === null ? '' : data.firstname
    let lastName = data.lastname === null ? '' : data.lastname
    let fullName = `${firstName} ${lastName}`.trim()

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={isModal.editMode || props.modal === undefined ? selectRow : props.selectContact}>
          <td style={{display: 'none'}}>{i}</td>
          <td style={{display: 'none'}}>{data.id}</td>
          <td style={{display: 'none'}}>{firstName}</td>
          <td style={{display: 'none'}}>{lastName}</td>
          <td>{fullName}</td>
        </tr>
      )
    }

  })

  let content = (
    <>
      {isModal.add || isModal.edit ? <Input close={closeModal} add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} data={isValidated} validate={validate} changedData={changedData} openClient={openClient} /> : null}
      {isModal.client ? <Client user={props.user} selectClient={selectClient} clearClient={clearClient} closeModal={closeClient} modal={true} /> : null}

      <div style={{margin: 10, display: 'flex', flexFlow: 'column', height: '100%'}}>

        <div style={{textAlign: props.modal ? 'center' : 'none'}}>
          <Icon name='add_circle' onClick={openAdd} />
          {props.modal ? <Icon name='edit' color={isModal.editMode ? 'dodgerblue' : 'gray'} onClick={toggleEdit} /> : null}
          <Icon name='refresh' onClick={fetchData} />
        </div>

        <SearchBar search={search} clearSearch={clearSearch} />

        {props.modal ?
          <div style={{textAlign: 'center'}}>
            <div style={{
              border: '2px solid tomato',
              borderRadius: 5,
              color: 'tomato',
              cursor: 'pointer',
              padding: 5,
              margin: 10
            }} onClick={props.clearContact}>Clear Contact
            </div>
          </div> : null
        }

        <>

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <div style={{textAlign: props.modal ? 'center' : 'none'}}>

              <div style={{display: 'inline-block'}}>

                {fetchedData.length > 0 ?

                  <table>

                    <thead>
                      <tr>
                        <th>Contact</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>

                  </table> :

                  <p>No contacts found</p>

                }

              </div>

            </div>

          </div>

        </>

      </div>

    </>
  )

  return props.modal ? <Modal content={content} close={props.closeModal} /> : content
}

export default Contact
