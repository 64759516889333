import React from 'react'

const CircleButton = (props) => {

  let style = {
    background: 'dodgerblue',
    border: 'none',
    borderRadius: '50%',
    padding: 15,
    height: 54,
    width: 54,
    margin: 10,
    color: '#ffffff',
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
    outline: 'none'
  }

  return <button style={style} onClick={props.onClick}><i className='material-icons'>{props.iconName}</i></button>
}

export default CircleButton
