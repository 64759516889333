import React from 'react'
import Icon from 'components/utils/Icon'

const LeftPanel = (props) => {

  let activeDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'dodgerblue',
    color: 'white'
  }

  let styleDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  let styleSpan = {
    marginRight: 10,
    whiteSpace: 'nowrap'
  }

  return (
    <div style={{backgroundColor: 'whitesmoke', height: '100%', overflowY: 'auto'}}>

      <div className='hoverLink' style={props.componentManage === 'Home' ? activeDiv : styleDiv} data-id='Home' onClick={props.selectComponent}><Icon name='home' color={props.componentManage === 'Home' ? 'white' : 'gray'} /><span style={styleSpan}>Home</span></div>

      {props.user.permission > 0 ? <div className='hoverLink' style={props.componentManage === 'Permissions' ? activeDiv : styleDiv} data-id='Permissions' onClick={props.selectComponent}><Icon name='vpn_key' color={props.componentManage === 'Permissions' ? 'white' : 'gray'} /><span style={styleSpan}>Permissions</span></div> : null}

      {props.user.client > 0 ? <div className='hoverLink' style={props.componentManage === 'Client' ? activeDiv : styleDiv} data-id='Client' onClick={props.selectComponent}><Icon name='groups' color={props.componentManage === 'Client' ? 'white' : 'gray'} /><span style={styleSpan}>Client</span></div> : null}

      {props.user.contact > 0 ? <div className='hoverLink' style={props.componentManage === 'Contact' ? activeDiv : styleDiv} data-id='Contact' onClick={props.selectComponent}><Icon name='person_outline' color={props.componentManage === 'Contact' ? 'white' : 'gray'} /><span style={styleSpan}>Contact</span></div> : null}

    </div>
  )
}

export default LeftPanel
