import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Locate from 'components/menu/LocateJob'
import Input from 'components/input/Client'

import { formatDateTime, formatDateYMD, getLocation, getDeviceType, replaceStr, filterData } from 'scripts/common'

const Client = (props) => {

  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    located: false
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    client: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    notes: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    client: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    notes: ''
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      // if (target.nodeName === 'I') {
      //
      //   directions(i)
      //
      // } else

      if (target.nodeName === 'TD') {

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          client: fetchedData[i].client,
          notes: fetchedData[i].notes,
          address: fetchedData[i].address,
          city: fetchedData[i].city,
          county: fetchedData[i].county,
          state: fetchedData[i].state,
          zip: fetchedData[i].zip,
          latitude: fetchedData[i].latitude,
          longitude: fetchedData[i].longitude
        }))
        openEdit()

      }

    }

  }

  const selectLocate = (data) => {

    //console.log(`siteInfo: ${JSON.stringify(data)}`)

    setIsValidated(prevState => ({...prevState,
      latitude: data.latitude,
      longitude: data.longitude,
      address: data.address,
      city: data.city,
      county: data.county,
      state: data.state,
      zip: data.zip
    }))

    isChanged.current = true

    closeLocate()

  }

  const fetchData = () => {

    fetch('/api/selectMenuClient', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuClient --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const addData = () => {

    if (isValidated.client === null || isValidated.client ==='') {
      alert("Please provide a Client.");
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
        alert("Please provide a latitude.")
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
        alert("Please provide a longitude.")
    } else if (isValidated.address === null || isValidated.address === '') {
        alert("Please provide an address.")
    } else if (isValidated.city === null || isValidated.city === '') {
        alert("Please provide a city.")
    } else {

      getLocation(function(latlng){

        fetch('/api/addClient', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            client: replaceStr(isValidated.client),
            notes: replaceStr(isValidated.notes),
            address: isValidated.address,
            city: isValidated.city,
            county: isValidated.county,
            state: isValidated.state,
            zip: isValidated.zip,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add client. Contact and admin.')
            console.log('Error: addClient --> ' + error)
          }
        )

      })

    }

  }

  const editData = () => {

    if (isValidated.client === null || isValidated.client ==='') {
      alert("Please provide a Client.")
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
        alert("Please provide a latitude.")
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
        alert("Please provide a longitude.")
    } else if (isValidated.address === null || isValidated.address === '') {
        alert("Please provide an address.")
    } else if (isValidated.city === null || isValidated.city === '') {
        alert("Please provide a city.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editClient', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            id: isValidated.id,
            client: replaceStr(isValidated.client),
            notes: replaceStr(isValidated.notes),
            address: isValidated.address,
            city: isValidated.city,
            county: isValidated.county,
            state: isValidated.state,
            zip: isValidated.zip,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: getDeviceType(),
                client: replaceStr(isValidated.client),
                notes: replaceStr(isValidated.notes),
                address: isValidated.address,
                city: isValidated.city,
                county: isValidated.county,
                state: isValidated.state,
                zip: isValidated.zip,
                latitude: isValidated.latitude,
                longitude: isValidated.longitude
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit client. Contact and admin.')
            console.log('Error: editClient --> ' + error)
          }
        )

      })

    }

  }

  const deleteData = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteClient', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          //fetchData()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete client. Contact and admin.')
          console.log('Error: deleteClient --> ' + error)
        }
      )

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const toggleEdit = () => setIsModal(prevState => ({...prevState, editMode: isModal.editMode ? false : true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let client = data.client === null ? '' : data.client

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={isModal.editMode || props.modal === undefined ? selectRow : props.selectClient}>
          <td style={{display: 'none'}}>{i}</td>
          <td style={{display: 'none'}}>{data.id}</td>
          <td>{client}</td>
        </tr>
      )
    }

  })

  let content = (
    <>
      {isModal.add || isModal.edit ? <Input close={closeModal} add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} data={isValidated} validate={validate} changedData={changedData} openLocate={openLocate} /> : null}
      {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} polygon={false} /> : null}

      <div style={{margin: 10, display: 'flex', flexFlow: 'column', height: '100%'}}>

        <div style={{textAlign: props.modal ? 'center' : 'none'}}>
          <Icon name='add_circle' onClick={openAdd} />
          {props.modal ? <Icon name='edit' color={isModal.editMode ? 'dodgerblue' : 'gray'} onClick={toggleEdit} /> : null}
          <Icon name='refresh' onClick={fetchData} />
        </div>

        <SearchBar search={search} clearSearch={clearSearch} />

        {props.modal ?
          <div style={{textAlign: 'center'}}>
            <div style={{
              border: '2px solid tomato',
              borderRadius: 5,
              color: 'tomato',
              cursor: 'pointer',
              padding: 5,
              margin: 10
            }} onClick={props.clearClient}>Clear Client
            </div>
          </div> : null
        }

        <>

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <div style={{textAlign: props.modal ? 'center' : 'none'}}>

              <div style={{display: 'inline-block'}}>

                {fetchedData.length > 0 ?

                  <table>

                    <thead>
                      <tr>
                        <th>Client</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>

                  </table> :

                  <p>No clients found</p>

                }

              </div>

            </div>

          </div>

        </>

      </div>

    </>
  )

  return props.modal ? <Modal content={content} close={props.closeModal} /> : content
}

export default Client
