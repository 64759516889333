import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Icon from 'components/utils/Icon'

import { formatDateTime, formatDateYMD, getLocation, getDeviceType, replaceStr, filterData } from 'scripts/common'

const Rate = (props) => {

  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    rate: '',
    description: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: '',
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    rate: '',
    description: ''
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      // if (target.nodeName === 'I') {
      //
      //   directions(i)
      //
      // } else

      if (target.nodeName === 'TD') {

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          rate: fetchedData[i].rate,
          description: fetchedData[i].description
        }))
        openEdit()

      }

    }

  }

  const fetchData = () => {

    fetch('/api/selectMenuRate', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectRateMenu --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const addData = () => {

    if (isValidated.rate === null || isValidated.rate === '') {
      alert("Please provide a rate.")
    } else if (isValidated.description === null || isValidated.description === '') {
      alert("Please provide a description.")
    } else {

      getLocation(function(latlng){

        fetch('/api/addRate', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            rate: isValidated.rate,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add rate. Contact and admin.')
            console.log('Error: addRate --> ' + error)
          }
        )

      })

    }

  }

  const editData = () => {

    if (isValidated.rate === null || isValidated.rate === '') {
      alert("Please provide a rate.")
    } else if (isValidated.description === null || isValidated.description === '') {
      alert("Please provide a description.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editRate', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            id: isValidated.id,
            rate: isValidated.rate,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: getDeviceType(),
                rate: isValidated.rate,
                description: replaceStr(isValidated.description)
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit rate. Contact and admin.')
            console.log('Error: editRate --> ' + error)
          }
        )

      })

    }

  }

  const deleteData = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteRate', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          fetchData()
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete rate. Contact and admin.')
          console.log('Error: deleteRate --> ' + error)
        }
      )

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const toggleEdit = () => setIsModal(prevState => ({...prevState, editMode: isModal.editMode ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let rate = data.rate === null ? '' : data.rate
    let description = data.description === null ? '' : data.description

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={isModal.editMode ? selectRow : props.selectRate}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{rate}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

        <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

          <div>
            <label className='label'>Rate</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='rate' onInput={validate} onChange={changedData} defaultValue={isValidated.rate} required />
          </div>

          <div>
            <div><label className='label'>Description</label></div>
            <textarea style={{height: 100}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} required></textarea>
          </div>

        </div>

    </div>
  )

  let content = (
    <>
      {isModal.add || isModal.edit ? <Modal close={closeModal} add={isModal.add ? addData : isModal.edit ? editData : null} delete={isModal.edit ? deleteData : null} content={modalContent} isValidated={isValidated} /> : null}

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        <div style={{textAlign: 'center'}}>
          <Icon name='add_circle' onClick={openAdd} />
          <Icon name='edit' color={isModal.editMode ? 'dodgerblue' : 'gray'} onClick={toggleEdit} />
          <Icon name='refresh' onClick={fetchData} />
        </div>

        <SearchBar search={search} clearSearch={clearSearch} />

        <div style={{textAlign: 'center'}}>
          <div style={{
            border: '2px solid tomato',
            borderRadius: 5,
            color: 'tomato',
            cursor: 'pointer',
            padding: 5,
            margin: 10
          }} onClick={props.clearUser}>Clear Rate
          </div>
        </div>

        <>

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <div style={{textAlign: 'center'}}>

              <div style={{display: 'inline-block'}}>

                {fetchedData.length > 0 ?

                  <table>

                    <thead>
                      <tr>
                        <th>Rate</th>
                        <th>Description</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>

                  </table> :

                  <p>No rates found</p>

                }

              </div>

            </div>

          </div>

        </>

      </div>

    </>
  )

  return <Modal content={content} close={props.closeModal} />
}

export default Rate
