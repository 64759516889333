import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import CircleButton from 'components/utils/CircleButton'
import Description from 'components/menu/Description'
import Color from 'components/menu/Color'
import Locate from 'components/menu/LocateLog'
import JobMenu from 'components/menu/Job'

import { formatDateYMD, formatDateTime, getLocation, getDeviceType, exportToExcel, filterData, replaceStr, addActivity } from 'scripts/common'

import { testPDF, createPDF } from 'scripts/pdf'

const Aerial = (props) => {  

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])
  const previewRef = useRef('')

  const [fetchedData, setFetchedData] = useState([])  

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    loading: true,
    preview: false
  })

  //const [isLoading, setIsLoading] = useState(true)

  const isChanged = useRef(false)
  const isChangedDescription = useRef(false)
  const isChangedSamples = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: '',
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    city: '',
    aerialDate: null,
    imagePath: '',
    coordError: null,
    north: '',
    south: '',
    east: '',
    west: '',
    rotation: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    city: '',
    aerialDate: null,
    imagePath: '',
    coordError: null,
    north: '',
    south: '',
    east: '',
    west: '',
    rotation: ''
  })  

  const changedData = () => isChanged.current = true  

  const fetchData = () => {

    fetch('/api/selectAerials', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedData(result.map(data => (
          {...data,
            entrytime: formatDateTime(data.entrytime),
            modtime: formatDateTime(data.modtime),
            aerialDate: formatDateYMD(data.aerialDate)
          }
        )))

        addActivity('jobs', props.filter.jobNumber, '', props.component, 'view', '', props.user.username)

        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        console.log('Error: selectLogs --> ' + error)
      }
    )

  }  

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {    
    fetchData()
  }, [props.filter])
  

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    //console.log(`perc2: ${JSON.stringify(markerRef.current)}`)

    for (let i = 0; i < markerRef.current.length; i++) {

      if (markerRef.current[i].get("id") === id) {

        let icon = {
            url: `http://maps.google.com/mapfiles/ms/icons/yellow.png`,
            scaledSize: new window.google.maps.Size(40, 40), // scaled size
            labelOrigin: new window.google.maps.Point(19, 12)
        }

        markerRef.current[i].setIcon(icon)
        //markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        let icon = {
            url: `http://maps.google.com/mapfiles/ms/icons/${markerRef.current[i].get("perc")}.png`,
            scaledSize: new window.google.maps.Size(40, 40), // scaled size
            labelOrigin: new window.google.maps.Point(19, 12)
        }

        markerRef.current[i].setIcon(icon)
        //markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.latitude !== '' && data.latitude !== null && data.longitude !== '' && data.longitude !== null) {

        let jobNumber = data.jobnumber === null ? '' : data.jobnumber
        let location = data.location === null ? '' : data.location
        let depth = data.depth === null ? '' : data.depth
        let gw = data.gw === null ? '' : data.gw
        let drillDate = data.drillDate === null ? '' : data.drillDate
        //let loggedBy = data.projectdescription === null ? '' : data.projectdescription
        let driller = data.driller === null ? '' : data.driller
        let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
        let entryTime = data.entrytime === null ? '' : data.entrytime

        return true

        //{testNo: testNo, lat: data.latitude, lng: data.longitude}

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.SATELLITE,
         tilt: 0
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, jn, location, depth, gw

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    // clear array in case of data carried over, was happening with edit

    markerRef.current = []

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      location = data[i].location === null ? '' : data[i].location
      depth = data[i].depth === null ? '' : data[i].depth
      gw = data[i].gw === null ? '' : data[i].gw

      let icon = {
          url: `http://maps.google.com/mapfiles/ms/icons/${data[i].percWell === 1 ? 'blue' : 'red'}.png`,
          scaledSize: new window.google.maps.Size(40, 40), // scaled size
          labelOrigin: new window.google.maps.Point(19, 12)
      }

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].latitude, data[i].longitude),
        title:
        `
        ${jn}
        ${location} @ ${depth}ft
        GW @ ${gw}ft
        `,
        //animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        // icon: {
        //   url: 'http://maps.google.com/mapfiles/ms/icons/blue.png'
        // },
        icon: icon,
        label: { color: 'black', fontWeight: 'bold', fontSize: '10px', text: `${location}` },
        //label: 'B-11',
        id: data[i].id,
        visible: filterData(data[i], searchValue),
        perc: data[i].percWell === 1 ? 'blue' : 'red'
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].latitude, data[i].longitude);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : ''}))
  }  

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      // if (target.nodeName === 'I') {
      //
      //   directions(i)
      //
      // } else

      if (target.nodeName === 'TD') {

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          jobNumber: fetchedData[i].jobNumber,
          location: fetchedData[i].location,
          depth: fetchedData[i].depth,
          gw: fetchedData[i].gw,
          drillDate: fetchedData[i].drillDate,
          elevation: fetchedData[i].elevation,
          driller: fetchedData[i].driller,
          drillMethod: fetchedData[i].drillMethod,
          drivingWeight: fetchedData[i].drivingWeight,
          percWell: fetchedData[i].percWell,
          latitude: fetchedData[i].latitude,
          longitude: fetchedData[i].longitude
        }))
        openEdit()

      }

    }

  }  

  const selectLocate = (latlng, elevation) => {

    setIsValidated(prevState => ({...prevState, latitude: latlng.lat, longitude: latlng.lng, elevation: elevation}))
    isChanged.current = true
    closeLocate()

  }  

  const addData = () => {

    if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
      alert("Please select a JN.");
    } else if (isValidated.drillDate === null || isValidated.drillDate === '') {
      alert("Please select a Drill Date.");
    } else if (isValidated.locaiton === null || isValidated.locaiton === '') {
      alert("Please select a Locaiton.");
    // } else if (isValidated.depth === null || isValidated.depth ==='') {
    //     alert("Please select a Depth.");
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
      alert("Please select a Latitude.");
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
      alert("Please select a Longitude.");
    } else if (isValidated.driller === null || isValidated.driller === '') {
      alert("Please select a Contractor.");
    } else if (isValidated.drillMethod === null || isValidated.drillMethod === '') {
      alert("Please select a Method.");
    } else if (isValidated.drivingWeight === null || isValidated.drivingWeight === '') {
      alert("Please select a Weight.");
    } else if (isValidated.percWell === null || isValidated.percWell === '') {
      alert("Am I a perc?");
    } else if (isValidated.feetPerPage === null || isValidated.feetPerPage === '') {
      alert("Please specify feet per page for log");
    } else if (isValidated.feetPerPage < 1) {
      alert("Please specify feet per page >= 1");
    } else {

      getLocation(function(latlng){

        fetch('/api/addLog', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            jobNumber: isValidated.jobNumber,
            drillDate: isValidated.drillDate,
            location: isValidated.location,
            depth: isValidated.depth,
            gw: isValidated.gw,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            elevation: isValidated.elevation,
            driller: replaceStr(isValidated.driller),
            drillMethod: replaceStr(isValidated.drillMethod),
            drivingWeight: replaceStr(isValidated.drivingWeight),
            percWell: isValidated.percWell,
            feetPerPage: isValidated.feetPerPage
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('jobs', props.filter.jobNumber, '', props.component, 'add', '', props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add log. Contact and admin.')
            console.log('Error: addLog --> ' + error)
          }
        )

      })

    }

  }

  const editData = () => {

    if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
      alert("Please select a JN.");
    } else if (isValidated.drillDate === null || isValidated.drillDate === '') {
      alert("Please select a Drill Date.");
    } else if (isValidated.locaiton === null || isValidated.locaiton === '') {
      alert("Please select a Locaiton.");
    // } else if (isValidated.depth === null || isValidated.depth ==='') {
    //     alert("Please select a Depth.");
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
      alert("Please select a Latitude.");
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
      alert("Please select a Longitude.");
    } else if (isValidated.driller === null || isValidated.driller === '') {
      alert("Please select a Contractor.");
    } else if (isValidated.drillMethod === null || isValidated.drillMethod === '') {
      alert("Please select a Method.");
    } else if (isValidated.drivingWeight === null || isValidated.drivingWeight === '') {
      alert("Please select a Weight.");
    } else if (isValidated.percWell === null || isValidated.percWell === '') {
      alert("Am I a perc?");
    } else if (isValidated.feetPerPage === null || isValidated.feetPerPage === '') {
      alert("Please specify feet per page for log")
    } else if (isValidated.feetPerPage < 1) {
      alert("Please specify feet per page >= 1");
    } else {

      getLocation(function(latlng){

        fetch('/api/editLog', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: getDeviceType(),
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            drillDate: isValidated.drillDate,
            location: isValidated.location,
            depth: isValidated.depth,
            gw: isValidated.gw,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            elevation: isValidated.elevation,
            driller: replaceStr(isValidated.driller),
            drillMethod: replaceStr(isValidated.drillMethod),
            drivingWeight: replaceStr(isValidated.drivingWeight),
            percWell: isValidated.percWell,
            feetPerPage: isValidated.feetPerPage
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: getDeviceType(),
                jobNumber: isValidated.jobNumber,
                drillDate: isValidated.drillDate,
                location: isValidated.location,
                depth: isValidated.depth,
                gw: isValidated.gw,
                latitude: isValidated.latitude,
                longitude: isValidated.longitude,
                elevation: isValidated.elevation,
                driller: replaceStr(isValidated.driller),
                drillMethod: replaceStr(isValidated.drillMethod),
                drivingWeight: replaceStr(isValidated.drivingWeight),
                percWell: isValidated.percWell,
                feetPerPage: isValidated.feetPerPage
              } :
              data
            ))

            addActivity('jobs', props.filter.jobNumber, '', props.component, 'edit', '', props.user.username)

            isChanged.current = false
            closeModal()
            //alert('Edited.')

          },
          (error) => {

            alert('Error: could not edit log. Contact and admin.')
            console.log('Error: editLog --> ' + error)
          }
        )

      })

    }

  }

  const deleteData = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteLog', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          addActivity('jobs', props.filter.jobNumber, '', props.component, 'delete', '', props.user.username)

          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()

        },
        (error) => {

          alert('Error: could not delete log. Contact and admin.')
          console.log('Error: deleteLog --> ' + error)
        }
      )

    }

  }  

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {
    if (props.filter.jobNumber !== '' && props.filter.jobNumber !== null) {
      //console.log(`isValidated: ${JSON.stringify(isValidated)}`)
      //console.log(`props: ${JSON.stringify(props)}`)
      setIsValidated(prevState => ({...prevState, jobNumber: props.filter.jobNumber, latitude: props.filter.latitude, longitude: props.filter.longitude}))
    }

    setIsModal(prevState => ({...prevState, add: true}))
  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))  

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const togglePreview = () => {
    setIsModal(prevState => ({...prevState, preview: isModal.preview ? false : true}))

    if (isModal.preview) {previewRef.current = testPDF()}
  }

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))
  
  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }  

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const closeGoogle = () => setIsModal(prevState => ({...prevState, google: false}))  

  let listOfData = fetchedData.map((data, i) => {

    let aerialDate = data.aerialDate === null ? '' : data.aerialDate
    // let location = data.location === null ? '' : data.location
    // let depth = data.depth === null ? '' : data.depth
    // let gw = data.gw === null ? '' : data.gw
    // let drillDate = data.drillDate === null ? '' : data.drillDate
    // //let loggedBy = data.projectdescription === null ? '' : data.projectdescription
    // let driller = data.driller === null ? '' : data.driller
    // let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    //let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data, searchValue)
    //console.log(`filter ${filter}`)

    //if (filter && ((data.id === selectedLog.id) || selectedLog === '')) {

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow} onMouseOver={hightlightMarker}>
          <td>{aerialDate}</td>
          <td>{data.flightNumber}</td>
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })  

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    marginBottom: 10
  }

  let styleBtn2 = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  let styleBtn3 = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid tomato',
    margin: 10,
    background: `repeating-linear-gradient(-45deg, tomato, tomato 5px, white 5px, white 20px)`
  }

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>        

        <div>
          <label className='label'>Drill Date</label>
          <input className='input' type="date" pattern=".{1,}" name='drillDate' onInput={validate} onChange={changedData} defaultValue={isValidated.drillDate === '' || isValidated.drillDate === null ? null : isValidated.drillDate} required />
        </div>

        <div>
          <label className='label'>Location</label>
          <input style={{width: 75}} className='input' type="text" placeholder='ie B-1' pattern="[A-Z]{1,3}-[0-9]{1,3}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
        </div>

        <div>
          <label className='label'>Depth</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='depth' onInput={validate} onChange={changedData} defaultValue={isValidated.depth} required />
        </div>

        <div>
          <label className='label'>GW</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='gw' onInput={validate} onChange={changedData} defaultValue={isValidated.gw} required />
        </div>

        <CircleButton iconName='add_location' onClick={openLocate} /><br />

        {isValidated.latitude !== null && isValidated.latitude !== '' ? <p>Lat: {isValidated.latitude}</p> : null}
        {isValidated.longitude !== null && isValidated.longitude !== '' ? <p>Lng: {isValidated.longitude}</p> : null}

        <div>
          <label className='label'>Elevation (ft)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elevation' onInput={validate} onChange={changedData} defaultValue={isValidated.elevation} required />
        </div>

        <div>
          <label className='label'>Contractor</label>
          <select className="select" pattern="{1,}" name="driller" onInput={validate} onChange={changedData} defaultValue={isValidated.driller}>
            <option value=""></option>
            <option value="2R Drilling">2R Drilling</option>
            <option value="Gregg Drilling">Gregg Drilling</option>
            <option value="Albus & Associates">Albus & Associates</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isValidated.driller === 'other1' ?
          <div>
            <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='driller' onInput={validate} onChange={changedData} required>{isValidated.driller}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Method</label>
          <select className="select" pattern="{1,}" name="drillMethod" onInput={validate} onChange={changedData} defaultValue={isValidated.drillMethod}>
            <option value=""></option>
            <option value="Hollow-Stem Auger">Hollow-Stem Auger</option>
            <option value="Hand Auger">Hand Auger</option>
            <option value="Bucket Auger">Bucket Auger</option>
            <option value="Backhoe">Backhoe</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isValidated.drillMethod === 'other1' ?
          <div>
            <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='drillMethod' onInput={validate} onChange={changedData} required>{isValidated.drillMethod}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Weight</label>
          <select className="select" pattern="{1,}" name="drivingWeight" onInput={validate} onChange={changedData} defaultValue={isValidated.drivingWeight}>
            <option value=""></option>
            <option value="140 lbs / 30 in">140 lbs / 30 in</option>
            <option value="Hand Driven">Hand Driven</option>
            <option value="other">Other</option>
          </select>
        </div>

        {isValidated.drivingWeight === 'other1' ?
          <div>
            <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='drivingWeight' onInput={validate} onChange={changedData} required>{isValidated.drivingWeight}</textarea>
          </div> : null
        }

        <div>
          <label className='label'>Perc Well?</label>
          <select className="select" pattern="{1,}" name="percWell" onInput={validate} onChange={changedData} defaultValue={isValidated.percWell}>
            <option value=""></option>
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </select>
        </div>

        <div>
          <label className='label'>Feet Per Page</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='feetPerPage' onInput={validate} onChange={changedData} defaultValue={isValidated.feetPerPage} required />
        </div>

      </div>

    </div>
  )  

  return (
    <>
      {isModal.add || isModal.edit ? <Modal close={closeModal} add={isModal.add ? addData : isModal.edit ? editData : null} delete={deleteData} content={modalContent} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} fetchedData={fetchedData} /> : null}
      
      <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <div style={{flex: isModal.google || (isModal.preview && (props.user.device === 'desktop' || props.user.device === 'electron')) ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>

              {props.user.log > 1 ? <Icon name='add_circle' title='Add' onClick={openAdd} /> : null}              
              <Icon name='preview' title='Preview Log' color={isModal.preview ? 'dodgerblue' : 'gray'} onClick={togglePreview} outline={true} />
              <Icon name='location_on' title='View in Google Maps' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
              <Icon name='download' title='Download to Excel' onClick={exportToExcel} />
              <Icon name='refresh' title='Refresh' onClick={fetchData} />

            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <>

                {isModal.google && (props.user.device !== 'desktop' && props.user.device !== 'electron') ?

                  null :

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <table id="toExcel">

                      <thead>
                        <tr>                          
                          <th>Date</th>
                          <th>Flight No</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                }

              </>: <p style={{margin: 10}}>{isModal.loading ? `Loading...` : `No data found.`}</p>

            }            

            {isModal.google && (props.user.device !== 'desktop' && props.user.device !== 'electron') ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
                <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
              </div> : null

            }

          </div>

        </div>

        {isModal.google && (props.user.device === 'desktop' || props.user.device === 'electron') ?

          <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
            <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
          </div> : null

        }

        {isModal.preview && (props.user.device === 'desktop' || props.user.device === 'electron') ?

          <>
            
          </> : null

        }

      </div>

    </>
  )

}

export default Aerial
