import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Locate from 'components/menu/LocateJob'
import JobMenu from 'components/menu/Job'
import PropMenu from 'components/menu/Proposal'
import User from 'components/menu/User'
import Rate from 'components/menu/Rate'
import Client from 'components/menu/Client'
import Contact from 'components/menu/Contact'
import SiteLocation from 'components/utils/SiteLocation'
import ViewClient from 'components/input/Client'
import ViewContact from 'components/input/Contact'

import { formatDateYMD, formatDateTime, getLocation, replaceStr, isElectron, exportToExcel, filterData, addActivity } from 'scripts/common'

const Job = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedReadOnly, setFetchedReadOnly] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    editMode: false,
    edit: false,
    google: false,
    locate: false,
    user: false,
    job: false,
    prop: false,
    rate: false,
    client: false,
    contact: false,
    site: false,
    viewClient: false,
    viewContact: false,
    editAddress: false
  })

  const [isAdd, setIsAdd] = useState({
    newJob: false,
    billingGroup: false,
    needJobMenu: false,
    propToJob: false,
    newProp: false,
    needPropMenu: false
  })

  const [isLoading, setIsLoading] = useState(true)

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    companyId: null,
    company: '',
    contactId: null,
    firstName: '',
    lastName: '',
    proposal: null,
    proposalYear: null,
    proposalDate: null,
    pm: '',
    project: '',
    description: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    elevation: '',
    siteLocation: '',
    siteArea: '',
    status: '',
    jobDate: null,
    accepted: null,
    rate: '',
    prevailingWage: null,
    archiveLocation: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    companyId: null,
    company: '',
    contactId: null,
    firstName: '',
    lastName: '',
    proposal: null,
    proposalYear: null,
    proposalDate: '',
    pm: '',
    project: '',
    description: '',
    address: '',
    city: '',
    county: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    elevation: '',
    siteLocation: '',
    siteArea: '',
    status: '',
    jobDate: '',
    accepted: null,
    rate: '',
    prevailingWage: null,
    archiveLocation: ''
  })

  const clearAdd = () => setIsAdd({
    newJob: false,
    billingGroup: false,
    needJobMenu: false,
    propToJob: false,
    newProp: false,
    needPropMenu: false
  })

  const fetchData = () => {

    if (props.component === 'Job') {

      fetch('/api/selectJobs', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('jobs', props.filter.jobNumber, '', props.component, 'view', '', props.user.username)

          setFetchedData(result.map(data => (
            {...data,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              jobdate: formatDateYMD(data.jobdate)
            }
          )))

          setIsLoading(false)

        },
        (error) => {
          console.log('Error: selectJobs --> ' + error)
        }
      )

    } else {

      fetch('/api/selectProposals', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          addActivity('jobs', props.filter.jobNumber, '', props.component, 'view', '', props.user.username)

          setFetchedData(result.map(data => (
            {...data,
              entrytime: formatDateTime(data.entrytime, true),
              modtime: formatDateTime(data.modtime, true),
              proposaldate: formatDateYMD(data.proposaldate, true)
            }
          )))

          setIsLoading(false)

        },
        (error) => {
          console.log('Error: selectProposals --> ' + error)
        }
      )

    }

  }

  useEffect(() => {
    if (isModal.google) {
      //initMap()

      let data = fetchedData.filter(data => {

        if (data.latitude !== '' && data.latitude !== null && data.longitude !== '' && data.longitude !== null) {

          // let jobNumber = data.jobnumber === null ? '' : data.jobnumber
          // let client = data.company === null ? '' : data.company
          // let lastName = data.lastname === null ? '' : data.lastname
          // let firstName = data.firstname === null ? '' : data.firstname
          // let contact = `${firstName} ${lastName}`.trim()
          // let pn
          //
          // if (data.proposal === null || data.proposal === '') {
          //   pn = ''
          // } else {
          //   pn = `${data.proposal}-${data.proposalyear}`
          // }
          //
          // let pm = data.pm === null ? '' : data.pm
          // let description = data.description === null ? '' : data.description
          // let address = data.address === null ? '' : data.address
          // let city = data.city === null ? '' : data.city
          // let county = data.county === null ? '' : data.county
          // let state = data.state === null ? '' : data.state
          // let zip = data.zip === null ? '' : data.zip
          // let status = data.jobstatus === null ? '' : data.jobstatus
          // let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
          // let entryTime = data.entrytime === null ? '' : data.entrytime

          return true

          //{testNo: testNo, lat: data.latitude, lng: data.longitude}

        }

        return false


      })

      let google = googleRef.current

      mapRef.current = new window.google.maps.Map(google, {
           mapTypeId: window.google.maps.MapTypeId.ROADMAP
      });

      let infoWindow = new window.google.maps.InfoWindow();

      let marker, i, latLng, jn, pn

      //let i, latLng

      let bounds = new window.google.maps.LatLngBounds();

      //console.log(`locations: ${JSON.stringify(data)}`)

      for (i = 0; i < data.length; i++) {

        jn = data[i].jobnumber === null ? '' : data[i].jobnumber
        pn = data[i].proposal === null ? '' : data[i].proposal

        marker = new window.google.maps.Marker({
          position: new window.google.maps.LatLng(data[i].latitude, data[i].longitude),
          title: `Job: ${jn}, Prop: ${pn}`,
          animation: window.google.maps.Animation.DROP,
          map: mapRef.current,
          icon: {
            url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
          },
          id: data[i].id,
          visible: filterData(data[i], searchValue)
        });

        marker.setMap(mapRef.current)

        markerRef.current.push(marker)

        latLng = new window.google.maps.LatLng(data[i].latitude, data[i].longitude);

        bounds.extend(latLng);

        window.google.maps.event.addListener(marker, 'click', function () {
          infoWindow.setContent('<h3>' + this.title + '</h3>');
          infoWindow.open(mapRef.current, this);
        });

      }

      mapRef.current.fitBounds(bounds);

      mapRef.current.setCenter(bounds.getCenter());

    }
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const changedData = () => isChanged.current = true

  const selectRow = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = tr.getAttribute('data-i') //td[4].textContent // usually 0, but listOfData was reorganized

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      if (target.nodeName === 'TD') {

        //console.log(`i: ${i}`)

        //console.log(`derp: ${JSON.stringify(fetchedData)}`)

        setIsValidated(prevState => ({...prevState,
          entryby: fetchedData[i].entryby,
          entrytime: fetchedData[i].entrytime,
          entrylat: fetchedData[i].entrylat,
          entrylng: fetchedData[i].entrylng,
          entrydevice: fetchedData[i].entrydevice,
          modby: fetchedData[i].modby,
          modtime: fetchedData[i].modtime,
          modlat: fetchedData[i].modlat,
          modlng: fetchedData[i].modlng,
          moddevice: fetchedData[i].moddevice,
          id: fetchedData[i].id,
          jobNumber: fetchedData[i].jobnumber,
          companyId: fetchedData[i].companyid,
          company: fetchedData[i].company,
          contactId: fetchedData[i].contactid,
          firstName: fetchedData[i].firstname,
          lastName: fetchedData[i].lastname,
          proposal: fetchedData[i].proposal,
          proposalYear: fetchedData[i].proposalyear,
          proposalDate: fetchedData[i].proposaldate,
          pm: fetchedData[i].pm,
          project: fetchedData[i].project,
          description: fetchedData[i].description,
          address: fetchedData[i].address,
          city: fetchedData[i].city,
          county: fetchedData[i].county,
          state: fetchedData[i].state,
          zip: fetchedData[i].zip,
          latitude: fetchedData[i].latitude,
          longitude: fetchedData[i].longitude,
          elevation: fetchedData[i].elevation,
          siteLocation: fetchedData[i].sitelocation,
          siteArea: fetchedData[i].sitearea,
          status: fetchedData[i].jobstatus,
          jobDate: fetchedData[i].jobdate,
          accepted: fetchedData[i].accepted,
          rate: fetchedData[i].rate,
          prevailingWage: fetchedData[i].prevailingwage,
          archiveLocation: fetchedData[i].archivelocation
        }))
        openEdit()

      }

    }

  }

  const selectLocate = (data) => {

    //console.log(`siteInfo: ${JSON.stringify(data)}`)

    setIsValidated(prevState => ({...prevState,
      latitude: data.latitude,
      longitude: data.longitude,
      elevation: data.elevation,
      address: data.address,
      city: data.city,
      county: data.county,
      state: data.state,
      zip: data.zip,
      siteLocation: data.siteLocation,
      siteArea: data.siteArea
    }))

    isChanged.current = true

    closeLocate()

  }

  const addJob = () => {

    if (!props.user.job > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.rate === null || isValidated.rate === '') {
        alert("Please select a rate.");
    } else if (isValidated.prevailingWage === null || isValidated.prevailingWage === '') {
         alert("Please select Yes or No for PW.");
    } else if (isValidated.client === null || isValidated.client === '') {
        alert("Please select a client.");
    } else if (isValidated.pm === null || isValidated.pm === '') {
        alert("Please select a PM.");
    // } else  if (isValidated.project === null || isValidated.project === '') {
    //     alert("Please provide a project.");
    } else if (isValidated.description === null || isValidated.description === '') {
        alert("Please provide a description.")
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
        alert("Please provide a latitude.")
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
        alert("Please provide a longitude.")
    } else if (isValidated.address === null || isValidated.address === '') {
        alert("Please provide an address.")
    } else if (isValidated.city === null || isValidated.city === '') {
        alert("Please provide a city.")
    } else {

      getLocation(function(latlng){

        fetch('/api/addJob', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            companyId: isValidated.companyId,
            contactId: isValidated.contactId,
            proposal: isValidated.proposal,
            proposalYear: isValidated.proposalYear,
            proposalDate: isAdd.newProp ? formatDateYMD(new Date()) : isValidated.proposalDate,
            pm: isValidated.pm,
            project: replaceStr(isValidated.project),
            description: replaceStr(isValidated.description),
            address: replaceStr(isValidated.address),
            city: isValidated.city,
            county: isValidated.county,
            state: isValidated.state,
            zip: isValidated.zip,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            elevation: isValidated.elevation,
            siteLocation: isValidated.siteLocation,
            siteArea: isValidated.siteArea,
            status: isAdd.newProp ? '' : 'Active',
            jobDate: isAdd.newJob || isAdd.billingGroup ? formatDateYMD(new Date()) : isValidated.jobDate,
            accepted: isAdd.newProp ? 0 : 1,
            rate: isValidated.rate,
            prevailingWage: isValidated.prevailingWage,
            archiveLocation: isValidated.archiveLocation
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('jobs', props.filter.jobNumber, '', props.component, 'add', '', props.user.username)

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            if (isAdd.newProp) createFolders('prop', isValidated.proposal)
            if (isAdd.newJob || isAdd.billingGroup) createFolders('job', isValidated.jobNumber)
            clearAdd()

          },
          (error) => {

            alert('Error: could not add job. Contact and admin.')
            console.log('Error: addJob --> ' + error)
          }
        )

      })

    }

  }

  const editJob = () => {

    if (!props.user.job > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.rate === null || isValidated.rate === '') {
        alert("Please select a rate.");
    } else if (isValidated.prevailingWage === null || isValidated.prevailingWage === '') {
         alert("Please select Yes or No for PW.");
    } else if (isValidated.client === null || isValidated.client === '') {
        alert("Please select a client.");
    } else if (isValidated.pm === null || isValidated.pm === '') {
        alert("Please select a PM.");
    // } else  if (isValidated.project === null || isValidated.project === '') {
    //     alert("Please provide a project.");
    } else if (isValidated.description === null || isValidated.description === '') {
        alert("Please provide a description.")
    } else if (isValidated.latitude === null || isValidated.latitude === '') {
        alert("Please provide a latitude.")
    } else if (isValidated.longitude === null || isValidated.longitude === '') {
        alert("Please provide a longitude.")
    } else if (isValidated.address === null || isValidated.address === '') {
        alert("Please provide an address.")
    } else if (isValidated.city === null || isValidated.city === '') {
        alert("Please provide a city.")
    // } else if (isValidated.status === null || isValidated.status === '') {
    //     alert("Please provide a status.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editJob', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            companyId: isValidated.companyId,
            contactId: isValidated.contactId,
            proposal: isValidated.proposal,
            proposalYear: isValidated.proposalYear,
            proposalDate: isValidated.proposalDate,
            pm: isValidated.pm,
            project: replaceStr(isValidated.project),
            description: replaceStr(isValidated.description),
            address: replaceStr(isValidated.address),
            city: isValidated.city,
            county: isValidated.county,
            state: isValidated.state,
            zip: isValidated.zip,
            latitude: isValidated.latitude,
            longitude: isValidated.longitude,
            elevation: isValidated.elevation,
            siteLocation: isValidated.siteLocation,
            siteArea: isValidated.siteArea,
            status: isAdd.propToJob ? 'Active' : isValidated.status,
            jobDate: isAdd.propToJob ? formatDateYMD(new Date()) : isValidated.jobDate,
            accepted: isAdd.propToJob ? 1 : isValidated.accepted,
            rate: isValidated.rate,
            prevailingWage: isValidated.prevailingWage,
            archiveLocation: isAdd.propToJob || isValidated.status === 'Active' ? '' : isValidated.archiveLocation
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            // setFetchedData(fetchedData.map(data =>
            //   data.id === isValidated.id ?
            //   {...data,
            //     modby: props.user.username,
            //     modtime: formatDateTime(new Date()),
            //     modlat: latlng.lat,
            //     modlng: latlng.lng,
            //     moddevice: props.user.device,
            //     jobnumber: isValidated.jobNumber,
            //     companyid: isValidated.companyId,
            //     contactid: isValidated.contactId,
            //     firstname: isValidated.firstName,
            //     lastname: isValidated.lastName,
            //     proposal: isValidated.proposal,
            //     proposalyear: isValidated.proposalYear,
            //     proposaldate: isValidated.proposalDate,
            //     pm: isValidated.pm,
            //     project: replaceStr(isValidated.project),
            //     description: replaceStr(isValidated.description),
            //     address: isValidated.address,
            //     city: isValidated.city,
            //     county: isValidated.county,
            //     state: isValidated.state,
            //     zip: isValidated.zip,
            //     latitude: isValidated.latitude,
            //     longitude: isValidated.longitude,
            //     elevation: isValidated.elevation,
            //     sitelocation: isValidated.siteLocation,
            //     siteArea: isValidated.sitearea,
            //     status: isAdd.propToJob ? 'Active' : isValidated.status,
            //     jobDate: isAdd.propToJob ? formatDateYMD(new Date()) : isValidated.jobDate,
            //     accepted: isAdd.propToJob ? 1 : isValidated.accepted,
            //     rate: isValidated.rate,
            //     prevailingwage: isValidated.prevailingWage,
            //     archivelocation: isValidated.archiveLocation
            //   } :
            //   data
            // ))

            addActivity('jobs', props.filter.jobNumber, '', props.component, 'edit', '', props.user.username)

            fetchData() // status wont update!&#$* so update all
            isChanged.current = false
            closeModal()
            if (isAdd.propToJob) createFolders('job', isValidated.jobNumber)
            clearAdd()


          },
          (error) => {

            alert('Error: could not edit job. Contact and admin.')
            console.log('Error: editJob --> ' + error)
          }
        )

      })

    }

  }

  const deleteJob = () => {

    if (!props.user.job > 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteJob', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            addActivity('jobs', props.filter.jobNumber, '', props.component, 'delete', '', props.user.username)

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not delete Job. Contact and admin.')
            console.log('Error: deleteJob --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const directions = (e) => {
    let i = parseInt(e.currentTarget.getAttribute('data-i'))
    window.open(`https://www.google.com/maps/search/?api=1&query=${fetchedData[i].latitude},${fetchedData[i].longitude}`)
  }

  const missingLocation = () => alert('Missing latitude and longitude.')

  //const viewEntryLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.entrylat},${isValidated.entrylnt}`)

  //const viewModLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.modlat},${isValidated.modlng}`)

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const toggleEdit = () => setIsModal(prevState => ({...prevState, editMode: isModal.editMode ? false : true}))

  const toggleEditAddress = () => setIsModal(prevState => ({...prevState, editAddress: isModal.editAddress ? false : true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const openSite = (e) => {

    let i = parseInt(e.currentTarget.getAttribute('data-i'))

    setIsValidated(prevState => ({...prevState,
      latitude: fetchedData[i].latitude,
      longitude: fetchedData[i].longitude,
      siteLocation: fetchedData[i].sitelocation,
      siteArea: fetchedData[i].sitearea
    }))

    setIsModal(prevState => ({...prevState, site: true}))

  }

  const closeSite = () => {
    setIsModal(prevState => ({...prevState, site: false}))
    clearIsValidated()
  }

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const openProp = () => setIsModal(prevState => ({...prevState, prop: true}))

  const closeProp = () => setIsModal(prevState => ({...prevState, prop: false}))

  const openUser = () => setIsModal(prevState => ({...prevState, user: true}))

  const closeUser = () => setIsModal(prevState => ({...prevState, user: false}))

  const openRate = () => setIsModal(prevState => ({...prevState, rate: true}))

  const closeRate = () => setIsModal(prevState => ({...prevState, rate: false}))

  const openClient = () => setIsModal(prevState => ({...prevState, client: true}))

  const closeClient = () => setIsModal(prevState => ({...prevState, client: false}))

  const openViewClient = (e) => {

    let i = parseInt(e.currentTarget.getAttribute('data-i'))

    fetch('/api/selectClientReadOnly', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: fetchedData[i].companyid
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedReadOnly(result)

        setIsModal(prevState => ({...prevState, viewClient: true}))

      },
      (error) => {
        console.log('Error: selectClientReadOnly --> ' + error)
      }
    )

  }

  const closeViewClient = () => {
    setIsModal(prevState => ({...prevState, viewClient: false}))
    setFetchedReadOnly([])
  }

  const openContact = () => setIsModal(prevState => ({...prevState, contact: true}))

  const closeContact = () => setIsModal(prevState => ({...prevState, contact: false}))

  const openViewContact = (e) => {

    let i = parseInt(e.currentTarget.getAttribute('data-i'))

    fetch('/api/selectContactReadOnly', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: fetchedData[i].contactid
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedReadOnly(result)

        setIsModal(prevState => ({...prevState, viewContact: true}))

      },
      (error) => {
        console.log('Error: selectContactReadOnly --> ' + error)
      }
    )

  }

  const closeViewContact = () => {
    setIsModal(prevState => ({...prevState, viewContact: false}))
    setFetchedReadOnly([])
  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false

        setIsAdd(prevState => ({
          newJob: false,
          billingGroup: false,
          propToJob: false,
          newProp: false
        }))
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()

      setIsAdd(prevState => ({
        newJob: false,
        billingGroup: false,
        propToJob: false,
        newProp: false
      }))
    }

  }

  const newJob = async (who, pn) => {

    // if proposal and convertPropToJob, then need to fetch max JN since the proposal comp does not grab them all...

    //   let maxJobNo = Number(fetchedData[0].jobnumber)
    //   let newJobNo = Number(maxJobNo) + 1 // remove decimal in case of billing group
    //   newJobNo = newJobNo.toFixed(2)
    //   //console.log(`maxJobNo: ${maxJobNo}`)
    //   console.log(`newJobNo: ${newJobNo}`)
    //
    //   setIsValidated(prevState => ({...prevState, jobNumber: newJobNo}))



    let response = await fetch('/api/maxJN', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

        let maxJobNo = Number(result[0].maxJN)
        let newJobNo = Number(maxJobNo) + 1 // remove decimal in case of billing group
        newJobNo = newJobNo.toFixed(2)
        //console.log(`maxJobNo: ${maxJobNo}`)
        //console.log(`newJobNo: ${newJobNo}`)

        if (who === 'prop') {
          //console.log(`who: ${who}`)
          let prop = String(pn).substring(0, 4)
          //console.log(`prop: ${prop}`)
          let data = fetchedData.filter(o => prop === String(o.proposal))
          //console.log(`data: ${JSON.stringify(data)}`)
          data = data[0]

          setIsValidated(prevState => ({...prevState,
            entryby: data.entryby,
            entrytime: data.entrytime,
            entrylat: data.entrylat,
            entrylng: data.entrylng,
            entrydevice: data.entrydevice,
            modby: data.modby,
            modtime: data.modtime,
            modlat: data.modlat,
            modlng: data.modlng,
            moddevice: data.moddevice,
            id: data.id,
            jobNumber: newJobNo,
            companyId: data.companyid,
            company: data.company,
            contactId: data.contactid,
            firstName: data.firstname === null ? '' : data.firstname,
            lastName: data.lastname === null ? '' : data.lastname,
            proposal: data.proposal,
            proposalYear: data.proposalyear,
            proposalDate: data.proposaldate,
            pm: data.pm,
            project: data.project,
            description: data.description,
            address: data.address,
            city: data.city,
            county: data.county,
            state: data.state,
            zip: data.zip,
            latitude: data.latitude,
            longitude: data.longitude,
            elevation: data.elevation,
            siteLocation: data.sitelocation,
            siteArea: data.sitearea,
            status: data.jobstatus,
            jobDate: data.jobdate,
            accepted: data.accepted,
            rate: data.rate,
            prevailingWage: data.prevailingwage,
            archiveLocation: data.archivelocation
          }))

        } else {

          setIsValidated(prevState => ({...prevState, jobNumber: newJobNo}))

        }

        return true

      },
      (error) => {
        alert('Error: could not determine the next JN. Contact an admin')
        console.log('Error: maxJN --> ' + error)

        return false
      }
    )

    return response

  }

  const createNewJob = () => {

    newJob()
    .then(res => {
      setIsAdd(prevState => ({
        newJob: true,
        billingGroup: false,
        needJobMenu: false,
        propToJob: false,
        newProp: false,
        needPropMenu: false
      }))
    })

  }

  const billingGroup = (job) => {

    console.log(`job: ${job}`)

    let rootJN = job !== undefined ? job.substring(0, 4) : props.filter.jobNumber.substring(0, 4)

    console.log(`rootJN: ${rootJN}`)

    let data
    let bg = Math.max.apply(Math, fetchedData.reduce((newArr, o) => {
      if (o.jobnumber.substring(0, 4) === rootJN) {
        data = o
        newArr.push(Number(o.jobnumber.substring(5)))
      }
      return newArr;
    }, []))

    console.log(`bg: ${bg}`)

    let newBG = bg + 1

    console.log(`newBG: ${newBG}`)

    let newJobNo = newBG < 10 ? `${rootJN}.0${newBG}` : `${rootJN}.${newBG}`

    console.log(`newJobNo: ${newJobNo}`)

    console.log(`data: ${JSON.stringify(data)}`)

    setIsValidated(prevState => ({...prevState,
      jobNumber: newJobNo,
      companyId: data.companyid,
      company: data.company,
      contactId: data.contactid,
      firstName: data.firstname,
      lastName: data.lastname,
      project: data.project,
      address: data.address,
      city: data.city,
      county: data.county,
      state: data.state,
      zip: data.zip,
      latitude: data.latitude,
      longitude: data.longitude,
      elevation: data.elevation,
      siteLocation: data.sitelocation,
      siteArea: data.sitearea
    }))

    setIsAdd(prevState => ({
      newJob: false,
      billingGroup: true,
      needJobMenu: false,
      propToJob: false,
      newProp: false,
      needPropMenu: false
    }))

  }

  const addBillingGroup = () => {

    if (props.filter.jobNumber !== '') {

      billingGroup(props.filter.jobNumber)

    } else {

      setIsAdd(prevState => ({
        newJob: false,
        billingGroup: true,
        needJobMenu: true,
        propToJob: false,
        newProp: false,
        needPropMenu: true
      }))

    }

  }

  const convertPropToJob = (selected, pn) => {

    //console.log(`filter: ${JSON.stringify(props.filter)}`)
    //console.log(`val: ${JSON.stringify(isValidated)}`)

    if ((props.filter.jobNumber === '' && props.filter.proposal !== '' && props.filter.proposal !== null) || selected === true) {
      //console.log(`pn: ${pn}`)
      //console.log(`pn??: ${pn !== undefined ? true : false}`)
      //console.log(`${(props.filter.jobNumber === '' && props.filter.proposal !== '' && props.filter.proposal !== null) || selected === true ? true : false}`)
      newJob('prop', pn !== undefined ? pn : props.filter.proposal)
      .then(res => {
        setIsAdd(prevState => ({
          newJob: false,
          billingGroup: false,
          needJobMenu: false,
          propToJob: true,
          newProp: false,
          needPropMenu: false
        }))

        closeProp()
      })

    } else {

      setIsAdd(prevState => ({
        newJob: false,
        billingGroup: false,
        needJobMenu: false,
        propToJob: true,
        newProp: false,
        needPropMenu: true
      }))

    }

  }

  const createNewProp = () => {

    let maxPropNo = Number(fetchedData[0].proposal)
    let newPropNo = Number(maxPropNo) + 1

    //console.log(`maxJobNo: ${maxPropNo}`)
    //console.log(`newPropNo: ${newPropNo}`)

    let d = new Date()
    let y = String(d.getFullYear())
    console.log(`year: ${y.substring(2)}`)
    setIsValidated(prevState => ({...prevState, proposal: newPropNo, proposalYear: y.substring(2)}))

    setIsAdd(prevState => ({
      newJob: false,
      billingGroup: false,
      needJobMenu: false,
      propToJob: false,
      newProp: true,
      needPropMenu: false
    }))

  }

  const selectJob = (e) => {

    // let target = e.target
    // //console.log(`target: ${target.nodeName}`)
    // let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    // let td = tr.getElementsByTagName('td')
    //
    //
    // if (target.nodeName === 'TD') {



    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

      billingGroup(td[4].textContent)

      setIsAdd(prevState => ({
        newJob: false,
        billingGroup: true,
        needJobMenu: false,
        propToJob: false,
        newProp: false,
        needPropMenu: false
      }))

      closeJob()

    //}

  }

  const clearJob = () => {
    // NEEDED?
  }

  const selectProp = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    convertPropToJob(true, td[2].textContent)

    // setIsAdd(prevState => ({
    //   newJob: false,
    //   billingGroup: false,
    //   needJobMenu: false,
    //   propToJob: true,
    //   newProp: false,
    //   needPropMenu: false
    // }))

    // closeProp() //moved to convertPropToJob

  }

  const clearProp = () => {
    // NEEDED?
  }

  const selectUser = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,
      pm: td[0].textContent
    }))

    changedData()

    closeUser()

  }

  const clearUser = () => {
    setIsValidated(prevState => ({...prevState, pm: ''}))
    changedData()
    closeUser()
  }

  const selectRate = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,
      rate: td[1].textContent
    }))

    changedData()

    closeRate()

  }

  const clearRate = () => {
    setIsValidated(prevState => ({...prevState, rate: ''}))
    changedData()
    closeRate()
  }

  const selectClient = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,
      companyId: td[1].textContent,
      company: td[2].textContent,
      contactId: null,
      firstName: '',
      lastName: ''
    }))
    changedData()
    closeClient()

  }

  const clearClient = () => {

    setIsValidated(prevState => ({...prevState,
      companyId: null,
      company: '',
      contactId: null,
      firstName: '',
      lastName: ''
    }))
    changedData()
    closeClient()
  }

  const selectContact = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,
      contactId: td[1].textContent,
      firstName: td[2].textContent,
      lastName: td[3].textContent
    }))

    changedData()

    closeContact()

  }

  const clearContact = () => {
    setIsValidated(prevState => ({...prevState, contactId: null, firstName: '', lastName: ''}))
    changedData()
    closeContact()
  }

  const createFolders = (who, what) => {

    // create folders

    let fs = window.require('fs')

    let path

    let drive = 'T'

    if (who == 'job') {

      path = `${drive}:/Job Support`

      let jn = what

      fs.mkdir(path, (err) => {

        path = `${drive}:/Job Support/- ${jn.substring(0, 2)}00`

        fs.mkdir(path, (err) => {

          path = `${drive}:/Job Support/- ${jn.substring(0, 2)}00/${jn}`

          fs.mkdir(path, (err) => {

            fs.mkdir(path+'/Analysis', err => err ? console.log(`Err: ${err}`) : console.log('create Analysis'))
            fs.mkdir(path+'/Lab Data', err => err ? console.log(`Err: ${err}`) : console.log('create Lab'))
            fs.mkdir(path+'/Plans', err => err ? console.log(`Err: ${err}`) : console.log('create Plans'))
            fs.mkdir(path+'/Plates and Appendices', err => err ? console.log(`Err: ${err}`) : console.log('create Plates'))
            fs.mkdir(path+'/Site Photos', err => err ? console.log(`Err: ${err}`) : console.log('create Photos'))

          })

        })

      })

    } else if (who === 'prop') {

      path = `${drive}:/PN`

      let pn = String(what)

      fs.mkdir(path, (err) => {

        path = `${drive}:/PN/${pn.substring(0, 2)}00`

        fs.mkdir(path, err => err ? console.log(`Err: ${err}`) : console.log('created'))

      })

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jobNumber = data.jobnumber === null ? '' : data.jobnumber
    let client = data.company === null ? '' : data.company
    let lastName = data.lastname === null ? '' : data.lastname
    let firstName = data.firstname === null ? '' : data.firstname
    let contact = `${firstName} ${lastName}`.trim()
    let pn

    if (data.proposal === null || data.proposal === '') {
      pn = ''
    } else {
      pn = `${data.proposal}-${data.proposalyear}`
    }

    let pm = data.pm === null ? '' : data.pm
    let description = data.description === null ? '' : data.description
    description += data.project === null ? '' : data.project === '' ? '' : `, ${data.project}`
    let address = data.address === null ? '' : data.address
    let city = data.city === null ? '' : data.city
    let county = data.county === null ? '' : data.county
    let state = data.state === null ? '' : data.state
    let zip = data.zip === null ? '' : data.zip
    let rate = data.rate === null ? '' : data.rate
    let pw = data.prevailingwage === 1 ? 'Yes' : 'No'
    let status = data.jobstatus === null ? '' : data.jobstatus
    let archiveLocation = data.archivelocation === null ? '' : data.archivelocation
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data, searchValue)
    //console.log(`filter ${JSON.stringify(data)}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      // im piggy backing selectJob which is used by JobMenu as well, hence the td positions

      return (
        <tr key={data.id.toString()} data-i={i} data-id={data.id} onClick={isModal.editMode ? selectRow : props.selectJob} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{data.latitude}</td>
          <td style={{display: 'none'}}>{data.longitude}</td>
          <td style={{display: 'none'}}>{data.sitelocation}</td>
          <td style={{display: 'none'}}>{data.sitearea}</td>
          <td style={{display: 'none'}}>{jobNumber}</td>
          <td style={{display: 'none'}}>{pn}</td>
          {isModal.google ?
            <td>
              {data.latitude === '' || data.latitude === null || data.longitude === '' || data.longitude === null ? <Icon name='wrong_location' color='tomato' /> : null}
            </td> : null
          }
          <td>{jobNumber}</td>
          <td>{pn}</td>
          <td style={{width: 100}}>{client}</td>
          <td><Icon name='info' title='View Client' i={i} outline={true} onClick={openViewClient} /></td>
          <td style={{width: 100}}>{contact}</td>
          <td>{contact !== '' ? <Icon name='info' title='View Contact' i={i} outline={true} onClick={openViewContact} /> : null}</td>
          <td style={{width: 100}}>{description}</td>
          {isModal.google ? null :
            <>
              <td>{pm}</td>
              <td style={{maxWidth: 200}}>{address}, {city}, {county}, {state} {zip}</td>
              <td>{rate}</td>
              <td>{pw}</td>
              <td>{status}</td>
              <td style={{maxWidth: 100, wordWrap: 'break-word'}}>{archiveLocation}</td>
            </>
          }
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  const missingClient = () => alert('Please select a client first.')

  const notElectron = () => alert('Please use the desktop version of the Job App to add Jobs and Props. However, you can still edit through the web version.')

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    marginTop: 100
  }

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {!isModal.edit && !isAdd.newJob && !isAdd.billingGroup && !isAdd.propToJob && !isAdd.newProp ?

        <div style={{textAlign: 'center'}}>
          <div style={{display: 'inline-block'}}>

            {props.component === 'Job' ?

              <>

                <div style={styleBtn} onClick={createNewJob}>
                  <Icon name='add_circle_outline' />
                  <span style={{marginRight: 10}}>Create New Job</span>
                </div>

                <div style={styleBtn} onClick={addBillingGroup}>
                  <Icon name='control_point_duplicate' />
                  <span style={{marginRight: 10}}>Add Billing Group</span>
                </div>

              </> :

              <>

                <div style={styleBtn} onClick={createNewProp}>
                  <Icon name='add_circle_outline' />
                  <span style={{marginRight: 10}}>Create New Prop</span>
                </div>

                <div style={styleBtn} onClick={convertPropToJob}>
                  <Icon name='arrow_circle_right' />
                  <span style={{marginRight: 10}}>Convert Prop to Job</span>
                </div>

              </>

            }

          </div>
        </div> : null

      }

      {isAdd.billingGroup && isAdd.needJobMenu ?

        <div style={{textAlign: 'center'}}>
          <div style={{display: 'inline-block'}}>
            <div style={styleBtn} onClick={openJob}>
              <Icon name='search' />
              <span style={{marginRight: 10}}>Select a Job</span>
            </div>
          </div>
        </div> : null

      }

      {
        isAdd.propToJob && isAdd.needPropMenu ?

        <div style={{textAlign: 'center'}}>
          <div style={{display: 'inline-block'}}>
            <div style={styleBtn} onClick={openProp}>
              <Icon name='search' />
              <span style={{marginRight: 10}}>Select a Prop</span>
            </div>
          </div>
        </div> : null

      }

      {
        isModal.edit ||
        isAdd.newJob ||
        isAdd.newProp ||
        (isAdd.billingGroup && !isAdd.needJobMenu) ||
        (isAdd.propToJob && !isAdd.needPropMenu) ?

        <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

          <div>
            <label className='label'>JN</label>
            <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} disabled />
          </div>

          <div>
            <label className='label'>PN</label>
            <input style={{width: 75}} className='input' type="text" value={isValidated.proposal === '' || isValidated.proposal === null ? '' : `${isValidated.proposal}-${isValidated.proposalYear}`} disabled />
          </div>

          <div>
            <label className='label'>Rate</label>
            <input style={{width: 75}} className='input' type="text" value={isValidated.rate} onClick={openRate} readOnly />
          </div>

          <div>
            <label className='label'>PW?</label>
            <select className='select' pattern=".{1,}" name='prevailingWage' onInput={validate} onChange={changedData} defaultValue={isValidated.prevailingWage} required>
              <option value=""></option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>

          {isModal.edit && isValidated.jobNumber !== '' && isValidated.jobNumber !== null ?

            <div>
              <label className='label'>Status</label>
              <select className='select' pattern=".{1,}" name='status' onInput={validate} onChange={changedData} defaultValue={isValidated.status} required>
                <option value=""></option>
                <option value="Active">Active</option>
                <option value="Archived">Archived</option>
                <option value="Cancelled">Cancelled</option>
                <option value="On Hold">On Hold</option>
                <option value="To Be Archived">To Be Archived</option>
                <option value="Reports Only">Reports Only</option>
              </select>
            </div> : null

          }

          {isValidated.status === 'Archived' || isValidated.status === 'Reports Only' ?

            <div>
              <label className='label'>Location</label>
              <input style={{width: 100}} className='input' type="text" pattern=".{1,}" name='archiveLocation' onInput={validate} onChange={changedData} defaultValue={isValidated.archiveLocation} required />
            </div> : null

          }

          <div>
            <div><label className='label'>Client</label></div>
            <textarea style={{height: 50}} className='textArea' value={isValidated.company} onClick={openClient} readOnly></textarea>
          </div>

          <div>
            <div><label className='label'>Contact</label></div>
            <textarea style={{height: 50}} className='textArea' value={`${isValidated.firstName} ${isValidated.lastName}`} onClick={isValidated.companyId !== '' && isValidated.companyId !== null ? openContact : missingClient} readOnly></textarea>
          </div>

          <div>
            <label className='label'>PM</label>
            <input style={{width: 200}} className='input' type="text" value={isValidated.pm} onClick={openUser} />
          </div>

          <div>
            <div><label className='label'>Project</label></div>
            <textarea style={{height: 100}} className='textArea' placeholder='ie Project Name, this appears in the Logs' pattern="[a-zA-Z0-9]{1,}" name='project' onInput={validate} onChange={changedData} defaultValue={isValidated.project} required></textarea>
          </div>

          <div>
            <div><label className='label'>Description</label></div>
            <textarea style={{height: 200}} className='textArea' placeholder='more details means better filtering...' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} required></textarea>
          </div>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          <div style={{display: 'flex', alignItems: 'center'}}>
            {isValidated.siteLocation !== null && isValidated.siteLocation !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: isValidated.siteLocation !== null && isValidated.siteLocation !== '' ? 'dodgerblue' : 'tomato'}}>Site Location</span>
          </div>

          {isValidated.siteLocation !== null && isValidated.siteLocation !== '' ? <p>Area: {Math.round((isValidated.siteArea) * 10) / 10} acres</p> : null}

          <div style={{display: 'flex', alignItems: 'center'}}>
            {isValidated.latitude !== null && isValidated.latitude !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: isValidated.latitude !== null && isValidated.latitude !== '' ? 'dodgerblue' : 'tomato'}}>Lat/Lng</span>
          </div>

          {isValidated.latitude !== null && isValidated.latitude !== '' ? <p>{isValidated.latitude}, {isValidated.longitude}</p> : null}

          <div style={{display: 'flex', alignItems: 'center'}}>
            {isValidated.address !== null && isValidated.address !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: isValidated.address !== null && isValidated.address !== '' ? 'dodgerblue' : 'tomato'}}>Address</span>
          </div>

          {isModal.editAddress ?
            <>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Icon name='info' color='orange' outline={true} />
                <span style={{verticalAlign: 'base-line', color: 'orange'}}>Editing the address will NOT update the lat/lng</span>
              </div>

              <div>
                <div><label className='label'>Address</label></div>
                <textarea style={{height: 50}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='address' onInput={validate} onChange={changedData} defaultValue={isValidated.address} required></textarea>
              </div>

              <div>
                <div><label className='label'>City</label></div>
                <textarea style={{height: 50}} className='textArea' pattern="[a-zA-Z]{1,}" name='city' onInput={validate} onChange={changedData} defaultValue={isValidated.city} required></textarea>
              </div>

            </>: null
          }

          {isValidated.address !== null && isValidated.address !== '' ?
            <>
              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={toggleEditAddress}>
                <span style={{verticalAlign: 'base-line', marginRight: 10}}>
                  <p>{isValidated.address}</p>
                  <p>{isValidated.city}, {isValidated.county}, {isValidated.state} {isValidated.zip}</p>
                </span>
                <Icon name='edit' title='edit' color={isModal.editAddress ? 'dodgerblue' : 'gray'} />
              </div>

            </> : null
          }

          <div>
            <label className='label'>Elevation (ft)</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elevation' onInput={validate} onChange={changedData} defaultValue={isValidated.elevation} required />
          </div>

        </div> : null

      }

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ?
        <Modal
          close={closeModal}
          add={
            isModal.add && (isAdd.newJob || isAdd.newProp || (isAdd.billingGroup && !isAdd.needJobMenu)) ?
            addJob :
            isModal.edit || (isAdd.propToJob && !isAdd.needPropMenu) ?
            editJob : null
          }
          delete={isModal.edit ? deleteJob : null}
          content={modalContent}
          isValidated={isValidated}
        /> : null
      }
      {isModal.site ? <SiteLocation close={closeSite} latlng={isValidated} /> : null}
      {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} /> : null}
      {isModal.job ? <JobMenu selectJob={selectJob} clearJob={clearJob} closeModal={closeJob} /> : null}
      {isModal.prop ? <PropMenu selectProp={selectProp} clearProp={clearProp} closeModal={closeProp} /> : null}
      {isModal.user ? <User selectUser={selectUser} clearUser={clearUser} closeModal={closeUser} /> : null}
      {isModal.rate ? <Rate user={props.user} selectRate={selectRate} clearRate={clearRate} closeModal={closeRate} /> : null}
      {isModal.client ? <Client user={props.user} selectClient={selectClient} clearClient={clearClient} closeModal={closeClient} modal={true} /> : null}
      {isModal.contact ? <Contact user={props.user} selectContact={selectContact} clearContact={clearContact} closeModal={closeContact} companyId={isValidated.companyId} modal={true} /> : null}
      {isModal.viewClient ? <ViewClient close={closeViewClient} data={fetchedReadOnly[0]} readOnly={true} /> : null}
      {isModal.viewContact ? <ViewContact close={closeViewContact} data={fetchedReadOnly[0]} readOnly={true} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <div style={{flex: isModal.google ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

          <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

            <div style={{width: '100%'}}>

              {props.user.job > 1 ?
                <>
                  <Icon name='add_circle' title='add' onClick={isElectron() ? openAdd : notElectron} />
                  <Icon name='edit' title='edit' color={isModal.editMode ? 'dodgerblue' : 'gray'} onClick={toggleEdit} />
                </> : null
              }
              <Icon name='refresh' title='refresh' onClick={fetchData} />
              <Icon name='location_on' title='View in Google Maps' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
              <Icon name='download' title='Download to Excel' onClick={exportToExcel} />

            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{flex: '1', overflow: 'auto'}}>

                <table id="toExcel">

                  <thead>
                    <tr>
                      <th style={{display: 'none'}}>lat</th>
                      <th style={{display: 'none'}}>lng</th>
                      <th style={{display: 'none'}}>jn</th>
                      <th style={{display: 'none'}}>pn</th>
                      <th style={{display: 'none'}}>id</th>
                      {isModal.google ? <th></th> : null}
                      <th>JN</th>
                      <th>PN</th>
                      <th>Client</th>
                      <th></th>
                      <th>Contact</th>
                      <th></th>
                      <th>Descrip</th>
                      {isModal.google ? null :
                        <>
                          <th>PM</th>
                          <th>Address</th>
                          <th>Rate</th>
                          <th>PW</th>
                          <th>Status</th>
                          <th></th>
                        </>
                      }
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div> : <p style={{margin: 10}}>{isLoading ? `Loading...` : `No Jobs found.`}</p>

            }

          </div>

        </div>

        {isModal.google ?

          <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
            <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
          </div> : null

        }

      </div>

    </>
  )

}

export default Job
