import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'

const Home = (props) => {

  const [fetchedData, setFetchedData] = useState('')

  const fetchData = () => {

    fetch('/api/selectJob', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: props.filter.jobNumber
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result[0])
      },
      (error) => {
        console.log('Error: selectMenuJob --> ' + error)
      }
    )

  }

  useEffect(() => {
    if (props.filter.jobNumber !== '') fetchData()
  }, [props.filter])

  return (
    <div style={{display: 'flex', overflowX: 'auto'}}>

      <div style={{flex: '1 1 auto'}}>

        {fetchedData !== '' ?
          <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

          <div>
            <label className='label'>JN</label>
            <input style={{width: 75}} className='input' type="text" value={fetchedData.jobnumber} disabled />
          </div>

          <div>
            <label className='label'>PN</label>
            <input style={{width: 75}} className='input' type="text" value={fetchedData.proposal === '' || fetchedData.proposal === null ? '' : `${fetchedData.proposal}-${fetchedData.proposalyear}`} disabled />
          </div>

          <div>
            <label className='label'>Rate</label>
            <input style={{width: 75}} className='input' type="text" value={fetchedData.rate} disabled />
          </div>

          <div>
            <label className='label'>PW?</label>
            <input style={{width: 75}} className='input' type="text" value={fetchedData.prevailingWage === 1 ? 'Yes' : 'No'} disabled />
          </div>

          <div>
            <label className='label'>Status</label>
            <input className='input' type="text" value={fetchedData.status} disabled />
          </div>

          {fetchedData.status === 'Archived' ?

            <div>
              <label className='label'>Location</label>
              <input className='input' type="text" value={fetchedData.archiveLocation} disabled />
            </div> : null

          }

          <div>
            <div><label className='label'>Client</label></div>
            <textarea style={{height: 50}} className='textArea' value={fetchedData.company} readOnly></textarea>
          </div>

          <div>
            <div><label className='label'>Contact</label></div>
            <textarea style={{height: 50}} className='textArea' value={`${fetchedData.firstname} ${fetchedData.lastname}`} readOnly></textarea>
          </div>

          <div>
            <label className='label'>PM</label>
            <input style={{width: 200}} className='input' type="text" value={fetchedData.pm} disabled />
          </div>

          <div>
            <div><label className='label'>Project</label></div>
            <textarea style={{height: 100}} className='textArea' value={fetchedData.project} readOnly></textarea>
          </div>

          <div>
            <div><label className='label'>Description</label></div>
            <textarea style={{height: 200}} className='textArea' value={fetchedData.description} readOnly></textarea>
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
            {fetchedData.siteLocation !== null && fetchedData.siteLocation !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: fetchedData.siteLocation !== null && fetchedData.siteLocation !== '' ? 'dodgerblue' : 'tomato'}}>Site Location</span>
          </div>

          {fetchedData.siteLocation !== null && fetchedData.siteLocation !== '' ? <p>Area: {Math.round((fetchedData.sitearea) * 10) / 10} acres</p> : null}

          <div style={{display: 'flex', alignItems: 'center'}}>
            {fetchedData.latitude !== null && fetchedData.latitude !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: fetchedData.latitude !== null && fetchedData.latitude !== '' ? 'dodgerblue' : 'tomato'}}>Lat/Lng</span>
          </div>

          {fetchedData.latitude !== null && fetchedData.latitude !== '' ? <p>{fetchedData.latitude}, {fetchedData.longitude}</p> : null}

          <div style={{display: 'flex', alignItems: 'center'}}>
            {fetchedData.address !== null && fetchedData.address !== '' ? <Icon name='where_to_vote' outline={true} /> : <Icon name='not_listed_location' color='tomato' outline={true} />}
            <span style={{verticalAlign: 'base-line', color: fetchedData.address !== null && fetchedData.address !== '' ? 'dodgerblue' : 'tomato'}}>Address</span>
          </div>

          {fetchedData.address !== null && fetchedData.address !== '' ?
            <>
              <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <span style={{verticalAlign: 'base-line', marginRight: 10}}>
                  <p>{fetchedData.address}</p>
                  <p>{fetchedData.city}, {fetchedData.county}, {fetchedData.state} {fetchedData.zip}</p>
                </span>
              </div>

            </> : null
          }

          <div>
            <label className='label'>Elevation (ft)</label>
            <input style={{width: 75}} className='input' type="text" value={fetchedData.elevation} disabled />
          </div>

        </div> : <p>job?</p>

      }

      </div>

    </div>
  )
}

export default Home
