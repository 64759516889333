import React from 'react'
import Modal from 'components/utils/Modal'
import CircleButton from 'components/utils/CircleButton'

const Client = (props) => {

  let content = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

        <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

          <div>
            <div><label className='label'>Client</label></div>
            <textarea style={{height: 100}} className='textArea' pattern="[a-zA-Z0-9]{1,}" name='client' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.client} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

          <div>
            <div><label className='label'>Notes</label></div>
            <textarea style={{height: 100}} className='textArea' placeholder='optional' pattern="[a-zA-Z0-9]{1,}" name='notes' onInput={props.validate} onChange={props.changedData} defaultValue={props.data.notes} required readOnly={props.readOnly ? true : false}></textarea>
          </div>

          {props.readOnly ? null : <><CircleButton iconName='add_location' onClick={props.openLocate} /><br /></>}

          {props.data.latitude !== null && props.data.latitude !== '' ? <p>Lat: {props.data.latitude}</p> : null}
          {props.data.longitude !== null && props.data.longitude !== '' ? <p>Lng: {props.data.longitude}</p> : null}

          {props.data.address !== null && props.data.address !== '' ?
            <>
              <p>Address: {props.data.address}</p>
              <p>{props.data.city}, {props.data.county}, {props.data.state} {props.data.zip}</p>
            </> : null
          }

        </div>

    </div>
  )

  return <Modal content={content} add={props.add} delete={props.delete} close={props.close} />

}

export default Client
