import React, { useState} from 'react'

import Home from 'components/manage/Home'
import Permissions from 'components/manage/Permissions'
import Client from 'components/menu/Client'
import Contact from 'components/menu/Contact'

const Manage = (props) => {

  // the addition of component controls add in entry / manage

  return (
    <>
      {props.componentManage === 'Home' ? <Home user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Permissions' ? <Permissions user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Client' ? <Client user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Contact' ? <Contact user={props.user} filter={props.filter} /> : null}
    </>
  )

}

export default Manage
