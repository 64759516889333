
//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function distance(lat1, lon1, lat2, lon2) {

  lat1 = Number(lat1)
  lat2 = Number(lat2)
  lon1 = Number(lon1)
  lon2 = Number(lon2)

  // console.log(`
  //   lat1: ${lat1}
  //   lat2: ${lat2}
  //   lon1: ${lon1}
  //   lon2: ${lon2}
  //   `)

  let r = 6371 // km
  let dLat = toRad(lat2-lat1)
  let dLon = toRad(lon2-lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  // console.log(`
  //   dLat: ${dLat}
  //   dLon: ${dLon}
  //   `)

  let a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  let d = r * c

  // console.log(`
  //   a: ${a}
  //   c: ${c}
  //   d: ${d}
  //   `)
  //console.log(`d: ${d}`)
  return d
}

// Converts numeric degrees to radians
function toRad(value) {
  return value * Math.PI / 180;
}

export const googleEarth = (filter, jobs, logs) => {

  const fs = window.require('fs')

  // var dir = './derpy';
  //
  // if (!fs.existsSync(dir)){
  //     fs.mkdirSync(dir);
  // }

  async function awaitSiteLocation(coords) {
    //console.log(`coords ${JSON.stringify(coords)}`)
    let coordArr
    let txt = ''

    for (let i=0; i < coords.length; i++) {

      let result = await Promise.resolve(i)
      coordArr = coords[i].split(',')
      txt += `${coordArr[1]},${coordArr[0]},0 `

    }

    // close the polygon
    coordArr = coords[0].split(',')
    txt += `${coordArr[1]},${coordArr[0]},0 `

    return txt

  }

  async function awaitJobs(jobs) {
//console.log(`jobs: ${JSON.stringify(jobs)}`)
    let polygon
    let txt = ''

    for (let i=0; i < jobs.length; i++) {

      let result = await Promise.resolve(i)

      let radius = distance(jobs[i].latitude, jobs[i].longitude, filter.latitude, filter.longitude) // 8 km

      if (jobs[i].jobnumber !== '' && jobs[i].jobnumber !== null &&
        (filter.jobNumber !== '' && filter.jobNumber !== null && radius < 8) ||
        (filter.jobNumber === '' || filter.jobNumber === null)
      ) {

        polygon = ''

        if (jobs[i].sitelocation !== '' && jobs[i].sitelocation !== null) {

          polygon = await awaitSiteLocation(jobs[i].sitelocation.split(" "))
          .then(res =>
            `<Placemark>

              <styleUrl>#SiteLocation</styleUrl>
              <description>
                JN: ${jobs[i].jobnumber}<br />
                PN: ${jobs[i].proposal !== '' && jobs[i].proposal !== null ? `${jobs[i].proposal}-${jobs[i].proposalyear}` : ''}
              </description>

              <Polygon>
                  <tessellate>1</tessellate>
                  <outerBoundaryIs>
                      <LinearRing>
                          <coordinates>
                            ${res}
                          </coordinates>
                      </LinearRing>
                  </outerBoundaryIs>
              </Polygon>

            </Placemark>
            `
          )
          .catch(err => console.log(`err: ${err}`))

        }

        txt += `
        <Folder>

          <name>${jobs[i].jobnumber}</name>

          <Placemark>

            <description>
              JN: ${jobs[i].jobnumber}<br />
              PN: ${jobs[i].proposal !== '' && jobs[i].proposal !== null ? `${jobs[i].proposal}-${jobs[i].proposalyear}` : ''}<br />
              Client: <br />
              Description: ${jobs[i].projectdescription}<br />
              Address: <br />
              Job Date: ${jobs[i].jobdate}<br />
            </description>

            <styleUrl>${jobs[i].proposal !== '' && jobs[i].proposal !== null ? '#IconBoth' : 'IconJN'}</styleUrl>

            <Point>
              <coordinates>${jobs[i].longitude},${jobs[i].latitude}</coordinates>
            </Point>

          </Placemark>

          ${polygon}

        </Folder>
        `
      }

    }

    return txt

  }

  async function awaitLogs(logs) {

    // async
    const fileExists = (file) => {
      return new Promise((resolve) => {
        fs.access(file, fs.constants.F_OK, (err) => {
          err ? resolve(false) : resolve(true)
        });
      })
    }

    let file
    let txt = ''

    for (let i=0; i < logs.length; i++) {

      let result = await Promise.resolve(i)

      let radius = distance(logs[i].latitude, logs[i].longitude, filter.latitude, filter.longitude) // 8 km

      if (
        (filter.jobNumber !== '' && filter.jobNumber !== null && radius < 8) ||
        (filter.jobNumber === '' || filter.jobNumber === null)
      ) {

        if (logs[i].gw === '' || logs[i].gw === null) {

          txt += `<Placemark>
          <name>No GW to ${logs[i].depth} on ${logs[i].drillDate}</name>
             <name>${logs[i].depth}</name>
             <styleUrl>#IconNoGW</styleUrl>
             <Point>
                 <coordinates>${logs[i].longitude}, ${logs[i].latitude}</coordinates>
             </Point>
           </Placemark>`

        } else {

          txt += `<Placemark>
          <name>GW at ${logs[i].gw} on ${logs[i].drillDate}</name>
             <name>${logs[i].gw}</name>
             <styleUrl>#IconGW</styleUrl>
             <Point>
                 <coordinates>${logs[i].longitude}, ${logs[i].latitude}</coordinates>
             </Point>
           </Placemark>`

        }

        let file = `T:\Databases\Admin\Archive\Logs\${logs[i].jobNumber} ${logs[i].location}.pdf`

        txt += `<Placemark>
        <name>${logs[i].location}</name>
          <description>
            Job Number: ${logs[i].jobNumber}<br />
            Location: ${logs[i].location}<br />
            Depth: ${logs[i].depth}<br />
            GW: ${logs[i].gw === '' || logs[i].gw === null ? '<b>Not Encountered</b>' : logs[i].gw}<br />
            Drill Date: ${logs[i].drillDate}<br />
            Logged By: ${logs[i].loggedBy}<br />
            Driller: ${logs[i].driller}<br />
            Drill Method: ${logs[i].drillMethod}<br />
            Elevation: ${logs[i].elevation}<br />
            ${fileExists(file) ?
              `<a href='file:///T:/Databases/Admin/Archive/Logs/${logs[i].jobNumber}%20${logs[i].location}.pdf'>View Log</a>
              Not working? Try clicking above...<br />
              'Tools'-->'Options'-->'General'<br />
              Select 'Allow access to local files'<br />
              Select 'Show web results in external browser'<br />` :
              `<b>A PDF has not been created for viewing.</b><br />`
            }
          </description>
          ${logs[i].percWell === 1 ? `<styleUrl>#IconPercolation</styleUrl>` : `<styleUrl>#IconBorings</styleUrl>`}
          <Point>
          <coordinates>${logs[i].longitude}, ${logs[i].latitude}</coordinates>
          </Point>
        </Placemark>`

      }

    }

    txt =
    `<Folder>
      <name>Exploration</name>
      ${txt}
    </Folder>
    `
    //console.log(`logs: ${logs.length}`)
    return txt

  }

  awaitJobs(jobs)
  .then(async res => {

    let logResult = await awaitLogs(logs).catch(err => console.log(`awaitLogs err: ${err}`))

    let file = `<?xml version="1.0" encoding="UTF-8"?>
    <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
      <Document>
      <name>Albus Data</name>
        <LookAt>
          <longitude>${filter.longitude}</longitude>
          <latitude>${filter.latitude}</latitude>
          <altitude>0</altitude>
          <heading>0</heading>
          <tilt>0</tilt>
          <range>1000.00000</range>
          <gx:altitudeMode>relativeToSeaFloor</gx:altitudeMode>
        </LookAt>
        <Style id="Selected">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/paddle/ylw-stars.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconJN">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/paddle/blu-circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconPN">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/paddle/red-circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconBoth">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/paddle/grn-circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconOtherReports">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/paddle/pink-circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconBorings">
             <IconStyle>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconPercolation">
             <IconStyle>
             <color>ffffff55</color>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconNoGW">
             <LabelStyle>
               <color>ff0000cc</color>
               <scale>0.75</scale>
             </LabelStyle>
             <IconStyle>
             <scale>0</scale>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/shapes/water.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="IconGW">
             <LabelStyle>
               <color>ff00ff00</color>
               <scale>0.75</scale>
             </LabelStyle>
             <IconStyle>
             <scale>0</scale>
               <Icon>
                   <href>http://maps.google.com/mapfiles/kml/shapes/water.png</href>
               </Icon>
             </IconStyle>
        </Style>
        <Style id="SiteLocation">
            <LineStyle>
                <color>ff00ff00</color>
                <width>2</width>
            </LineStyle>
            <PolyStyle>
                <color>4000ff00</color>
            </PolyStyle>
        </Style>
        <Style id="SiteLocationOther">
            <LineStyle>
                <color>ffff55ff</color>
                <width>2</width>
            </LineStyle>
            <PolyStyle>
                <color>40ff55ff</color>
            </PolyStyle>
        </Style>
        <Folder>
          <name>Jobs</name>
          ${res}
        </Folder>
        ${logResult}
      </Document>
    </kml>
    `
    file = file.replaceAll('&', 'and')
    fs.writeFileSync('Albus Data.kml', file, 'utf-8')

  })
  .catch(e => {
    alert('Failed to save the file !');
    console.log(`e: ${JSON.stringify(e)}`)
  })


  // try {
  //   console.log('clicked')
  //   fs.writeFileSync('testing.kml', file, 'utf-8');
  // }
  // catch(e) {
  //   alert('Failed to save the file !');
  //   console.log(`e: ${JSON.stringify(e)}`)
  // }

}



//     return (
//       <Folder>
//
//           <name><![CDATA[" & adors![Proposal] & "-" & adors!ProposalYear & "]]></name>
//
//           <Placemark>
//
//              <description>
//                  Proposal: <![CDATA[" & adors![Proposal] & "-" & adors!ProposalYear & "]]><br />
//                  Client: <![CDATA[" & adors!Company & "]]><br />
//                  Description: <![CDATA[" & adors!ProjectDescription & "]]><br />
//                  Address: <![CDATA[" & adors!Address & "]]>, <![CDATA[" & adors!City & "]]>, <![CDATA[" & adors!State & "]]>, <![CDATA[" & adors!Zip & "]]><br />
//                  Proposal Date: <![CDATA[" & adors!ProposalDate & "]]><br />
//              </description>
//
//              <styleUrl>#IconPN</styleUrl>
//
//              <Point>
//                  <coordinates><![CDATA[" & adors!Longitude & "," & adors!Latitude & "]]></coordinates>
//              </Point>
//            </Placemark>
//     )
//   })
//
//   <Folder>
//
//       <name><![CDATA[" & adors![Proposal] & "-" & adors!ProposalYear & "]]></name>
//
//       <Placemark>
//
//          <description>
//              Proposal: <![CDATA[" & adors![Proposal] & "-" & adors!ProposalYear & "]]><br />
//              Client: <![CDATA[" & adors!Company & "]]><br />
//              Description: <![CDATA[" & adors!ProjectDescription & "]]><br />
//              Address: <![CDATA[" & adors!Address & "]]>, <![CDATA[" & adors!City & "]]>, <![CDATA[" & adors!State & "]]>, <![CDATA[" & adors!Zip & "]]><br />
//              Proposal Date: <![CDATA[" & adors!ProposalDate & "]]><br />
//          </description>
//
//          <styleUrl>#IconPN</styleUrl>
//
//          <Point>
//              <coordinates><![CDATA[" & adors!Longitude & "," & adors!Latitude & "]]></coordinates>
//          </Point>
//        </Placemark>
//
//
//
//       <name><![CDATA[" & adors!JobNumber & "]]></name>
//
//       <Placemark>
//
//          <description>
//              Job Number: <![CDATA[" & adors!JobNumber & "]]><br />
//
//     If Not IsNull(adors!Proposal) And Not adors!Proposal = ' Then
//
//                  Proposal: <![CDATA[" & adors!Proposal & "]]>-<![CDATA[" & adors!ProposalYear & "]]><br />
//
//     End If
//
//              Client: <![CDATA[" & adors!Company & "]]><br />
//              Description: <![CDATA[" & adors!ProjectDescription & "]]><br />
//              Address: <![CDATA[" & adors!Address & "]]>, <![CDATA[" & adors!City & "]]>, <![CDATA[" & adors!State & "]]>, <![CDATA[" & adors!Zip & "]]><br />
//              Job Date: <![CDATA[" & adors!JobDate & "]]><br />
//          </description>
//
//     'set the icon style
//     If adors!JobID = JobID Then
//
//              <styleUrl>#Selected</styleUrl>
//
//     ElseIf IsNull(adors!Proposal) Or adors!Proposal = ' Then
//
//              <styleUrl>#IconJN</styleUrl>
//
//     Else
//
//              <styleUrl>#IconBoth</styleUrl>
//
//     End If
//
//          <Point>
//              <coordinates><![CDATA[" & adors!Longitude & "," & adors!Latitude & "]]></coordinates>
//          </Point>
//        </Placemark>
//
// End If 'cbJN
//
// Else
//
// 'do nothing
//
// End If
//
//
//
//
// 'site locations
// If (IsNull(adors!JobNumber) Or adors!JobNumber = ') Or (Not IsNull(adors!JobNumber) And Not adors!JobNumber = ') Then
//
// If Forms!frmGoogleEarth.Form.cbJNsitelocations = -1 Then
//
//     'see if site location exists
//     If Not IsNull(adors!siteLocation) Or Not adors!siteLocation = ' Then
//     'Debug.Print adors!jobnumber
//         strCoords = adors!siteLocation
//
//         geCoords = '
//         lastGECoords = '
//         strCoord = '
//         lat = '
//         lng = '
//
//            <Placemark>
//                <styleUrl>#SiteLocation</styleUrl>
//
//         If IsNull(adors!Proposal) Or adors!Proposal = ' Then
//
//                    <description>JN: <![CDATA[" & adors!JobNumber & "]]></description>
//
//         Else
//
//                    <description>JN: <![CDATA[" & adors!JobNumber & "]]>, PN: <![CDATA[" & adors!Proposal & "]]></description>
//
//         End If
//
//                <Polygon>
//                    <tessellate>1</tessellate>
//                    <outerBoundaryIs>
//                        <LinearRing>
//                            <coordinates>
//
//         Do While Not strCoords = '
//         'Debug.Print strCoords
//             'get lat
//             strCoord = Left(strCoords, InStr(strCoords, ","))
//             lat = Trim(strCoord)
//             'Debug.Print lat
//
//             strCoords = Replace(strCoords, strCoord, ', , 1)
//             'Debug.Print strCoords
//
//             'get lng
//             strCoord = Left(strCoords, InStr(strCoords, " "))
//             If Len(strCoord) = 0 Then
//                 lng = Trim(strCoords)
//                 strCoords = '
//             Else
//                 lng = Trim(strCoord)
//                 strCoords = Replace(strCoords, strCoord, ', , 1)
//             End If
//             'Debug.Print lng
//             'Debug.Print strCoords
//
//             'reassemble
//             geCoords = geCoords & lng & "," & lat & "0 "
//
//             'need to close the polygon
//             If lastGECoords = ' Then
//                 lastGECoords = geCoords
//             End If
//             'Debug.Print Trim(geCoords & lastGECoords)
//         Loop
//         'Debug.Print geCoords
//
//                                " & Trim(geCoords & lastGECoords)
//                            </coordinates>
//                        </LinearRing>
//                    </outerBoundaryIs>
//                </Polygon>
//            </Placemark>
//
//     End If
//
// End If 'cbJNsitelocations
//
// End If ' PN or JN exist?
//
// </Folder>
//
// return (
//   <?xml version='1.0' encoding='UTF-8'?>
//     <kml xmlns='http://www.opengis.net/kml/2.2' xmlns:gx='http://www.google.com/kml/ext/2.2' xmlns:kml='http://www.opengis.net/kml/2.2' xmlns:atom='http://www.w3.org/2005/Atom'>
//      <Document>
//      <name>Albus-Data</name>
//       <LookAt>
//          <longitude> & lng & "</longitude>
//          <latitude> & lat & "</latitude>
//          <altitude>0</altitude>
//          <heading>0</heading>
//          <tilt>0</tilt>
//          <range>1000.00000</range>
//          <gx:altitudeMode>relativeToSeaFloor</gx:altitudeMode>
//       </LookAt>
//
//       <Style id='Selected'>
//          <IconStyle>
//            <Icon>
//               <href>http://maps.google.com/mapfiles/kml/paddle/ylw-stars.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconJN'>
//          <IconStyle>
//            <Icon>
//               <href>http://maps.google.com/mapfiles/kml/paddle/blu-circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconPN'>
//          <IconStyle>
//            <Icon>
//               <href>http://maps.google.com/mapfiles/kml/paddle/red-circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconBoth'>
//          <IconStyle>
//            <Icon>
//             <href>http://maps.google.com/mapfiles/kml/paddle/grn-circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconOtherReports'>
//          <IconStyle>
//            <Icon>
//               <href>http://maps.google.com/mapfiles/kml/paddle/pink-circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconBorings'>
//          <IconStyle>
//            <Icon>
//                <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconPercolation'>
//          <IconStyle>
//          <color>ffffff55</color>
//            <Icon>
//                <href>http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconNoGW'>
//          <LabelStyle>
//            <color>ff0000cc</color>
//   '         <colorMode>random</colorMode>
//            <scale>0.75</scale>
//          </LabelStyle>
//          <IconStyle>
//          <scale>0</scale>
//            <Icon>
//                <href>http://maps.google.com/mapfiles/kml/shapes/water.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='IconGW'>
//          <LabelStyle>
//            <color>ff00ff00</color>
//   '         <colorMode>random</colorMode>
//            <scale>0.75</scale>
//          </LabelStyle>
//          <IconStyle>
//          <scale>0</scale>
//            <Icon>
//                <href>http://maps.google.com/mapfiles/kml/shapes/water.png</href>
//            </Icon>
//          </IconStyle>
//       </Style>
//
//       <Style id='SiteLocation'>
//         <LineStyle>
//             <color>ff00ff00</color>
//             <width>2</width>
//         </LineStyle>
//         <PolyStyle>
//             <color>4000ff00</color>
//         </PolyStyle>
//       </Style>
//
//       <Folder>
//         <name>JNs</name>
//         ${jobInfo}
//       </Folder>
//
//     '=================================================================
//
//   ' Include PNs with JNs to help load faster
//
//           <Folder>
//           <name>PNs</name>
//
//         Do Until RSPN.EOF
//
//             If Not RSPN!Latitude = ' And Not RSPN!Longitude = ' And Not IsNull(RSPN!Latitude) And Not IsNull(RSPN!Longitude) Then
//
//
//
//                       <Folder>
//                       <name><![CDATA[" & RSPN!Proposal & "]]></name>
//
//                 If Forms!frmGoogleEarth.Form.cbPN = -1 Then
//
//                       <Placemark>
//
//                          <description>
//                              Proposal: <![CDATA[" & RSPN![Proposal] & "-" & RSPN!ProposalYear & "]]><br />
//                              Client: <![CDATA[" & RSPN!Company & "]]><br />
//                              Description: <![CDATA[" & RSPN!ProjectDescription & "]]><br />
//                              Address: <![CDATA[" & RSPN!Address & "]]>, <![CDATA[" & RSPN!City & "]]>, <![CDATA[" & RSPN!State & "]]>, <![CDATA[" & RSPN!Zip & "]]><br />
//                              Proposal Date: <![CDATA[" & RSPN!ProposalDate & "]]><br />
//                          </description>
//
//                     'set the icon style
//                          <styleUrl>#IconPN</styleUrl>
//
//                          <Point>
//                              <coordinates><![CDATA[" & RSPN!Longitude & "," & RSPN!Latitude & "]]></coordinates>
//                          </Point>
//                        </Placemark>
//
//                 End If 'cbPN
//
//                 If Forms!frmGoogleEarth.Form.cbPNsitelocations = -1 Then
//
//                     'see if site location exists
//                     If Not IsNull(RSPN!siteLocation) Or Not RSPN!siteLocation = ' Then
//                     'Debug.Print RSPN!jobnumber
//                         strCoords = RSPN!siteLocation
//
//                         geCoords = '
//                         lastGECoords = '
//                         strCoord = '
//                         lat = '
//                         lng = '
//
//                            <Placemark>
//                                <styleUrl>#SiteLocation</styleUrl>
//
//                                <description>PN: <![CDATA[" & RSPN!Proposal & "]]></description>
//
//                                <Polygon>
//                                    <tessellate>1</tessellate>
//                                    <outerBoundaryIs>
//                                        <LinearRing>
//                                            <coordinates>
//
//                         Do While Not strCoords = '
//                         'Debug.Print strCoords
//                             'get lat
//                             strCoord = Left(strCoords, InStr(strCoords, ","))
//                             lat = Trim(strCoord)
//                             'Debug.Print lat
//
//                             strCoords = Replace(strCoords, strCoord, ', , 1)
//                             'Debug.Print strCoords
//
//                             'get lng
//                             strCoord = Left(strCoords, InStr(strCoords, " "))
//                             If Len(strCoord) = 0 Then
//                                 lng = Trim(strCoords)
//                                 strCoords = '
//                             Else
//                                 lng = Trim(strCoord)
//                                 strCoords = Replace(strCoords, strCoord, ', , 1)
//                             End If
//                             'Debug.Print lng
//                             'Debug.Print strCoords
//
//                             'reassemble
//                             geCoords = geCoords & lng & "," & lat & "0 "
//
//                             'need to close the polygon
//                             If lastGECoords = ' Then
//                                 lastGECoords = geCoords
//                             End If
//                             'Debug.Print Trim(geCoords & lastGECoords)
//                         Loop
//                         'Debug.Print geCoords
//
//                                                " & Trim(geCoords & lastGECoords)
//                                            </coordinates>
//                                        </LinearRing>
//                                    </outerBoundaryIs>
//                                </Polygon>
//                            </Placemark>
//
//                     End If
//
//                 End If 'cbPNsitelocations
//
//                   </Folder> 'close folder for JobNo
//
//             End If 'checks that lat/lng is not '
//
//         RSPN.MoveNext
//         Loop 'end jobs in root
//
//           </Folder> 'close folder for rootJobNo
//
//
//
//     '=================================================================
//
//     'do logs in separate folder...itll be faster, one loop versus lots
//       <Folder>
//       <name>Exploration</name>
//
//     Do Until RSlogs.EOF
//
//         If Forms!frmGoogleEarth.Form.cbBoringsGW = -1 Then
//
//             'throw on GW data so its more obvious
//               <Placemark>
//
//             If IsNull(RSlogs!GW) Or RSlogs!GW = ' Then
//
//                 '     <name>No GW to <![CDATA[" & RSlogs!Depth & "]]>' on <![CDATA[" & RSlogs!DrillDate & "]]></name>
//                      <name><![CDATA[" & RSlogs!Depth & "]]></name>
//                      <styleUrl>#IconNoGW</styleUrl>
//                      <Point>
//                          <coordinates><![CDATA[" & RSlogs!Longitude & "," & RSlogs!Latitude & "]]></coordinates>
//                      </Point>
//                    </Placemark>
//
//             Else
//
//                 '     <name>GW at <![CDATA[" & RSlogs!GW & "]]>' on <![CDATA[" & RSlogs!DrillDate & "]]></name>
//                      <name><![CDATA[" & RSlogs!GW & "]]></name>
//                      <styleUrl>#IconGW</styleUrl>
//                      <Point>
//                          <coordinates><![CDATA[" & RSlogs!Longitude & "," & RSlogs!Latitude & "]]></coordinates>
//                      </Point>
//                    </Placemark>
//
//             End If
//
//         End If 'cbGW
//
//         If Forms!frmGoogleEarth.Form.cbBorings = -1 Then
//
//             'add boring location
//               <Placemark>
//                  <name><![CDATA[" & RSlogs!Location & "]]></name>
//                  <description>
//                      Job Number: <![CDATA[" & RSlogs!JobNumber & "]]><br />
//                      Location: <![CDATA[" & RSlogs!Location & "]]><br />
//                      Depth: <![CDATA[" & RSlogs!Depth & "]]><br />
//
//             If IsNull(RSlogs!GW) Or RSlogs!GW = ' Then
//
//                          GW: <b> Not Encountered</b><br />
//
//             Else
//
//                          GW: <![CDATA[" & RSlogs!GW & "]]><br />
//
//             End If
//
//                      Drill Date: <![CDATA[" & RSlogs!DrillDate & "]]><br />
//                      Logged By: <![CDATA[" & RSlogs!LoggedBy & "]]><br />
//                      Driller: <![CDATA[" & RSlogs!Driller & "]]><br />
//                      Drill Method: <![CDATA[" & RSlogs!DrillMethod & "]]><br />
//                      Elevation: <![CDATA[" & RSlogs!Elevation & "]]><br />
//
//             fileName = "T:\Databases\Admin\Archive\Logs\" & RSlogs!JobNumber & " " & RSlogs!Location & ".pdf"
//             'Debug.Print fileName
//
//             'see if a PDF was created for viewing
//             If fso.FileExists(fileName) Then
//
//                          <a href='file:///T:/Databases/Admin/Archive/Logs/" & RSlogs!JobNumber & "%20" & RSlogs!Location & ".pdf'>View Log</a>
//                          Not working? Try clicking above...<br />
//                          'Tools'-->'Options'-->'General'<br />
//                          Select 'Allow access to local files'<br />
//                          Select 'Show web results in external browser'<br />
//
//             Else
//
//                          <b>A PDF has not been created for viewing.</b><br />
//
//             End If
//
//
//                  </description>
//
//             If Not RSlogs!percWell = "True" Then
//
//                      <styleUrl>#IconBorings</styleUrl>
//
//             Else
//
//                      <styleUrl>#IconPercolation</styleUrl>
//
//             End If
//
//                  <Point>
//                      <coordinates><![CDATA[" & RSlogs!Longitude & "," & RSlogs!Latitude & "]]></coordinates>
//                  </Point>
//                </Placemark>
//
//         End If 'cbBorings
//
//     RSlogs.MoveNext
//     Loop
//
//       </Folder>
//      </Document>
//     </kml>
// )
