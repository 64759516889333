import React, { useState, useEffect } from 'react'
import Icon from 'components/utils/Icon'

import { formatDateYMD, formatDateTime } from 'scripts/common'
import { googleEarth } from 'scripts/googleEarth'

const FolderPanel = (props) => {

  const electron = window.require('electron')
  const {shell} = window.require('electron')
  const remote = electron.remote
  const { dialog } = remote

  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedLogs, setFetchedLogs] = useState([])

  const fetchData = () => {

    fetch('/api/selectJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedJobs(result.map(data => (
          {...data,
            entrytime: formatDateTime(data.entrytime, true),
            modtime: formatDateTime(data.modtime, true),
            jobdate: formatDateYMD(data.jobdate, true)
          }
        )))

      },
      (error) => {
        console.log('Error: selectJobs --> ' + error)
      }
    )

    fetch('/api/selectLogsGoogle', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedLogs(result.map(data => (
          {...data,
            entrytime: formatDateTime(data.entrytime),
            modtime: formatDateTime(data.modtime),
            drillDate: formatDateYMD(data.drillDate)
          }
        )))

      },
      (error) => {
        console.log('Error: selectLogs --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const openGoogleEarth = () => {
    googleEarth(props.filter, fetchedJobs, fetchedLogs)
    shell.openPath('Albus Data.kml');
  }

  const openLiquefaction = () => {
    shell.openPath('T:\\Databases\\Admin\\Google Earth\\Liquefaction Zones.kml')
  }

  const openGroundwater = () => {
    shell.openPath('T:\\Databases\\Admin\\Google Earth\\Ground Water.kml')
  }

  const directions = () => {
    props.filter.jobNumber !== '' && props.filter.jobNumber !== null ?
    shell.openExternal(`http://maps.google.com/?q=${props.filter.latitude}, ${props.filter.longitude}`) :
    alert('Please select a Job Number first')
  }

  const openFolder = (e) => {

    let object = e.currentTarget.getAttribute('data-id')
    // const electron = window.require('electron');
    // const { shell } = electron;

    let path = ''
    let jn = props.filter.jobNumber !== null && props.filter.jobNumber !== '' ? props.filter.jobNumber : ''
    let pn = props.filter.proposal !== null && props.filter.proposal !== '' ? props.filter.proposal : ''

    switch(object) {
      case 'jobSupport':

        //console.log(str.substring(0, 2));

        if (jn !== '') {
          path = `T:\\Job Support\\- ${jn.substring(0, 2)}00\\${jn}\\`
        } else {
          path = 'T:\\Job Support\\'
        }

        break
      case 'jobReports':

        if (jn !== '') {
          path = `T:\\Job Reports\\${jn.substring(0, 2)}00\\${jn}*.*`
        } else {
          path = 'T:\\Job Reports\\'
        }

        break
      case 'shareSpace':

        if (jn !== '') {
          path = `T:\\Share Space\\${jn.substring(0, 2)}00\\${jn}*.*`
        } else {
          path = 'T:\\Share Space\\'
        }

        break
      case 'proposals':

        if (pn !== '') {
          path = `T:\\PN\\${pn.substring(0, 2)}00\\${pn}*.*`
        } else {
          path = 'T:\\PN\\'
        }

        break
      default:
        // code block
    }

    //T:\Job Support\- 3000\3000.00

    // T:\Job Reports\3000

    // T:\Share Space\2900

    //T:\PN\2100

    console.log(`path ${path}`)

    let options = {
     defaultPath : path,
     properties: ['openFile']
    }

    dialog.showOpenDialog(options)
    .then(data => {
       //console.log(data.filePaths);
       shell.openPath(data.filePaths[0])
    })
    .catch(err => console.log('do nothing'))

  }

  // let activeDiv = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   backgroundColor: 'dodgerblue',
  //   color: 'white'
  // }

  let styleDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  let styleSpan = {
    marginRight: 10,
    whiteSpace: 'nowrap'
  }

  //<div className='hoverLink' style={styleDiv} data-id='' onClick={openFolder}><Icon name='directions' outline={true} color='gray' /><span style={styleSpan}>Directions</span></div>

  return (
    <div style={{backgroundColor: 'whitesmoke', height: '100%', overflowY: 'auto'}}>

      <div className='hoverLink' style={styleDiv} data-id='jobSupport' onClick={openFolder}><Icon name='source' color='gray' /><span style={styleSpan}>Job Support</span></div>

      <div className='hoverLink' style={styleDiv} data-id='shareSpace' onClick={openFolder}><Icon name='folder_shared' color='gray' /><span style={styleSpan}>Share Space</span></div>

      <div className='hoverLink' style={styleDiv} data-id='jobReports' onClick={openFolder}><Icon name='folder_special' color='gray' /><span style={styleSpan}>Job Reports</span></div>

      <div className='hoverLink' style={styleDiv} data-id='proposals' onClick={openFolder}><Icon name='folder' color='gray' /><span style={styleSpan}>Proposals</span></div>

      <div className='hoverLink' style={styleDiv} onClick={directions}><Icon name='map' outline={true} color='gray' /><span style={styleSpan}>Google Maps</span></div>

      <div className='hoverLink' style={styleDiv} onClick={openGoogleEarth}><Icon name='public' color='gray' /><span style={styleSpan}>Google Earth</span></div>

      <div className='hoverLink' style={styleDiv} onClick={openLiquefaction}><Icon name='public' color='gray' /><span style={styleSpan}>Liquefaction</span></div>

      <div className='hoverLink' style={styleDiv} onClick={openGroundwater}><Icon name='public' color='gray' /><span style={styleSpan}>Groundwater</span></div>

    </div>
  )
}

export default FolderPanel
