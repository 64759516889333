import React from "react";

import Home from './Home'
import Job from './Job'
import Proposal from './Proposal'
import Log from './Log'
import Aerial from './Aerial'
import Geology from './Geology'
import Library from './Library'
//import Concrete from './Concrete'
import Manage from './Manage'

//{props.component === 'Concrete' ? <Concrete user={props.user} filter={props.filter} component={props.component} /> : null}

const Container = (props) => {

  return (
    <>
      {props.component === 'Home' ? <Home user={props.user} filter={props.filter} /> : null}
      {props.component === 'Job' ? <Job user={props.user} filter={props.filter} component={props.component} selectJob={props.selectJob} /> : null}
      {props.component === 'Proposal' ? <Job user={props.user} filter={props.filter} component={props.component} selectJob={props.selectJob} /> : null}
      {props.component === 'Log' ? <Log user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Aerial' ? <Aerial user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Geology' ? <Geology user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Library' ? <Library user={props.user} filter={props.filter} component={props.component} /> : null}
      {props.component === 'Manage' ? <Manage user={props.user} filter={props.filter} component={props.component} componentManage={props.componentManage} /> : null}
    </>
  )
}
export default Container
