import React, {useState, useEffect} from 'react';
import LeftPanel from 'components/utils/LeftPanel'
import FolderPanel from 'components/utils/FolderPanel'
import Login from 'components/Login'
import NavBar from 'components/utils/NavBar'
import Container from 'components/main/Container'
import Icon from 'components/utils/Icon'
import Job from 'components/menu/Job'
import SiteLocation from 'components/utils/SiteLocation'

import './styles/app.css';

import { formatDateTime, addActivity, getDeviceType, isElectron } from 'scripts/common'
//import { showNotification, test } from 'utils/notification'

function App() {

  let officeLat = 33.84789303492371 // albus office
  let officeLng = -117.86229291534426

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const [stayLoggedIn, setStayLoggedIn] = useState(false)
  const toggleStayLoggedIn = () => setStayLoggedIn(prevState => (stayLoggedIn ? false : true))

  const [userDetails, setUserDetails] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isModal, setIsModal] = useState({
    job: false,
    folder: false,
    site: false
  })

  const [filter, setFilter] = useState({
    jobNumber: '',
    proposal: '',
    latitude: officeLat,
    longitude: officeLng,
    siteLocation: '',
    siteArea: ''
  })

  const [component, setComponent] = useState('Home')
  const [componentManage, setComponentManage] = useState('Home')

  const selectComponent = (o) => {
    //console.log(`o: ${o.getAttribute('data-name')}`);
    setComponent(o.getAttribute('data-name'))
  }

  const selectComponentManage = (e) => {
    //console.log(`derp: ${e.currentTarget.getAttribute('data-id')}`)
    setComponentManage(e.currentTarget.getAttribute('data-id'))
  }

  useEffect(() => {

    if (localStorage.getItem('albusgeo') !== null) {

      let user = JSON.parse(localStorage.getItem('albusgeo'))
      login(user.user, user.pw)

    }

  }, [])

  const login = (user, pw) => {

    let device = getDeviceType()

    fetch('/api/login', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user: user, pw: pw})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(JSON.stringify(result))
        if (result.length === 0) {
          //alert('Incorrect login credentials.')
          setIsError(true)
        } else {

          if (stayLoggedIn) {

            let info = {
              user: user,
              pw: pw
            }

            localStorage.setItem('albusgeo', JSON.stringify(info))

          }

          setUserDetails({...result[0],
            device: device
          })

          setIsLoggedIn(true)
          setIsError(false)

          addActivity('jobs', '', '', '', 'login', 'jobs app', user)

          fetch('/api/updateLastLogin', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: result[0].id,
              time: formatDateTime(new Date())
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + result)
            },
            (error) => {
              console.log('Error: updateLastLogin --> ' + error)
            }
          )

        }

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  const selectJob = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let lat, lng, site, area

    if (target.nodeName === 'TD') {

    //let tr = e.target.parentNode
    //let td = tr.getElementsByTagName('td')
    //let lat, lng

      if (td[0].textContent === '' || td[1].textContent === '') {
        lat = officeLat
        lng = officeLng
        site = ''
        area = ''
      } else {
        lat = td[0].textContent
        lng = td[1].textContent
        site = td[2].textContent
        area = td[3].textContent
      }

      setFilter(prevState =>
        ({...prevState,
          jobNumber: td[4].textContent,
          proposal: td[5].textContent,
          latitude: lat,
          longitude: lng,
          siteLocation: site,
          siteArea: area
        })
      )
      closeModal()

    }

  }

  const clearJob = () => {

    //let lat = 33.84789303492371 // albus office
    //let lng = -117.86229291534426

    setFilter(prevState =>
      ({...prevState,
        jobNumber: '',
        proposal: '',
        lat: officeLat,
        lng: officeLng
      })
    )
    closeModal()

  }

  const closeModal = () => setIsModal(prevState => ({...prevState, job: false, user: false, date: false}))
  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))
  const toggleFolder = () => setIsModal(prevState => ({...prevState, folder: isModal.folder ? false : true}))
  const openSite = () => setIsModal(prevState => ({...prevState, site: true}))
  const closeSite = () => setIsModal(prevState => ({...prevState, site: false}))

  const logOut = () => {

    localStorage.removeItem('albusgeo');
    setIsLoggedIn(false)
    setUserDetails({offline: null})

  }

  const directions = () => window.open(`https://www.google.com/maps/search/?api=1&query=${filter.latitude},${filter.longitude}`)

  return (
    <div style={{height: '100vh', width: '100vw'}}>
      {isLoggedIn ?

        <div style={{display: 'flex', flexFlow: 'column', height: '100%', width: '100%'}}>

          {isModal.job ? <Job selectJob={selectJob} clearJob={clearJob} closeModal={closeModal} /> : null}
          {isModal.site ? <SiteLocation close={closeSite} latlng={filter} /> : null}

          <NavBar component={component} user={userDetails} onClick={selectComponent} logOut={logOut} />

          <div style={{flex: '1', overflow: 'auto'}}>

            <div style={{display: 'flex', height: '100%', maxWidth: '100%', overflow: 'auto'}}>

              {component === 'Manage' ?

                <div style={{flex: '0 1 auto', height: '100%'}}>
                  <LeftPanel selectComponent={selectComponentManage} componentManage={componentManage} user={userDetails} />
                </div> : null

              }

              {isModal.folder ?

                <div style={{flex: '0 1 auto', height: '100%'}}>
                  <FolderPanel filter={filter} />
                </div> : null

              }

              <div style={{flex: '1 1 auto', overflow: 'auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    {isElectron() ? <Icon name='menu' outline={isModal.folder ? false : true} onClick={toggleFolder} /> : null}
                    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openJob}>
                      <Icon name='search' />
                      {
                        filter.jobNumber !== '' ?
                        <span style={{verticalAlign: 'base-line'}}>{filter.jobNumber}</span> :
                        filter.proposal !== '' ?
                        <span style={{verticalAlign: 'base-line'}}>{filter.proposal}</span> :
                        'Select a Job'
                      }
                    </div>
                    {filter.siteLocation !== '' && filter.siteLocation !== null ? <Icon name='share_location' title='Site Location' onClick={openSite} /> : null}
                    {filter.latitude !== '' && filter.latitude !== null && filter.longitude !== '' && filter.longitude !== null ? <Icon name='directions' title='Directions' color='dodgerblue' onClick={directions} /> : null}
                  </div>

                  <div style={{flex: '1', overflow: 'auto', width: '100%'}}>

                    <Container component={component} componentManage={componentManage} user={userDetails} filter={filter} selectJob={selectJob} />

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div> : <Login isError={isError} stayLoggedIn={stayLoggedIn} toggleStayLoggedIn={toggleStayLoggedIn} login={login} />
      }
    </div>
  )
}

export default App;
