import React from 'react'
import Icon from 'components/utils/Icon'

import { formatDateTime, missingLocation, viewLocation } from 'scripts/common'

const ViewLocation = (props) => {

  let data = props.data

  return (
    <div style={{borderBottom: '1px solid gray'}}>
      {data.entryby !== null && data.entryby !== '' ?
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {data.entrylat !== null && data.entrylat !== '' ?
            <Icon name='location_on' lat={data.entrylat} lng={data.entrylng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Entry by: {data.entryby} @ {formatDateTime(data.entrytime)} ({data.entrydevice !== null && data.entrydevice !== '' ? data.entrydevice : 'device?'})</small>
        </div> :
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <small>Not entered</small>
        </div>
      }

      {data.modby !== null && data.modby !== '' ?
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          {data.modlat !== null && data.modlat !== '' ?
            <Icon name='location_on' lat={data.modlat} lng={data.modlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Mod by: {data.modby} @ {formatDateTime(data.modtime)} ({data.moddevice !== null && data.moddevice !== '' ? data.moddevice : 'device?'})</small>
        </div> :
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <small>Not modified</small>
        </div>
      }
    </div>
  )
}

export default ViewLocation
