import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import CircleButton from 'components/utils/CircleButton'
import Description from 'components/menu/Description'
import Color from 'components/menu/Color'
import Locate from 'components/menu/LocateLog'
import JobMenu from 'components/menu/Job'

import { formatDateYMD, formatDateTime, getLocation, getDeviceType, exportToExcel, filterData, replaceStr, addActivity } from 'scripts/common'

import { testPDF, createPDF } from 'scripts/pdf'

const Library = (props) => {

  // //const fs = require("fs") // used to determine if log PDF exists before opening, else create then open

  // //const [selectedLog, setSelectedLog] = useState('')
  // //const [selectedSection, setSelectedSection] = useState('description') // Description or Sample

  // const googleRef = useRef(null)
  // const mapRef = useRef(null)
  // const markerRef = useRef([])
  // const previewRef = useRef('')

  // const [fetchedData, setFetchedData] = useState([])  

  // const [isModal, setIsModal] = useState({
  //   add: false,
  //   edit: false,    
  //   locate: false,
  //   job: false,
  //   logDescription: false,
  //   addDescription: false,
  //   editDescription: false,
  //   addSample: false,
  //   editSample: false,
  //   description: false,
  //   color: false,
  //   preview: false
  // })

  // const [isLoading, setIsLoading] = useState(true)

  // const isChanged = useRef(false)
  // const isChangedDescription = useRef(false)
  // const isChangedSamples = useRef(false)

  // const [searchValue, setSearchValue] = useState('')

  // const [isValidated, setIsValidated] = useState({
  //   entryby: '',
  //   entrytime: '',
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: '',
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   jobNumber: '',
  //   location: '',
  //   depth: null,
  //   gw: null,
  //   drillDate: '',
  //   elevation: '',
  //   driller: '',
  //   drillMethod: '',
  //   drivingWeight: '',
  //   percWell: null,
  //   feetPerPage: 25,
  //   latitude: '',
  //   longitude: '',
  //   latitude2: '', // if filter !== jn, then jobMenu, but no latLng from filter or validated. so temp latLng if JobMenu
  //   longitude2: ''
  // })

  // const clearIsValidated = () => setIsValidated({
  //   entryby: '',
  //   entrytime: '',
  //   entrylat: '',
  //   entrylng: '',
  //   entrydevice: '',
  //   modby: '',
  //   modtime: '',
  //   modlat: '',
  //   modlng: '',
  //   moddevice: '',
  //   id: null,
  //   jobNumber: '',
  //   location: '',
  //   depth: null,
  //   gw: null,
  //   drillDate: '',
  //   elevation: '',
  //   driller: '',
  //   drillMethod: '',
  //   drivingWeight: '',
  //   percWell: null,
  //   feetPerPage: 25,
  //   latitude: '',
  //   longitude: '',
  //   latitude2: '',
  //   longitude2: ''
  // })

  // const [isValidatedDescriptions, setIsValidatedDescriptions] = useState({
  //   logId: null,
  //   jobNumber: '',
  //   location: '',
  //   initialDepth: '',
  //   finalDepth: '',
  //   layer: '',
  //   description: '',
  //   uscs: '',
  //   color: '',
  //   moisture: '',
  //   density: '',
  //   grainSize: '',
  //   additionalDescription: '',
  //   lineStyle: 'Dash'
  // })

  // const clearIsValidatedDescriptions = () => setIsValidatedDescriptions({
  //   logId: null,
  //   jobNumber: '',
  //   location: '',
  //   initialDepth: '',
  //   finalDepth: '',
  //   layer: '',
  //   description: '',
  //   uscs: '',
  //   color: '',
  //   moisture: '',
  //   density: '',
  //   grainSize: '',
  //   additionalDescription: '',
  //   lineStyle: 'Dash'
  // })

  // const [isValidatedSamples, setIsValidatedSamples] = useState({
  //   logId: null,
  //   jobNumber: '',
  //   location: '',
  //   depth: '',
  //   type: '',
  //   sampleLength: '',
  //   recovered: 1,
  //   blowCount1: '',
  //   blowCount2: '',
  //   blowCount3: '',
  //   blowCount4: ''
  // })

  // const clearIsValidatedSamples = () => setIsValidatedSamples({
  //   logId: null,
  //   jobNumber: '',
  //   location: '',
  //   depth: '',
  //   type: '',
  //   sampleLength: '',
  //   recovered: 1,
  //   blowCount1: '',
  //   blowCount2: '',
  //   blowCount3: '',
  //   blowCount4: ''
  // })

  // const changedData = () => isChanged.current = true
  // const changedDescription = () => isChangedDescription.current = true
  // const changedSample = () => isChangedSamples.current = true

  // const fetchData = () => {

  //   fetch('/api/selectLogs', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       jobNumber: props.filter.jobNumber
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (result) => {
  //       //console.log('result: ' + result)

  //       setFetchedData(result.map(data => (
  //         {...data,
  //           entrytime: formatDateTime(data.entrytime),
  //           modtime: formatDateTime(data.modtime),
  //           drillDate: formatDateYMD(data.drillDate)
  //         }
  //       )))

  //       addActivity('jobs', props.filter.jobNumber, '', props.component, 'view', '', props.user.username)

  //       setIsLoading(false)

  //     },
  //     (error) => {
  //       console.log('Error: selectLogs --> ' + error)
  //     }
  //   )

  // }

  // const fetchLogData = () => {

  //   fetchLogDescriptions()
  //   fetchLogSamples()
  //   fetchLogLab()

  // }

  // const fetchLogDescriptions = () => {

  //   fetch('/api/selectLogDescriptions', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       logId: selectedLog.id
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (result) => {
  //       //console.log('result: ' + result)

  //       setFetchedDescriptions(result.map(data => (
  //         {...data,
  //           entrytime: formatDateTime(data.entrytime),
  //           modtime: formatDateTime(data.modtime)
  //         }
  //       )))

  //     },
  //     (error) => {
  //       console.log('Error: selectLogDescriptions --> ' + error)
  //     }
  //   )

  // }

  // const fetchLogSamples = () => {

  //   fetch('/api/selectLogSamples', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       logId: selectedLog.id
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (result) => {
  //       //console.log(`samples: ${JSON.stringify(result)}`)

  //       setFetchedSamples(result.map(data => (
  //         {...data,
  //           entrytime: formatDateTime(data.entrytime),
  //           modtime: formatDateTime(data.modtime)
  //         }
  //       )))

  //     },
  //     (error) => {
  //       console.log('Error: selectLogSamples --> ' + error)
  //     }
  //   )

  // }

  // const fetchLogLab = () => {

  //   fetch('/api/selectLogLab', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       jobNumber: selectedLog.jobNumber,
  //       location: selectedLog.location
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (result) => {
  //       //console.log(`samples: ${JSON.stringify(result)}`)

  //       setFetchedLab(result.map(data => (
  //         {...data,
  //           entrytime: formatDateTime(data.entrytime),
  //           modtime: formatDateTime(data.modtime)
  //         }
  //       )))

  //     },
  //     (error) => {
  //       console.log('Error: selectLogLab --> ' + error)
  //     }
  //   )

  // }

  // useEffect(() => {
  //   if (isModal.google) initMap()
  // }, [props.filter, isModal.google, fetchedData])

  // useEffect(() => {
  //   if (isModal.google) updateMap()
  // }, [searchValue, isModal.google, fetchedData])

  // useEffect(() => {
  //   setSelectedLog('')
  //   fetchData()
  // }, [props.filter])

  // useEffect(() => {
  //   if (selectedLog !== '') fetchLogData()
  // }, [selectedLog])

  // // Sets the map on all markers in the array.
  // const hightlightMarker = (e) => {

  //   let id = parseInt(e.target.parentNode.getAttribute('data-id'))

  //   //console.log(`perc2: ${JSON.stringify(markerRef.current)}`)

  //   for (let i = 0; i < markerRef.current.length; i++) {

  //     if (markerRef.current[i].get("id") === id) {

  //       let icon = {
  //           url: `http://maps.google.com/mapfiles/ms/icons/yellow.png`,
  //           scaledSize: new window.google.maps.Size(40, 40), // scaled size
  //           labelOrigin: new window.google.maps.Point(19, 12)
  //       }

  //       markerRef.current[i].setIcon(icon)
  //       //markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
  //     } else {

  //       let icon = {
  //           url: `http://maps.google.com/mapfiles/ms/icons/${markerRef.current[i].get("perc")}.png`,
  //           scaledSize: new window.google.maps.Size(40, 40), // scaled size
  //           labelOrigin: new window.google.maps.Point(19, 12)
  //       }

  //       markerRef.current[i].setIcon(icon)
  //       //markerRef.current[i].setAnimation(null)
  //       markerRef.current[i].getVisible(false)
  //     }

  //     markerRef.current[i].setMap(mapRef.current);
  //   }
  // }

  // const updateMap = (id, visible) => {

  //   for (let i = 0; i < markerRef.current.length; i++) {
  //     if (markerRef.current[i].get("id") === id) {

  //       markerRef.current[i].setVisible(visible)

  //     }

  //     markerRef.current[i].setMap(mapRef.current);
  //   }
  // }

  // const initMap = () => {

  //   let data = fetchedData.filter(data => {

  //     if (data.latitude !== '' && data.latitude !== null && data.longitude !== '' && data.longitude !== null) {

  //       let jobNumber = data.jobnumber === null ? '' : data.jobnumber
  //       let location = data.location === null ? '' : data.location
  //       let depth = data.depth === null ? '' : data.depth
  //       let gw = data.gw === null ? '' : data.gw
  //       let drillDate = data.drillDate === null ? '' : data.drillDate
  //       //let loggedBy = data.projectdescription === null ? '' : data.projectdescription
  //       let driller = data.driller === null ? '' : data.driller
  //       let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
  //       let entryTime = data.entrytime === null ? '' : data.entrytime

  //       return true

  //       //{testNo: testNo, lat: data.latitude, lng: data.longitude}

  //     }

  //     return false


  //   })

  //   let google = googleRef.current

  //   mapRef.current = new window.google.maps.Map(google, {
  //        mapTypeId: window.google.maps.MapTypeId.SATELLITE,
  //        tilt: 0
  //   });

  //   let infoWindow = new window.google.maps.InfoWindow;

  //   let marker, i, latLng, jn, location, depth, gw

  //   //let i, latLng

  //   let bounds = new window.google.maps.LatLngBounds();

  //   //console.log(`locations: ${JSON.stringify(data)}`)

  //   // clear array in case of data carried over, was happening with edit

  //   markerRef.current = []

  //   for (i = 0; i < data.length; i++) {

  //     jn = data[i].jobNumber === null ? '' : data[i].jobNumber
  //     location = data[i].location === null ? '' : data[i].location
  //     depth = data[i].depth === null ? '' : data[i].depth
  //     gw = data[i].gw === null ? '' : data[i].gw

  //     let icon = {
  //         url: `http://maps.google.com/mapfiles/ms/icons/${data[i].percWell === 1 ? 'blue' : 'red'}.png`,
  //         scaledSize: new window.google.maps.Size(40, 40), // scaled size
  //         labelOrigin: new window.google.maps.Point(19, 12)
  //     }

  //     marker = new window.google.maps.Marker({
  //       position: new window.google.maps.LatLng(data[i].latitude, data[i].longitude),
  //       title:
  //       `
  //       ${jn}
  //       ${location} @ ${depth}ft
  //       GW @ ${gw}ft
  //       `,
  //       //animation: window.google.maps.Animation.DROP,
  //       map: mapRef.current,
  //       // icon: {
  //       //   url: 'http://maps.google.com/mapfiles/ms/icons/blue.png'
  //       // },
  //       icon: icon,
  //       label: { color: 'black', fontWeight: 'bold', fontSize: '10px', text: `${location}` },
  //       //label: 'B-11',
  //       id: data[i].id,
  //       visible: filterData(data[i], searchValue),
  //       perc: data[i].percWell === 1 ? 'blue' : 'red'
  //     });

  //     marker.setMap(mapRef.current)

  //     markerRef.current.push(marker)

  //     latLng = new window.google.maps.LatLng(data[i].latitude, data[i].longitude);

  //     bounds.extend(latLng);

  //     window.google.maps.event.addListener(marker, 'click', function () {
  //       infoWindow.setContent('<h3>' + this.title + '</h3>');
  //       infoWindow.open(mapRef.current, this);
  //     });

  //   }

  //   mapRef.current.fitBounds(bounds);

  //   mapRef.current.setCenter(bounds.getCenter());

  // }

  // const validate = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value

  //   setIsValidated(prevState => ({...prevState, [name]: state ? value : name === 'gw' ? null : ''}))
  // }

  // const validateDescription = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value

  //   setIsValidatedDescriptions(prevState => ({...prevState, [name]: state ? value : ''}))
  // }

  // const validateSample = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value

  //   setIsValidatedSamples(prevState => ({...prevState, [name]: state ? value : ''}))
  // }

  // const selectRow = (e) => {

  //   let target = e.target
  //   //console.log(`target: ${target.nodeName}`)
  //   let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let i = td[0].textContent

  //   if (i === '' || i === null) {
  //     alert('Error: data index not found. Contact an admin.')
  //   } else {

  //     // if (target.nodeName === 'I') {
  //     //
  //     //   directions(i)
  //     //
  //     // } else

  //     if (target.nodeName === 'TD') {

  //       setIsValidated(prevState => ({...prevState,
  //         entryby: fetchedData[i].entryby,
  //         entrytime: fetchedData[i].entrytime,
  //         entrylat: fetchedData[i].entrylat,
  //         entrylng: fetchedData[i].entrylng,
  //         entrydevice: fetchedData[i].entrydevice,
  //         modby: fetchedData[i].modby,
  //         modtime: fetchedData[i].modtime,
  //         modlat: fetchedData[i].modlat,
  //         modlng: fetchedData[i].modlng,
  //         moddevice: fetchedData[i].moddevice,
  //         id: fetchedData[i].id,
  //         jobNumber: fetchedData[i].jobNumber,
  //         location: fetchedData[i].location,
  //         depth: fetchedData[i].depth,
  //         gw: fetchedData[i].gw,
  //         drillDate: fetchedData[i].drillDate,
  //         elevation: fetchedData[i].elevation,
  //         driller: fetchedData[i].driller,
  //         drillMethod: fetchedData[i].drillMethod,
  //         drivingWeight: fetchedData[i].drivingWeight,
  //         percWell: fetchedData[i].percWell,
  //         latitude: fetchedData[i].latitude,
  //         longitude: fetchedData[i].longitude
  //       }))
  //       openEdit()

  //     }

  //   }

  // }

  // const selectRowDescriptions = (e) => {

  //   let target = e.target
  //   //console.log(`target: ${target.nodeName}`)
  //   let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let i = td[0].textContent

  //   if (i === '' || i === null) {
  //     alert('Error: data index not found. Contact an admin.')
  //   } else {

  //     if (target.nodeName === 'TD') {

  //       setIsValidatedDescriptions(fetchedDescriptions[i])
  //       openEditDescriptions()

  //     }

  //   }

  // }

  // const selectRowSamples = (e) => {

  //   let target = e.target
  //   //console.log(`target: ${target.nodeName}`)
  //   let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let i = td[0].textContent

  //   if (i === '' || i === null) {
  //     alert('Error: data index not found. Contact an admin.')
  //   } else {

  //     if (target.nodeName === 'TD') {

  //       setIsValidatedSamples(fetchedSamples[i])
  //       openEditSamples()

  //     }

  //   }

  // }

  // const toggleSection = () => setSelectedSection(selectedSection === 'description' ? 'sample' : 'description')

  // const selectLocate = (latlng, elevation) => {

  //   setIsValidated(prevState => ({...prevState, latitude: latlng.lat, longitude: latlng.lng, elevation: elevation}))
  //   isChanged.current = true
  //   closeLocate()

  // }

  // const selectJob = (e) => {

  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')

  //   setIsValidated(prevState => ({...prevState,
  //     jobNumber: td[2].textContent, latitude2: td[0].textContent, longitude2: td[1].textContent
  //   }))

  //   closeJob()

  // }

  // const clearJob = () => {
  //   // NEEDED?
  // }

  // const addData = () => {

  //   if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
  //     alert("Please select a JN.");
  //   } else if (isValidated.drillDate === null || isValidated.drillDate === '') {
  //     alert("Please select a Drill Date.");
  //   } else if (isValidated.locaiton === null || isValidated.locaiton === '') {
  //     alert("Please select a Locaiton.");
  //   // } else if (isValidated.depth === null || isValidated.depth ==='') {
  //   //     alert("Please select a Depth.");
  //   } else if (isValidated.latitude === null || isValidated.latitude === '') {
  //     alert("Please select a Latitude.");
  //   } else if (isValidated.longitude === null || isValidated.longitude === '') {
  //     alert("Please select a Longitude.");
  //   } else if (isValidated.driller === null || isValidated.driller === '') {
  //     alert("Please select a Contractor.");
  //   } else if (isValidated.drillMethod === null || isValidated.drillMethod === '') {
  //     alert("Please select a Method.");
  //   } else if (isValidated.drivingWeight === null || isValidated.drivingWeight === '') {
  //     alert("Please select a Weight.");
  //   } else if (isValidated.percWell === null || isValidated.percWell === '') {
  //     alert("Am I a perc?");
  //   } else if (isValidated.feetPerPage === null || isValidated.feetPerPage === '') {
  //     alert("Please specify feet per page for log");
  //   } else if (isValidated.feetPerPage < 1) {
  //     alert("Please specify feet per page >= 1");
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/addLog', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           jobNumber: isValidated.jobNumber,
  //           drillDate: isValidated.drillDate,
  //           location: isValidated.location,
  //           depth: isValidated.depth,
  //           gw: isValidated.gw,
  //           latitude: isValidated.latitude,
  //           longitude: isValidated.longitude,
  //           elevation: isValidated.elevation,
  //           driller: replaceStr(isValidated.driller),
  //           drillMethod: replaceStr(isValidated.drillMethod),
  //           drivingWeight: replaceStr(isValidated.drivingWeight),
  //           percWell: isValidated.percWell,
  //           feetPerPage: isValidated.feetPerPage
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'add', '', props.user.username)

  //           fetchData() // i need the id if edited
  //           isChanged.current = false
  //           closeModal()
  //           //alert('Added.')

  //         },
  //         (error) => {

  //           alert('Error: could not add log. Contact and admin.')
  //           console.log('Error: addLog --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const editData = () => {

  //   if (isValidated.jobNumber === null || isValidated.jobNumber === '') {
  //     alert("Please select a JN.");
  //   } else if (isValidated.drillDate === null || isValidated.drillDate === '') {
  //     alert("Please select a Drill Date.");
  //   } else if (isValidated.locaiton === null || isValidated.locaiton === '') {
  //     alert("Please select a Locaiton.");
  //   // } else if (isValidated.depth === null || isValidated.depth ==='') {
  //   //     alert("Please select a Depth.");
  //   } else if (isValidated.latitude === null || isValidated.latitude === '') {
  //     alert("Please select a Latitude.");
  //   } else if (isValidated.longitude === null || isValidated.longitude === '') {
  //     alert("Please select a Longitude.");
  //   } else if (isValidated.driller === null || isValidated.driller === '') {
  //     alert("Please select a Contractor.");
  //   } else if (isValidated.drillMethod === null || isValidated.drillMethod === '') {
  //     alert("Please select a Method.");
  //   } else if (isValidated.drivingWeight === null || isValidated.drivingWeight === '') {
  //     alert("Please select a Weight.");
  //   } else if (isValidated.percWell === null || isValidated.percWell === '') {
  //     alert("Am I a perc?");
  //   } else if (isValidated.feetPerPage === null || isValidated.feetPerPage === '') {
  //     alert("Please specify feet per page for log")
  //   } else if (isValidated.feetPerPage < 1) {
  //     alert("Please specify feet per page >= 1");
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/editLog', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           id: isValidated.id,
  //           jobNumber: isValidated.jobNumber,
  //           drillDate: isValidated.drillDate,
  //           location: isValidated.location,
  //           depth: isValidated.depth,
  //           gw: isValidated.gw,
  //           latitude: isValidated.latitude,
  //           longitude: isValidated.longitude,
  //           elevation: isValidated.elevation,
  //           driller: replaceStr(isValidated.driller),
  //           drillMethod: replaceStr(isValidated.drillMethod),
  //           drivingWeight: replaceStr(isValidated.drivingWeight),
  //           percWell: isValidated.percWell,
  //           feetPerPage: isValidated.feetPerPage
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           setFetchedData(fetchedData.map(data =>
  //             data.id === isValidated.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: getDeviceType(),
  //               jobNumber: isValidated.jobNumber,
  //               drillDate: isValidated.drillDate,
  //               location: isValidated.location,
  //               depth: isValidated.depth,
  //               gw: isValidated.gw,
  //               latitude: isValidated.latitude,
  //               longitude: isValidated.longitude,
  //               elevation: isValidated.elevation,
  //               driller: replaceStr(isValidated.driller),
  //               drillMethod: replaceStr(isValidated.drillMethod),
  //               drivingWeight: replaceStr(isValidated.drivingWeight),
  //               percWell: isValidated.percWell,
  //               feetPerPage: isValidated.feetPerPage
  //             } :
  //             data
  //           ))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'edit', '', props.user.username)

  //           isChanged.current = false
  //           closeModal()
  //           //alert('Edited.')

  //         },
  //         (error) => {

  //           alert('Error: could not edit log. Contact and admin.')
  //           console.log('Error: editLog --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const deleteData = () => {

  //   if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

  //     fetch('/api/deleteLog', {
  //       method: 'post',
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         id: isValidated.id
  //       })
  //     })
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log('result: ' + JSON.stringify(result))

  //         addActivity('jobs', props.filter.jobNumber, '', props.component, 'delete', '', props.user.username)

  //         setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
  //         isChanged.current = false
  //         closeModal()

  //       },
  //       (error) => {

  //         alert('Error: could not delete log. Contact and admin.')
  //         console.log('Error: deleteLog --> ' + error)
  //       }
  //     )

  //   }

  // }

  // const addDescription = () => {

  //   if (isValidatedDescriptions.initialDepth === null || isValidatedDescriptions.initialDepth === '') {
  //     alert("Please provide an Initial Depth.")
  //   } else if (isValidatedDescriptions.finalDepth !== '' && isValidatedDescriptions.lineStyle === '') {
  //     alert("Please select a line style")
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/addLogDescription', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           logId: selectedLog.id,
  //           jobNumber: isValidatedDescriptions.jobNumber,
  //           location: isValidatedDescriptions.location,
  //           initialDepth: isValidatedDescriptions.initialDepth,
  //           finalDepth: isValidatedDescriptions.finalDepth,
  //           layer: isValidatedDescriptions.layer,
  //           description: isValidatedDescriptions.description,
  //           uscs: isValidatedDescriptions.uscs.toUpperCase(),
  //           color: isValidatedDescriptions.color,
  //           moisture: isValidatedDescriptions.moisture,
  //           density: isValidatedDescriptions.density,
  //           grainSize: isValidatedDescriptions.grainSize,
  //           additionalDescription: isValidatedDescriptions.additionalDescription,
  //           lineStyle: isValidatedDescriptions.lineStyle
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'add description', '', props.user.username)

  //           fetchLogDescriptions()
  //           isChangedDescription.current = false
  //           closeModalDescriptions()

  //         },
  //         (error) => {

  //           alert('Error: could not add log description. Contact and admin.')
  //           console.log('Error: addLogDescription --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const editDescription = () => {

  //   if (isValidatedDescriptions.initialDepth === null || isValidatedDescriptions.initialDepth ==='') {
  //     alert("Please provide an Initial Depth.")
  //   } else if (isValidatedDescriptions.finalDepth !== '' && isValidatedDescriptions.lineStyle === '') {
  //     alert("Please select a line style")
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/editLogDescription', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           id: isValidatedDescriptions.id,
  //           initialDepth: isValidatedDescriptions.initialDepth,
  //           finalDepth: isValidatedDescriptions.finalDepth,
  //           layer: isValidatedDescriptions.layer,
  //           description: isValidatedDescriptions.description,
  //           uscs: isValidatedDescriptions.uscs.toUpperCase(),
  //           color: isValidatedDescriptions.color,
  //           moisture: isValidatedDescriptions.moisture,
  //           density: isValidatedDescriptions.density,
  //           grainSize: isValidatedDescriptions.grainSize,
  //           additionalDescription: isValidatedDescriptions.additionalDescription,
  //           lineStyle: isValidatedDescriptions.lineStyle,
  //           uscs: isValidatedDescriptions.uscs
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'edit description', '', props.user.username)

  //           setFetchedDescriptions(fetchedDescriptions.map(data =>
  //             data.id === isValidatedDescriptions.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: getDeviceType(),
  //               initialDepth: isValidatedDescriptions.initialDepth,
  //               finalDepth: isValidatedDescriptions.finalDepth,
  //               layer: isValidatedDescriptions.layer,
  //               description: isValidatedDescriptions.description,
  //               uscs: isValidatedDescriptions.uscs.toUpperCase(),
  //               color: isValidatedDescriptions.color,
  //               moisture: isValidatedDescriptions.moisture,
  //               density: isValidatedDescriptions.density,
  //               grainSize: isValidatedDescriptions.grainSize,
  //               additionalDescription: isValidatedDescriptions.additionalDescription,
  //               lineStyle: isValidatedDescriptions.lineStyle
  //             } :
  //             data
  //           ))

  //           isChangedDescription.current = false
  //           closeModalDescriptions()

  //         },
  //         (error) => {

  //           alert('Error: could not edit log description. Contact and admin.')
  //           console.log('Error: editLogDescription --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const deleteDescription = () => {

  //   if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

  //     fetch('/api/deleteLogDescription', {
  //       method: 'post',
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         id: isValidatedDescriptions.id
  //       })
  //     })
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log('result: ' + JSON.stringify(result))

  //         addActivity('jobs', props.filter.jobNumber, '', props.component, 'delete description', '', props.user.username)

  //         setFetchedDescriptions(fetchedDescriptions.filter(data => data.id !== isValidatedDescriptions.id))
  //         isChangedDescription.current = false
  //         closeModalDescriptions()

  //       },
  //       (error) => {

  //         alert('Error: could not delete log description. Contact and admin.')
  //         console.log('Error: deleteLogDescription --> ' + error)
  //       }
  //     )

  //   }

  // }

  // const addSample = () => {

  //   if (isValidatedSamples.depth === null || isValidatedSamples.depth === '') {
  //     alert("Please provide a depth.")
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/addLogSample', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           logId: selectedLog.id,
  //           jobNumber: isValidatedSamples.jobNumber,
  //           location: isValidatedSamples.location,
  //           depth: isValidatedSamples.depth,
  //           type: isValidatedSamples.type,
  //           sampleLength: isValidatedSamples.sampleLength,
  //           recovered: isValidatedSamples.recovered,
  //           blowCount1: isValidatedSamples.blowCount1,
  //           blowCount2: isValidatedSamples.blowCount2,
  //           blowCount3: isValidatedSamples.blowCount3,
  //           blowCount4: isValidatedSamples.blowCount4
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'add sample', '', props.user.username)

  //           fetchLogSamples()
  //           isChangedSamples.current = false
  //           closeModalSamples()

  //         },
  //         (error) => {

  //           alert('Error: could not add log sample. Contact and admin.')
  //           console.log('Error: addLogSample --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const editSample = () => {

  //   if (isValidatedSamples.depth === null || isValidatedSamples.depth === '') {
  //     alert("Please provide a depth.")
  //   } else {

  //     getLocation(function(latlng){

  //       fetch('/api/editLogSample', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: getDeviceType(),
  //           id: isValidatedSamples.id,
  //           depth: isValidatedSamples.depth,
  //           type: isValidatedSamples.type,
  //           sampleLength: isValidatedSamples.sampleLength,
  //           recovered: isValidatedSamples.recovered,
  //           blowCount1: isValidatedSamples.blowCount1,
  //           blowCount2: isValidatedSamples.blowCount2,
  //           blowCount3: isValidatedSamples.blowCount3,
  //           blowCount4: isValidatedSamples.blowCount4
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))

  //           addActivity('jobs', props.filter.jobNumber, '', props.component, 'edit sample', '', props.user.username)

  //           setFetchedSamples(fetchedSamples.map(data =>
  //             data.id === isValidatedSamples.id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: getDeviceType(),
  //               depth: isValidatedSamples.depth,
  //               type: isValidatedSamples.type,
  //               sampleLength: isValidatedSamples.sampleLength,
  //               recovered: isValidatedSamples.recovered,
  //               blowCount1: isValidatedSamples.blowCount1,
  //               blowCount2: isValidatedSamples.blowCount2,
  //               blowCount3: isValidatedSamples.blowCount3,
  //               blowCount4: isValidatedSamples.blowCount4
  //             } :
  //             data
  //           ))

  //           isChangedSamples.current = false
  //           closeModalSamples()

  //         },
  //         (error) => {

  //           alert('Error: could not edit log sample. Contact and admin.')
  //           console.log('Error: editLogSample --> ' + error)
  //         }
  //       )

  //     })

  //   }

  // }

  // const deleteSample = () => {

  //   if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

  //     fetch('/api/deleteLogSample', {
  //       method: 'post',
  //       headers: {
  //         'Accept': 'application/json, text/plain, */*',
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //         id: isValidatedSamples.id
  //       })
  //     })
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log('result: ' + JSON.stringify(result))

  //         addActivity('jobs', props.filter.jobNumber, '', props.component, 'delete sample', '', props.user.username)

  //         setFetchedSamples(fetchedSamples.filter(data => data.id !== isValidatedSamples.id))
  //         isChangedSamples.current = false
  //         closeModalSamples()

  //       },
  //       (error) => {

  //         alert('Error: could not delete log sample. Contact and admin.')
  //         console.log('Error: deleteLogSample --> ' + error)
  //       }
  //     )

  //   }

  // }

  // const search = (e) => {
  //   let value = e.target.value
  //   setSearchValue(value)
  // }

  // const clearSearch = () => {
  //   document.getElementById('searchInput').value = ''
  //   setSearchValue('')
  // }

  // const directions = (e) => {
  //   let i = parseInt(e.currentTarget.getAttribute('data-i'))
  //   //console.log(`i: ${i}`)
  //   window.open(`https://www.google.com/maps/search/?api=1&query=${fetchedData[i].latitude},${fetchedData[i].longitude}`)
  // }

  // const missingLocation = () => alert('Missing latitude and longitude.')

  // const viewEntryLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.entrylat},${isValidated.entrylng}`)

  // const viewModLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.modlat},${isValidated.modlng}`)

  // const openAdd = () => {
  //   if (props.filter.jobNumber !== '' && props.filter.jobNumber !== null) {
  //     //console.log(`isValidated: ${JSON.stringify(isValidated)}`)
  //     //console.log(`props: ${JSON.stringify(props)}`)
  //     setIsValidated(prevState => ({...prevState, jobNumber: props.filter.jobNumber, latitude: props.filter.latitude, longitude: props.filter.longitude}))
  //   }

  //   setIsModal(prevState => ({...prevState, add: true}))
  // }

  // const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  // const openAddDescriptions = () => {

  //   if (isModal.logDescription && selectedLog !== '') {setIsModal(prevState => ({...prevState, addDescription: true}))} else {alert(`Please select a Location`)}

  // }

  // const openAddSamples = () => {

  //   if (isModal.logDescription && selectedLog !== '') {setIsModal(prevState => ({...prevState, addSample: true}))} else {alert(`Please select a Location`)}

  // }

  // const openEditDescriptions = () => setIsModal(prevState => ({...prevState, editDescription: true}))

  // const openEditSamples = () => setIsModal(prevState => ({...prevState, editSample: true}))

  // const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  // const togglePreview = () => {
  //   setIsModal(prevState => ({...prevState, preview: isModal.preview ? false : true}))

  //   if (isModal.preview) {previewRef.current = testPDF()}
  // }

  // const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  // const toggleLogDescription = () => setIsModal(prevState => ({...prevState, logDescription: isModal.logDescription ? false : true}))

  // const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  // const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  // const closeModal = () => {

  //   if (isChanged.current) {
  //     if (window.confirm('You have unsaved data. Proceed?')) {
  //       setIsModal(prevState => ({...prevState, add: false, edit: false}))
  //       clearIsValidated()
  //       isChanged.current = false
  //     }
  //   } else {
  //     setIsModal(prevState => ({...prevState, add: false, edit: false}))
  //     clearIsValidated()
  //   }

  // }

  // const closeModalDescriptions = () => {

  //   if (isChangedDescription.current) {
  //     if (window.confirm('You have unsaved data. Proceed?')) {
  //       setIsModal(prevState => ({...prevState, addDescription: false, editDescription: false}))
  //       clearIsValidatedDescriptions()
  //       isChangedDescription.current = false
  //     }
  //   } else {
  //     setIsModal(prevState => ({...prevState, addDescription: false, editDescription: false}))
  //     clearIsValidatedDescriptions()
  //   }

  // }

  // const closeModalSamples = () => {

  //   if (isChangedSamples.current) {
  //     if (window.confirm('You have unsaved data. Proceed?')) {
  //       setIsModal(prevState => ({...prevState, addSample: false, editSample: false}))
  //       clearIsValidatedSamples()
  //       isChangedSamples.current = false
  //     }
  //   } else {
  //     setIsModal(prevState => ({...prevState, addSample: false, editSample: false}))
  //     clearIsValidatedSamples()
  //   }

  // }

  // const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  // const closeGoogle = () => setIsModal(prevState => ({...prevState, google: false}))

  // const openDescription = () => setIsModal(prevState => ({...prevState, description: true}))

  // const openColor = () => setIsModal(prevState => ({...prevState, color: true}))

  // const closeDescription = () => setIsModal(prevState => ({...prevState, description: false}))

  // const closeColor = () => setIsModal(prevState => ({...prevState, color: false}))

  // const selectDescription = (e) => {

  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')

  //   setIsValidatedDescriptions(prevState => ({...prevState, description: td[0].textContent}))
  //   closeDescription()

  //   changedDescription()

  // }

  // const selectColor = (e) => {

  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')

  //   setIsValidatedDescriptions(prevState => ({...prevState, color: td[0].textContent}))
  //   closeColor()

  //   changedDescription()

  // }

  // const missingJN = () => alert('Please select a JN')

  // const selectLog = (e) => {
  //   let i = parseInt(e.currentTarget.getAttribute('data-i'))
  //   //console.log(`i: ${i}`)
  //   setSelectedLog({i: i, id: fetchedData[i].id, jobNumber: fetchedData[i].jobNumber, location: fetchedData[i].location})

  //   //if (isModal.preview && (props.user.device !== 'desktop' && props.user.device !== 'electron')) {

  //   if (isModal.preview && (props.user.device === 'desktop' || props.user.device === 'electron')) {

  //     // let path = `logs/log${fetchedData[i].id}.pdf`
  //     //
  //     // window.open(path)

  //   } else if (isModal.preview && (props.user.device === 'mobile' || props.user.device === 'tablet')) {

  //     createPDF(isModal.preview, fetchedData[i], fetchedDescriptions, fetchedSamples, fetchedLab)
  //     .then(res=>res.json())
  //     .then(
  //       (result) => {
  //         //console.log(`samples: ${JSON.stringify(result)}`)

  //         let path = `logs/log${fetchedData[i].id}.pdf`

  //         window.open(path)

  //       },
  //       (error) => {
  //         console.log('Error: openLog --> ' + error)
  //       }
  //     )

  //     // if (fs.existsSync(path)) {
  //     //   // path exists
  //     //   console.log("exists:", path);
  //     //
  //     //   window.open(path)
  //     //
  //     //   //window.open(`dailies/daily${fetchedData[i].id}.pdf`)
  //     //
  //     // } else {
  //     //   console.log("DOES NOT exist:", path);
  //     // }

  //   }

  // }

  // const clearLog = () => setSelectedLog('')

  // let listOfData = fetchedData.map((data, i) => {

  //   let jobNumber = data.jobNumber === null ? '' : data.jobNumber
  //   let location = data.location === null ? '' : data.location
  //   let depth = data.depth === null ? '' : data.depth
  //   let gw = data.gw === null ? '' : data.gw
  //   let drillDate = data.drillDate === null ? '' : data.drillDate
  //   //let loggedBy = data.projectdescription === null ? '' : data.projectdescription
  //   let driller = data.driller === null ? '' : data.driller
  //   let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
  //   let entryTime = data.entrytime === null ? '' : data.entrytime

  //   let filter = filterData(data, searchValue)
  //   //console.log(`filter ${filter}`)

  //   //if (filter && ((data.id === selectedLog.id) || selectedLog === '')) {

  //   if ((filter && !isModal.logDescription) || (filter && isModal.logDescription && selectedLog === '') || (data.id === selectedLog.id && isModal.logDescription)) {

  //     if (isModal.google) updateMap(data.id, true)

  //     return (
  //       <tr key={data.id.toString()} data-id={data.id} onClick={selectRow} onMouseOver={hightlightMarker}>
  //         <td style={{display: 'none'}}>{i}</td>
  //         <td style={{display: 'none'}}>{data.latitude}</td>
  //         <td style={{display: 'none'}}>{data.longitude}</td>
  //         <td>
  //           {
  //             (isModal.logDescription || isModal.preview) && data.id === selectedLog.id ?
  //             <Icon name='check_circle' onClick={clearLog} /> :
  //             (isModal.logDescription || isModal.preview) ?
  //             <Icon name='circle' i={i} onClick={selectLog} outline={true} /> :
  //             data.latitude === '' || data.latitude === null || data.longitude === '' || data.longitude === null ?
  //             <Icon name='wrong_location' color='tomato' onClick={missingLocation} /> :
  //             <Icon name='directions' i={i} onClick={directions} />
  //           }
  //         </td>
  //         {isModal.preview ? null : <td>{jobNumber}</td>}
  //         {isModal.preview ? null : <td>{drillDate}</td>}
  //         <td>{location}</td>
  //         <td>{depth}</td>
  //         <td>{gw}</td>
  //         {isModal.preview ? null : <td>{entryBy}</td>}
  //         {isModal.preview || isModal.google ? null : <td>{driller}</td>}
  //       </tr>
  //     )

  //   } else {
  //     if (isModal.google) updateMap(data.id, false)
  //   }

  // })

  // let listOfLogDescriptions = fetchedDescriptions.map((data, i) => {

  //   //let filter = filterData(data, searchValue)
  //   //console.log(`filter ${filter}`)

  //   if (isModal.logDescription && selectedSection === 'description' && selectedLog !== '' ) {

  //     //console.log('success')

  //     return (
  //       <tr key={data.id.toString()} data-id={data.id} onClick={selectRowDescriptions}>
  //         <td style={{display: 'none'}}>{i}</td>
  //         <td>{data.initialDepth}</td>
  //         <td>{data.finalDepth}</td>
  //         <td>{data.layer}</td>
  //         <td>{data.description}</td>
  //         <td>{data.uscs}</td>
  //         <td>{data.color}</td>
  //         <td>{data.moisture}</td>
  //         <td>{data.density}</td>
  //         <td>{data.grainSize}</td>
  //         <td className='wrapText'>{data.additionalDescription}</td>
  //       </tr>
  //     )

  //   } else {
  //     // console.log(`
  //     //   filter: ${filter}
  //     //   logDescription: ${isModal.logDescription}
  //     //   selectedSection: ${selectedSection}
  //     //   selectedLog: ${selectedLog}
  //     //   `)
  //   }

  // })

  // let listOfLogSamples = fetchedSamples.map((data, i) => {

  //   //let filter = filterData(data, searchValue)
  //   //console.log(`filter ${filter}`)

  //   if (isModal.logDescription && selectedSection === 'sample' && selectedLog !== '') {

  //     return (
  //       <tr key={data.id.toString()} data-id={data.id} onClick={selectRowSamples}>
  //         <td style={{display: 'none'}}>{i}</td>
  //         <td>{data.depth}</td>
  //         <td>{data.type}</td>
  //         <td>{data.sampleLength}</td>
  //         <td style={{color: 'white', background: data.recovered === 1 ? 'dodgerblue' : 'tomato'}}>{data.recovered === 1 ? 'Yes' : 'No'}</td>
  //         <td>{data.blowCount1}</td>
  //         <td>{data.blowCount2}</td>
  //         <td>{data.blowCount3}</td>
  //         <td>{data.blowCount4}</td>
  //         <td>{data.notes}</td>
  //       </tr>
  //     )

  //   }

  // })

  // let styleBtn = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   borderRadius: 10,
  //   border: '2px solid dodgerblue',
  //   marginBottom: 10
  // }

  // let styleBtn2 = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   borderRadius: 10,
  //   border: '2px solid dodgerblue',
  //   margin: 10
  // }

  // let styleBtn3 = {
  //   display: 'flex',
  //   alignItems: 'center',
  //   cursor: 'pointer',
  //   borderRadius: 10,
  //   border: '2px solid tomato',
  //   margin: 10,
  //   background: `repeating-linear-gradient(-45deg, tomato, tomato 5px, white 5px, white 20px)`
  // }

  // let modalContent = (
  //   <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

  //     <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

  //       {isValidated.jobNumber === '' || isValidated.jobNumber === null ?

  //         <div style={styleBtn} onClick={openJob}>
  //           <Icon name='search' />
  //           <span style={{marginRight: 10}}>Select a Job</span>
  //         </div> :
  //         <div>
  //           <label className='label'>JN</label>
  //           <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} disabled />
  //         </div>

  //       }

  //       <div>
  //         <label className='label'>Drill Date</label>
  //         <input className='input' type="date" pattern=".{1,}" name='drillDate' onInput={validate} onChange={changedData} defaultValue={isValidated.drillDate === '' || isValidated.drillDate === null ? null : isValidated.drillDate} required />
  //       </div>

  //       <div>
  //         <label className='label'>Location</label>
  //         <input style={{width: 75}} className='input' type="text" placeholder='ie B-1' pattern="[A-Z]{1,3}-[0-9]{1,3}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
  //       </div>

  //       <div>
  //         <label className='label'>Depth</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='depth' onInput={validate} onChange={changedData} defaultValue={isValidated.depth} required />
  //       </div>

  //       <div>
  //         <label className='label'>GW</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='gw' onInput={validate} onChange={changedData} defaultValue={isValidated.gw} required />
  //       </div>

  //       <CircleButton iconName='add_location' onClick={openLocate} /><br />

  //       {isValidated.latitude !== null && isValidated.latitude !== '' ? <p>Lat: {isValidated.latitude}</p> : null}
  //       {isValidated.longitude !== null && isValidated.longitude !== '' ? <p>Lng: {isValidated.longitude}</p> : null}

  //       <div>
  //         <label className='label'>Elevation (ft)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elevation' onInput={validate} onChange={changedData} defaultValue={isValidated.elevation} required />
  //       </div>

  //       <div>
  //         <label className='label'>Contractor</label>
  //         <select className="select" pattern="{1,}" name="driller" onInput={validate} onChange={changedData} defaultValue={isValidated.driller}>
  //           <option value=""></option>
  //           <option value="2R Drilling">2R Drilling</option>
  //           <option value="Gregg Drilling">Gregg Drilling</option>
  //           <option value="Albus & Associates">Albus & Associates</option>
  //           <option value="other">Other</option>
  //         </select>
  //       </div>

  //       {isValidated.driller === 'other1' ?
  //         <div>
  //           <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='driller' onInput={validate} onChange={changedData} required>{isValidated.driller}</textarea>
  //         </div> : null
  //       }

  //       <div>
  //         <label className='label'>Method</label>
  //         <select className="select" pattern="{1,}" name="drillMethod" onInput={validate} onChange={changedData} defaultValue={isValidated.drillMethod}>
  //           <option value=""></option>
  //           <option value="Hollow-Stem Auger">Hollow-Stem Auger</option>
  //           <option value="Hand Auger">Hand Auger</option>
  //           <option value="Bucket Auger">Bucket Auger</option>
  //           <option value="Backhoe">Backhoe</option>
  //           <option value="other">Other</option>
  //         </select>
  //       </div>

  //       {isValidated.drillMethod === 'other1' ?
  //         <div>
  //           <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='drillMethod' onInput={validate} onChange={changedData} required>{isValidated.drillMethod}</textarea>
  //         </div> : null
  //       }

  //       <div>
  //         <label className='label'>Weight</label>
  //         <select className="select" pattern="{1,}" name="drivingWeight" onInput={validate} onChange={changedData} defaultValue={isValidated.drivingWeight}>
  //           <option value=""></option>
  //           <option value="140 lbs / 30 in">140 lbs / 30 in</option>
  //           <option value="Hand Driven">Hand Driven</option>
  //           <option value="other">Other</option>
  //         </select>
  //       </div>

  //       {isValidated.drivingWeight === 'other1' ?
  //         <div>
  //           <textarea className='textArea' pattern="{1,}" placeholder='Specify other' name='drivingWeight' onInput={validate} onChange={changedData} required>{isValidated.drivingWeight}</textarea>
  //         </div> : null
  //       }

  //       <div>
  //         <label className='label'>Perc Well?</label>
  //         <select className="select" pattern="{1,}" name="percWell" onInput={validate} onChange={changedData} defaultValue={isValidated.percWell}>
  //           <option value=""></option>
  //           <option value={0}>No</option>
  //           <option value={1}>Yes</option>
  //         </select>
  //       </div>

  //       <div>
  //         <label className='label'>Feet Per Page</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='feetPerPage' onInput={validate} onChange={changedData} defaultValue={isValidated.feetPerPage} required />
  //       </div>

  //     </div>

  //   </div>
  // )

  // // <div>
  // //   <label className='label'>Descrip</label>
  // //   <input style={{width: 200}} className='input' type="text" value={isValidatedDescriptions.description} onClick={openDescription} />
  // // </div>

  // let modalContentDescription = (
  //   <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

  //     <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

  //       <div>
  //         <label className='label'>Line Style</label>
  //         <select className="select" pattern="{1,}" name="lineStyle" onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.lineStyle}>
  //           <option value=""></option>
  //           <option value="Dash">Dash</option>
  //           <option value="Solid">Solid</option>
  //         </select>
  //       </div>

  //       <div>
  //          <label className='label'>Initial Depth (ft)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='initialDepth' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.initialDepth} required />
  //       </div>

  //       <div>
  //          <label className='label'>Final Depth (ft)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='finalDepth' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.finalDepth} />
  //       </div>

  //       <div>
  //         <label className='label'>Layer</label>
  //         <input style={{width: 200}} className='input' type="text"  pattern="{1,}" name='layer' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.layer} />
  //         <Icon name='menu' onClick={null} />
  //       </div>

  //       <div>
  //         <label className='label'>Descrip</label>
  //         <input style={{width: 200}} className='input' type="text"  pattern="{1,}" name='description' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.description} />
  //         <Icon name='menu' onClick={openDescription} />
  //       </div>

  //       <div>
  //          <label className='label'>USCS</label>
  //         <input style={{width: 75}} className='input' type="text"  pattern="{1,}" name='uscs' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.uscs} />
  //       </div>

  //       <div>
  //         <label className='label'>Color</label>
  //         <input style={{width: 200}} className='input' type="text"  pattern="{1,}" name='color' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.color} />
  //         <Icon name='menu' onClick={openColor} />
  //       </div>

  //       <div>
  //         <label className='label'>Moist</label>
  //         <select className="select" pattern="{1,}" name="moisture" onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.moisture}>
  //           <option value=""></option>
  //           <option value="dry">dry</option>
  //           <option value="dry to damp">dry to damp</option>
  //           <option value="damp">damp</option>
  //           <option value="damp to moist">damp to moist</option>
  //           <option value="moist">moist</option>
  //           <option value="moist to very moist">moist to very moist</option>
  //           <option value="very moist">very moist</option>
  //           <option value="very moist to wet">very moist to wet</option>
  //           <option value="wet">wet</option>
  //         </select>
  //       </div>

  //       <div>
  //         <label className='label'>Density</label>
  //         <input style={{width: 200}} className='input' type="text"  pattern="{1,}" name='density' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.density} />
  //         <Icon name='menu' onClick={null} />
  //       </div>

  //       <div>
  //         <label className='label'>Grain Size</label>
  //         <input style={{width: 200}} className='input' type="text"  pattern="{1,}" name='grainSize' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.grainSize} />
  //         <Icon name='menu' onClick={null} />
  //       </div>

  //       <div>
  //         <div><label className='label'>More</label></div>
  //         <textarea style={{height: 200}} className='textArea' placeholder='' pattern="{1,}" name='additionalDescription' onInput={validateDescription} onChange={changedDescription} defaultValue={isValidatedDescriptions.additionalDescription}></textarea>
  //       </div>

  //     </div>

  //   </div>
  // )

  // let modalContentSample = (
  //   <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

  //     <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

  //       <div>
  //         <label className='label'>Depth (ft)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='depth' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.depth} required />
  //       </div>

  //       <div>
  //         <label className='label'>Type</label>
  //         <select className="select" pattern=".{1,}" name="type" onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.type} required>
  //           <option value=""></option>
  //           <option value="ca">CA Rings</option>
  //           <option value="spt">SPT</option>
  //           <option value="bulk">Bulk</option>
  //           <option value="smbag">Small Bag</option>
  //           <option value="ac">Asphalt</option>
  //           <option value="shelby">Shelby Tube</option>
  //         </select>
  //       </div>

  //       <div>
  //         <label className='label'>length (ft)</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1,2})?" name='sampleLength' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.sampleLength} required />
  //       </div>

  //       <div>
  //         <label className='label'>Recovered</label>
  //         <select className="select" pattern=".{1,}" name="recovered" onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.recovered} required>
  //           <option value={1}>Yes</option>
  //           <option value={0}>No</option>
  //         </select>
  //       </div>

  //       <div>
  //         <label className='label'>BC1</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='blowCount1' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.blowCount1} required />
  //       </div>

  //       <div>
  //         <label className='label'>BC2</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='blowCount2' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.blowCount2} required />
  //       </div>

  //       <div>
  //         <label className='label'>BC3</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='blowCount3' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.blowCount3} required />
  //       </div>

  //       <div>
  //         <label className='label'>BC4</label>
  //         <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='blowCount4' onInput={validateSample} onChange={changedSample} defaultValue={isValidatedSamples.blowCount4} required />
  //       </div>

  //     </div>

  //   </div>
  // )

  // // <div style={selectedSection === 'description' ? styleBtn2 : styleBtn3} onClick={toggleSection}>
  // //   <span style={{margin: 10}}>Description</span>
  // // </div>
  // //
  // // <div style={selectedSection === 'sample' ? styleBtn2 : styleBtn3} onClick={toggleSection}>
  // //   <span style={{margin: 10}}>Sample</span>
  // // </div>

  // return (
  //   <>
  //     {isModal.add || isModal.edit ? <Modal close={closeModal} add={isModal.add ? addData : isModal.edit ? editData : null} delete={deleteData} content={modalContent} isValidated={isValidated} isModal={isModal} /> : null}
  //     {isModal.addDescription || isModal.editDescription ? <Modal close={closeModalDescriptions} add={isModal.addDescription ? addDescription : isModal.editDescription ? editDescription : null} delete={deleteDescription} content={modalContentDescription} isValidated={isValidatedDescriptions} isModal={isModal} /> : null}
  //     {isModal.addSample || isModal.editSample ? <Modal close={closeModalSamples} add={isModal.addSample ? addSample : isModal.editSample ? editSample : null} delete={deleteSample} content={modalContentSample} isValidated={isValidatedSamples} isModal={isModal} /> : null}
  //     {isModal.locate ? <Locate close={closeLocate} add={selectLocate} filter={props.filter} latlng={isValidated} fetchedData={fetchedData} /> : null}
  //     {isModal.job ? <JobMenu selectJob={selectJob} clearJob={clearJob} close={closeJob} /> : null}
  //     {isModal.description ? <Description selectDescription={selectDescription} close={closeDescription} /> : null}
  //     {isModal.color ? <Color selectColor={selectColor} close={closeColor} /> : null}

  //     <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

  //       <div style={{flex: isModal.google || (isModal.preview && (props.user.device === 'desktop' || props.user.device === 'electron')) ? '0 1 auto' : '1 0 auto', maxWidth: '100%'}}>

  //         <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

  //           <div style={{width: '100%'}}>

  //             {props.user.log > 1 ? <Icon name='add_circle' title='Add' onClick={openAdd} /> : null}
  //             {props.user.log > 1 ? <Icon name='post_add' title='Add Descriptions and Samples' color={isModal.logDescription ? 'dodgerblue' : 'gray'} onClick={toggleLogDescription} /> : null}
  //             <Icon name='preview' title='Preview Log' color={isModal.preview ? 'dodgerblue' : 'gray'} onClick={togglePreview} outline={true} />
  //             <Icon name='location_on' title='View in Google Maps' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
  //             <Icon name='download' title='Download to Excel' onClick={exportToExcel} />
  //             <Icon name='refresh' title='Refresh' onClick={fetchData} />

  //           </div>

  //           <SearchBar search={search} clearSearch={clearSearch} />

  //           {fetchedData.length > 0 ?

  //             <>

  //               {isModal.google && (props.user.device !== 'desktop' && props.user.device !== 'electron') ?

  //                 null :

  //                 <div style={{flex: isModal.logDescription && selectedLog !== '' ? '0 1 auto' : '1', overflow: 'auto'}}>

  //                   <table id="toExcel">

  //                     <thead>
  //                       <tr>
  //                         <th style={{display: 'none'}}>id</th>
  //                         <th style={{display: 'none'}}>lat</th>
  //                         <th style={{display: 'none'}}>lng</th>
  //                         <th></th>
  //                         {isModal.preview ? null : <th>JN</th>}
  //                         {isModal.preview ? null : <th>Date</th>}
  //                         <th>Location</th>
  //                         <th>Depth</th>
  //                         <th>GW</th>
  //                         {isModal.preview ? null : <th>By</th>}
  //                         {isModal.preview || isModal.google ? null : <th>Driller</th>}
  //                       </tr>
  //                     </thead>

  //                     <tbody>
  //                       {listOfData}
  //                     </tbody>

  //                   </table>

  //                 </div>

  //               }

  //             </>: <p style={{margin: 10}}>{isLoading ? `Loading...` : `No Logs found.`}</p>

  //           }

  //           {

  //             isModal.logDescription && selectedLog !== '' ?

  //             <>

  //             <div style={{display: 'flex', alignItems: 'center'}}>

  //               {props.user.log > 1 ? <Icon name='add_box' title='Add' onClick={selectedSection === 'description' ? openAddDescriptions : openAddSamples} /> : null}

  //               <div style={{display: 'inline-block', margin: 10}}>
  //                 <small style={{margin: 10}}>Sample</small>
  //                 <label className='switch'>
  //                   <input type='checkbox' onClick={toggleSection} defaultChecked={selectedSection === 'description' ? 1 : 0} />
  //                   <span className='slider round'></span>
  //                 </label>
  //                 <small style={{margin: 10}}>Description</small>
  //               </div>

  //             </div>

  //             <div style={{flex: '1', overflow: 'auto'}}>

  //               {
  //                 isModal.logDescription && selectedSection === 'description' && selectedLog !== '' && fetchedDescriptions.length === 0 ?
  //                 <p style={{margin: 10}}>{`No Descriptions found.`}</p> :
  //                 isModal.logDescription && selectedSection === 'sample' && selectedLog !== '' && fetchedSamples.length === 0 ?
  //                 <p style={{margin: 10}}>{`No Samples found.`}</p> :
  //                 isModal.logDescription && selectedSection === 'description' && selectedLog !== '' && fetchedDescriptions.length > 0 ?

  //                 <table id="toExcel">

  //                   <thead>
  //                     <tr>
  //                       <th>Init.</th>
  //                       <th>Final</th>
  //                       <th>Layer</th>
  //                       <th>Desc.</th>
  //                       <th>USCS</th>
  //                       <th>Color</th>
  //                       <th>Moist</th>
  //                       <th>Dens.</th>
  //                       <th>Size</th>
  //                       <th>More</th>
  //                     </tr>
  //                   </thead>

  //                   <tbody>
  //                     {listOfLogDescriptions}
  //                   </tbody>

  //                 </table> :

  //                 isModal.logDescription && selectedSection === 'sample' && selectedLog !== '' && fetchedSamples.length > 0 ?

  //                 <table id="toExcel">

  //                   <thead>
  //                     <tr>
  //                       <th>Depth</th>
  //                       <th>Type</th>
  //                       <th>Length</th>
  //                       <th>Recovered</th>
  //                       <th>BC1</th>
  //                       <th>BC2</th>
  //                       <th>BC3</th>
  //                       <th>BC4</th>
  //                       <th>Notes</th>
  //                     </tr>
  //                   </thead>

  //                   <tbody>
  //                     {listOfLogSamples}
  //                   </tbody>

  //                 </table> : null

  //               }

  //             </div>

  //             </> : null

  //           }

  //           {isModal.google && (props.user.device !== 'desktop' && props.user.device !== 'electron') ?

  //             <div style={{margin: 10, flex: '1', overflow: 'auto'}}>
  //               <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
  //             </div> : null

  //           }

  //         </div>

  //       </div>

  //       {isModal.google && (props.user.device === 'desktop' || props.user.device === 'electron') ?

  //         <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
  //           <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
  //         </div> : null

  //       }

  //       {isModal.preview && (props.user.device === 'desktop' || props.user.device === 'electron') ?

  //         <>
  //           {selectedLog !== '' ?
  //             <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
  //               <iframe style={{height: 'calc(100% - 10px)', width: 'calc(100% - 10px)'}} src={createPDF(isModal.preview, fetchedData[selectedLog.i], fetchedDescriptions, fetchedSamples, fetchedLab)}></iframe>
  //             </div> : <p>Please select a log to Preview (click the blue circle)</p>
  //           }
  //         </> : null

  //       }

  //     </div>

  //   </>
  // )

}

export default Library
