import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

const Color = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectMenuColor', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuColor --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let color = data.color === null ? '' : data.color

    if (searchValue === '' || color.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0) {
      return (
        <tr key={data.id.toString()} onClick={props.selectColor}>
          <td>{color}</td>
        </tr>
      )
    }

  })

  let content = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          <table>

            <thead>
              <tr>
                <th>Color</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} close={props.close} />
}

export default Color
