import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { filterData } from 'scripts/common'

const Proposal = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectMenuProposal', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuProposal --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => setSearchValue(e.target.value)

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let pn = data.proposal === null ? '' : `${data.proposal}-${data.proposalyear}`
    let company = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city
    let latitude = data.latitude === null ? '' : data.latitude
    let longitude = data.longitude === null ? '' : data.longitude

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} onClick={props.selectProp}>
          <td style={{display: 'none'}}>{latitude}</td>
          <td style={{display: 'none'}}>{longitude}</td>
          <td style={{whiteSpace: 'nowrap'}}>{pn}</td>
          <td>{company}</td>
          <td>{city}</td>
        </tr>
      )
    }

  })

  let content = (
    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

      <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

      <div style={{textAlign: 'center'}}>
        <div style={{
          border: '2px solid tomato',
          borderRadius: 5,
          color: 'tomato',
          cursor: 'pointer',
          padding: 5,
          margin: 10
        }} onClick={props.clearProp}>Clear Proposal
        </div>
      </div>

      <>

        <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

          <table>

            <thead>
              <tr>
                <th>PN</th>
                <th>Client</th>
                <th>City</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>

        </div>

      </>

    </div>
  )

  return <Modal content={content} close={props.closeModal} />
}

export default Proposal
