import React, { useState} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import 'styles/login.css'

const Login = (props) => {

  const styleLogo = {
    width: 'calc(100% - 10px)'
  }

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  const login = () => {

    let user = document.getElementById("loginname").value
    let pw = document.getElementById("loginpw").value

    props.login(user, pw)

  }

  const loginOnEnter = (e) => {
    if(e.key === 'Enter') {
      //console.log('enter')
      login()
    }
  }

  return (

    <div className="modal">

      <div className="modal-content animate">

        <div className="fadein" style={{textAlign: 'center'}}>

          <div className="logoSize">

            <img style={styleLogo} src="logo500.png" />

          </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>

              <div>

                <input type="text" className="input" placeholder="Enter Username" id="loginname" onKeyPress={loginOnEnter} />

                <input type="password" className="input" placeholder="Enter Password" id="loginpw" onKeyPress={loginOnEnter} />

              </div>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styleBtn} onClick={login}>
                  <Icon name='login' outline={true} />
                  <span style={{marginRight: 10}}><b>Login</b></span>
                </div>
              </div>

            </div>

            <div style={{display: 'inline-block', margin: 10}}>
              <span>Stay logged in?</span>
              <small style={{margin: 10}}>No</small>
              <label className='switch'>
                <input type='checkbox' onClick={props.toggleStayLoggedIn} defaultChecked={props.stayLoggedIn} onKeyPress={loginOnEnter} />
                <span className='slider round'></span>
              </label>
              <small style={{margin: 10}}>Yes</small>
            </div>

          {props.isError ? <p>Incorrect login credentials.</p> : null}

        </div>

      </div>

    </div>

  )

}

export default Login
