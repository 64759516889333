import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import 'styles/navBar.css';

function NavBar(props) {

  const [isModal, setIsModal] = useState({
    profile: false,
    menu: false
  })
  const minimumScreenSize = 600
  const [screenSizeNormal, setScreenSizeNormal] = useState(null)

  const resizeScreen = () => {

    if (window.innerWidth > minimumScreenSize && screenSizeNormal === null) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth <= minimumScreenSize && screenSizeNormal === null) {
      setScreenSizeNormal(false)
    } else if (window.innerWidth > minimumScreenSize && !screenSizeNormal) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth < minimumScreenSize && screenSizeNormal) {
      setScreenSizeNormal(false)
    }

  }

  window.addEventListener("resize", resizeScreen)

  useEffect(() => {
    resizeScreen()
  }, [])

  //const openAppMenu = () => setIsAppMenu(isAppMenu ? false : true)

  const openProfile = () => setIsModal(prevState => ({...prevState, profile: true}))
  const openMenu = () => setIsModal(prevState => ({...prevState, menu: true}))
  const closeProfile = () => setIsModal(prevState => ({...prevState, profile: false}))
  const closeMenu = () => setIsModal(prevState => ({...prevState, menu: false}))

  const selectComponent = (e) => {
    props.onClick(e.target)
    closeMenu()
  }

  const selectRow = (e) => {
    props.onClick(e.target.parentNode)
    closeMenu()
  }

  const styleNormal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto'
  }

  const styleSmall = {
    backgroundColor: '#383838',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const styleAppMenu = {
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto',
    textAlign: 'right'
  }

  const titleStyle = {
    display: 'inline-block',
    width: 50,
    height: 'auto',
    objectFit: 'contain',
    marginRight: 10
  }

  // {props.user.drawing > 0 ? <tr className={props.component === 'Drawings' ? 'activeMenu' : 'nonActive2'} data-name='Drawings' onClick={selectRow}><td>Drawings</td></tr> : null}

  let menuContent = (
    <div style={{textAlign: 'center'}}>
      <div style={{display: 'inline-block'}}>
        <table>

          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr className={props.component === 'Home' ? 'activeMenu' : 'nonActive2'} data-name='Home' onClick={selectRow}><td>Home</td></tr>
            {props.user.job > 0 ? <tr className={props.component === 'Job' ? 'activeMenu' : 'nonActive2'} data-name='Job' onClick={selectRow}><td>Job</td></tr> : null}
            {props.user.proposal > 0 ? <tr className={props.component === 'Proposal' ? 'activeMenu' : 'nonActive2'} data-name='Proposal' onClick={selectRow}><td>Proposal</td></tr> : null}
            {props.user.log > 0 ? <tr className={props.component === 'Log' ? 'activeMenu' : 'nonActive2'} data-name='Log' onClick={selectRow}><td>Log</td></tr> : null}
            {props.user.aerial > 0 ? <tr className={props.component === 'Aerial' ? 'activeMenu' : 'nonActive2'} data-name='Aerial' onClick={selectRow}><td>Aerial</td></tr> : null}
            {props.user.geology > 0 ? <tr className={props.component === 'Geology' ? 'activeMenu' : 'nonActive2'} data-name='Geology' onClick={selectRow}><td>Geology</td></tr> : null}
            {props.user.library > 0 ? <tr className={props.component === 'Library' ? 'activeMenu' : 'nonActive2'} data-name='Library' onClick={selectRow}><td>Library</td></tr> : null}
            {props.user.manage > 0 ? <tr className={props.component === 'Manage' ? 'activeMenu' : 'nonActive2'} data-name='Manage' onClick={selectRow}><td>Manage</td></tr> : null}
          </tbody>

        </table>
      </div>
    </div>
  )

  let styleProfile = {
    background: 'dodgerblue',
    color: 'white',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  let fullname = props.user.fullname
  let profileInitials = fullname.substring(0, 1) + fullname.substring(fullname.indexOf(' ') + 1, fullname.indexOf(' ') + 2)

  return (
    <div>
      {isModal.profile ?
        <Modal
          content={
            <div>
              <h3 style={{marginLeft: 10}}>{props.user.fullname}</h3>

              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={styleBtn} onClick={props.logOut}>
                  <Icon name='logout' outline={true} />
                  <span style={{marginRight: 10}}><b>Sign Out</b></span>
                </div>
              </div>

            </div>
          }
          close={closeProfile}
        /> : null
      }
      {isModal.menu ? <Modal content={menuContent} close={closeMenu} /> : null}
      {screenSizeNormal ?
        <div style={styleNormal}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img style={titleStyle} src="logo192.png" />
            <div style={{display: 'inline-block'}} className={props.component === 'Home' ? 'active' : 'nonActive'} data-name='Home' onClick={selectComponent}>Home</div>
            {props.user.job > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Job' ? 'active' : 'nonActive'} data-name='Job' onClick={selectComponent}>Job</div> : null}
            {props.user.proposal > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Proposal' ? 'active' : 'nonActive'} data-name='Proposal' onClick={selectComponent}>Proposal</div> : null}
            {props.user.log > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Log' ? 'active' : 'nonActive'} data-name='Log' onClick={selectComponent}>Log</div> : null}
            {props.user.aerial > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Aerial' ? 'active' : 'nonActive'} data-name='Aerial' onClick={selectComponent}>Aerial</div> : null}
            {props.user.geology > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Geology' ? 'active' : 'nonActive'} data-name='Geology' onClick={selectComponent}>Geology</div> : null}
            {props.user.library > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Library' ? 'active' : 'nonActive'} data-name='Library' onClick={selectComponent}>Library</div> : null}
            {props.user.manage > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Manage' ? 'active' : 'nonActive'} data-name='Manage' onClick={selectComponent}>Manage</div> : null}
          </div>
          <div>
            <div style={styleProfile} onClick={openProfile}>{profileInitials}</div>
          </div>
        </div> :
      !screenSizeNormal ?
        <div>
          <div style={styleSmall}>
            <img style={titleStyle} src="logo192.png" />
            <Icon name='apps' onClick={openMenu} />
            <div style={styleProfile} onClick={openProfile}>{profileInitials}</div>
          </div>
        </div> :null
      }
    </div>
  )

}

export default NavBar
